import { ReactNode } from "react";
import { AppContext } from "../App";

type ContainerGridProps = {
  children?: ReactNode;
};

export function ContainerGrid({ children }: ContainerGridProps) {
  return (
    <AppContext.Consumer>
      {({}) => (
        <article className="z-0 prose lg:prose-sm text-left min-w-full h-full overflow-hidden">
          <div className="content w-full h-full flex relative">{children}</div>
        </article>
      )}
    </AppContext.Consumer>
  );
}
