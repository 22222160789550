import { LargeText, SmallText } from "../styles/Typography";
import { displayValue } from "../utils/helpers";
import StarsBg from "../assets/Stars.png";

type StartCardProps = {
  avgRent: number;
  portfolioAvgRent?: number | null;
  diff: number;
};

export const StarsCard = ({
  avgRent,
  portfolioAvgRent,
  diff,
}: StartCardProps) => (
  <div className="bg-auto relative bg-gradient-to-b from-[#212121] to-[#373737] p-4 !text-white rounded-xl overflow-hidden">
    <div
      className="w-full h-full absolute"
      style={{
        backgroundImage: `url(${StarsBg})`,
      }}
    ></div>
    <LargeText className="!text-white font-normal text-xl">
      Average rent
    </LargeText>
    <LargeText className="!text-white font-extrabold text-3xl">
      {displayValue(avgRent, "$")}
    </LargeText>
    {portfolioAvgRent ? (
      <SmallText>
        Your property's average rent for this unit type is{" "}
        {displayValue(portfolioAvgRent, "$")}
        {avgRent && portfolioAvgRent ? (
          <span>
            , which is
            {avgRent > portfolioAvgRent ? (
              <span className=" text-red-300 animate-[glow_3s_ease-in-out] animate-once font-normal pl-1">
                {diff.toFixed(2)}% lower
              </span>
            ) : (
              <span className=" text-green-300 animate-[glow_3s_ease-in-out] animate-once font-normal pl-1">
                ${diff.toFixed(2)}% higher
              </span>
            )}
            .
          </span>
        ) : (
          ""
        )}
      </SmallText>
    ) : (
      <SmallText>
        Your portfolio does not contain any unit of this type.
      </SmallText>
    )}
  </div>
);
