// currentStep, onStepClick

import { FC, ReactNode } from "react";
import { Stepper, Step } from "@material-tailwind/react";
import { GenerateReportStep } from "../views/Reports/GenerateReport/types";

export type Step = {
  id: GenerateReportStep;
  label?: string;
  button: string | JSX.Element;
  icon?: ReactNode;
  actions?: {
    primary?: { label?: string; icon?: JSX.Element; onClick?: () => void };
    secondary?: { label?: string; icon?: JSX.Element; onClick?: () => void };
  };
  content?: JSX.Element;
};

interface AppStepperProps {
  steps: Step[];
  onStepChange: (step: number) => void;
  currentStep: GenerateReportStep;
  disabledNextSteps: boolean;
}

export const AppStepper: FC<AppStepperProps> = ({
  steps,
  onStepChange,
  currentStep,
  disabledNextSteps,
}) => {
  return (
    <div className="flex flex-col justify-between top-0 w-full">
      <div className="w-full h-14 flex justify-center items-start p-3 bg-primary-light">
        <div className="w-3/4 p-0 bg-pr">
          <Stepper
            activeStep={currentStep}
            lineClassName="bg-secondary-gray-350 h-[1px]"
            activeLineClassName="bg-primary-dark"
          >
            {steps.map((s, index) => (
              <Step
                key={`${s.label}_${index}`}
                className={` bg-primary-soft font-light text-secondary-gray-500 text-xs w-5 h-5 cursor-pointer ${
                  disabledNextSteps
                    ? "pointer-events-none bg-secondary-gray-350"
                    : ""
                }`}
                activeClassName="ring-0 !bg-primary text-white [&>div]:text-primary pointer-events-auto"
                completedClassName="bg-primary-dark text-secondary-gray-100 [&>div]:text-primary-dark pointer-events-auto"
                onClick={() => {
                  onStepChange(index);
                }}
              >
                {index + 1}
                <div className="absolute -bottom-[1.1rem] w-max text-center text-[10px]">
                  {s.label}
                </div>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
};
