import { createSlice } from '@reduxjs/toolkit';
import { MessageDTO } from '../../models/models';

interface MessagesState {
    conversationID: number,
    isChatOpen: boolean,
    messages: MessageDTO[]
}

const initialState: MessagesState = {
    conversationID: 0,
    isChatOpen: false,
    messages: [],
}

const MessagesSlice = createSlice({name: 'messages', initialState, reducers: {
    setConversationID(state, {payload: conversationID}) {
        return {...state, conversationID};
    },
    openChat(state) {
        return {...state, isChatOpen: true};
    },
    closeChat(state) {
        return {...state, isChatOpen: false};
    },
    setMessages(state, {payload: messages}) {
        return {...state, messages};
    },
    addMessage(state, {payload: message}) {
        return {...state, messages:[...state.messages, message]};
    },
    
}});

export const {setConversationID, setMessages, addMessage, openChat, closeChat} = MessagesSlice.actions;
export default MessagesSlice.reducer;