import { useCallback, useEffect, useState } from "react";
import { GenerateReportDTO, REPORT_TYPE } from "../../../../../models/models";
import { Radio, Typography } from "@material-tailwind/react";
import { AppDropdown } from "../../../../../components/Inputs";
import { DatePicker } from "../../../../../components/DatePicker";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { LargeText, SmallText } from "../../../../../styles/Typography";

interface FillStepProps {
  onFieldChange: (attribute: any, value: any) => void;
  reportData: GenerateReportDTO;
  setIsDisabledSubmitButton: (value: boolean) => void;
}
const FillStep = ({
  reportData,
  onFieldChange,
  setIsDisabledSubmitButton,
}: FillStepProps) => {
  const [isValidDateRange, setIsValidDateRange] = useState(true);

  useEffect(() => {
    const noPropertiesSelected = reportData.properties.length === 0;
    const validDateRange = reportData?.toDate > reportData?.fromDate;
    setIsValidDateRange(validDateRange);
    setIsDisabledSubmitButton(noPropertiesSelected || !validDateRange);
  }, [reportData.fromDate, reportData.toDate, reportData.properties.length]);

  const onChange = useCallback(
    (attribute: any) => (e: any) => {
      console.log(attribute);
      console.log(e?.target?.value);
      onFieldChange(attribute, e?.target?.value || e);
    },
    [reportData, onFieldChange]
  );
  const fillProperties = (properties: string[]) => {
    onFieldChange("properties", properties);
  };
  const fillDate = (field: string, date: Date) => {
    onFieldChange(field, date);
  };
  // const [focused, setFocused] = useState(false);
  const toggleReportType = useCallback(() => {
    onFieldChange(
      "reportType",
      reportData.reportType === REPORT_TYPE.internal
        ? REPORT_TYPE.lp
        : REPORT_TYPE.internal
    );
  }, [reportData]);
  return (
    <>
      <div className="px-6 pt-8 text-secondary-gray-600">
        <LargeText>Report type</LargeText>
        <div className="flex flex-col">
          <Radio
            color="blue"
            name="type"
            label="Internal"
            crossOrigin={undefined}
            checked={reportData.reportType === REPORT_TYPE.internal}
            onChange={toggleReportType}
          />
          <Radio
            color="blue"
            name="type"
            label={"LP"}
            crossOrigin={undefined}
            checked={reportData.reportType === REPORT_TYPE.lp}
            onChange={toggleReportType}
          />
        </div>
      </div>
      <div className="px-6 py-3 text-secondary-gray-600 w-2/3">
        <LargeText>Property selection</LargeText>
        <div className="py-3">
          <AppDropdown
            label={"Select Properties"}
            options={["Unit-A1", "Unit-A2", "Unit-A3"]}
            onSelectCallback={fillProperties}
          />
          <SmallText className=" text-primary !m-2 text-xs">
            {reportData.properties.length} properties selected
          </SmallText>
        </div>
      </div>

      <div className="px-6 py-3 text-secondary-gray-600 w-2/3">
        <LargeText>Time range</LargeText>
        <div className="py-3 flex gap-2 w-96 overflow-hidden [&>*]:min-w-fit [&>*]:max-w-fit">
          <DatePicker
            label="From"
            onChange={onChange("fromDate")}
            onDateSelect={(date: Date) => fillDate("fromDate", date)}
            value={reportData?.fromDate}
            error={!isValidDateRange}
          />
          <DatePicker
            label="To"
            onChange={onChange("toDate")}
            onDateSelect={(date: Date) => fillDate("toDate", date)}
            value={reportData?.toDate}
          />
        </div>
        {!isValidDateRange ? (
          <>
            <SmallText className="text-red text-xs ml-2 !mt-0 pt-0">
              Start Date should be earlier than End Date
            </SmallText>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="px-6 py-3 text-secondary-gray-600 w-2/3">
        <LargeText>Recepients</LargeText>
        <div className="py-3 flex gap-6">
          <ReactMultiEmail
            placeholder="Enter recepients"
            emails={reportData.recepients}
            onChange={onChange("recepients")}
            autoFocus={true}
            // onFocus={() => setFocused(true)}
            // onBlur={() => setFocused(false)}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
        <SmallText className="text-secondary-gray-400 font-light !0pt-0 !mt-0 ml-2 text-xs">
          Can also be selected later in your mailing client.
        </SmallText>
      </div>
    </>
  );
};

export default FillStep;
