import React, { ChangeEventHandler, useEffect } from "react";
import {
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import DatePickerWithInput from "./DatePickerWithInput";
import { format } from "date-fns";

type DatePickerProps = {
  label: string;
  value: Date;
  onChange: ChangeEventHandler;
  onDateSelect: (date: Date) => void;
  error?: boolean;
  disabledDays?: any;
  className?: string;
};

export const DatePicker = ({
  label,
  value,
  onChange,
  onDateSelect,
  error,
  disabledDays,
  className,
}: DatePickerProps) => {
  const [date, setDate] = React.useState<Date>();

  useEffect(() => {
    setDate(value);
  }, []);

  const onSelect = (day: Date | undefined) => {
    setDate(day);
    day && onDateSelect(day);
  };

  return (
    <Popover placement="bottom">
      <PopoverHandler>
        <Input
          label={label ?? "Select a Date"}
          onChange={onChange}
          value={date ? format(date, "MM/dd/yyyy") : ""}
          crossOrigin={undefined}
          className={`!max-w-[100px] !min-w-[100px] ml-[0.5px] !border rounded-sm !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 peer ${
            error ? "!border-red" : ""
          } ${className || ""}`}
          labelProps={{
            className: `bg-white ${
              !label
                ? "hidden"
                : "ml-3 px-1 !w-fit !h-3 peer-focus:!bg-white before:!hidden after:!hidden"
            }`,
          }}
        />
      </PopoverHandler>
      <PopoverContent className=" z-[99999999]">
        <DatePickerWithInput onSelect={onSelect} disabledDays={disabledDays} />
      </PopoverContent>
    </Popover>
  );
};
