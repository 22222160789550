import { useState } from "react";
import { FeedbackIcon, InfoIcon } from "./Icons";
import { SearchBar } from "./SearchBar";
import { Popup } from "./Popup";
import { sendFeedback } from "../api/api";
import { FeedbackDTO } from "../models/models";
import { LargeText, SmallText } from "../styles/Typography";

type FeedbackBoxProps = {
  sectionName: string;
};

export const FeedbackBox = ({ sectionName }: FeedbackBoxProps) => {
  const [isVisibleBox, setIsVisibleBox] = useState(false);
  const [feedbackRecieved, setFeedbackRecieved] = useState(false);

  const send = (props: FeedbackDTO) => {
    sendFeedback(props);
    setFeedbackRecieved(true);
  };

  return (
    <div>
      <div onClick={() => setIsVisibleBox(true)} className=" text-primary">
        <FeedbackIcon />
      </div>
      {isVisibleBox && (
        <Popup
          title={""}
          closePopup={() => {
            setIsVisibleBox(false);
            setFeedbackRecieved(false);
          }}
          className="p-2 top-32 ml-[-150px]"
        >
          <div className="p-4">
            {feedbackRecieved ? (
              <>
                <div className=" bg-green-50 rounded-lg p-2 mb-4">
                  <div className="flex items-center gap-1 justify-start text-green font-semibold">
                    <InfoIcon color="#0D652D" />
                    <div>Feedback sent successfully!</div>
                  </div>

                  <SmallText className="!mt-2 !ml-6 text-secondary-gray-500">
                    Thank you!
                    {/* Your feedback is valuable for us! Our team will go over it
                    soon. */}
                  </SmallText>
                </div>
              </>
            ) : (
              <>
                <LargeText>Send us feedback about this section</LargeText>
                <SearchBar
                  className="mt-4"
                  placeholder="Tell us anything you like..."
                  search={(input) => send({ sectionName, input })}
                />
              </>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
};
