import { FC } from "react";
import { Link, OutlineButton, PrimaryButton } from "@components/Buttons";

type NavigationAction = {
  label?: string;
  icon?: JSX.Element;
  onClick?: () => void;
};

interface IProps {
  primaryAction?: NavigationAction;
  secondaryAction?: NavigationAction;
  onClose: () => void;
  disabled: boolean;
}
const GenerateReportNavigation: FC<IProps> = ({
  primaryAction,
  secondaryAction,
  onClose,
  disabled,
}) => {
  {
    return (
      <div
        className={`w-full bottom-0 h-16 border-t shadow-2xl mt-8 p-3 flex justify-between items-center`}
      >
        <Link onClick={onClose}>Close</Link>
        <div className="flex gap-4">
          {secondaryAction && (
            <OutlineButton onClick={secondaryAction.onClick}>
              {secondaryAction.icon ?? ""}
              {secondaryAction.label ?? "Back"}
            </OutlineButton>
          )}
          {primaryAction && (
            <PrimaryButton onClick={primaryAction.onClick} disabled={disabled}>
              {primaryAction.icon ?? ""}
              {primaryAction.label ?? "Next"}
            </PrimaryButton>
          )}
        </div>
      </div>
    );
  }
};

export default GenerateReportNavigation;
