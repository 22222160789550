import { PropsWithChildren } from "react";

type FrameProps = { className?: string };

export const Frame = ({
  children,
  className,
}: PropsWithChildren<FrameProps>) => (
  <div
    className={`relative border border-secondary-gray-350 rounded-md p-8 my-4 hover:bg-secondary-gray-100/30 hover:shadow-md cursor-pointer ${
      className || ""
    }`}
  >
    {children}
  </div>
);
