import { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { MessageDTO, ROLE } from "../models/models";
import { ChatLogoSymbolIcon } from "./Icons";
import Typed from "typed.js";
import { FilePreview } from "./FileUploader";

export const ChatMessage = ({
  role,
  content,
  time,
  _key,
  typeMessage,
  typingRef,
  isLast,
  isFile,
}: MessageDTO) => {
  const el = useRef(null);
  const [shouldTypeMessage, setShouldTypeMessage] = useState(
    role === ROLE.ASSISTANT &&
      typeof content === "string" &&
      typingRef !== "undefined"
  );

  useEffect(() => {
    if (!isLast) {
      setShouldTypeMessage(false);
    }
  }, [isLast]);

  useEffect(() => {
    if (!(typeof content === "string")) return;

    if (typingRef.current) {
      typingRef.current.destroy();
    }

    const options = {
      strings: [content],
      typeSpeed: 20,
      contentType: "html",
      onComplete: () => {
        typingRef.current.stop();
        typingRef.current.destroy();
        setShouldTypeMessage(false);
        typingRef.current = null;
      },
    };

    typingRef.current = new Typed(el.current, options);
  }, []);

  return (
    <>
      <div
        className={`flex items-center gap-3 text-white/80 ${
          role === ROLE.USER ? "flex-row-reverse w-full" : ""
        }`}
      >
        {role !== ROLE.USER && (
          <div
            className={`flex justify-center items-center rounded-full w-10 h-10 bg-black/40 p-2 text-white`}
          >
            <ChatLogoSymbolIcon />
          </div>
        )}
        <div>{role === ROLE.USER ? "You" : "Lori"}</div>
      </div>
      <div
        key={_key}
        className={`p-2 flex flex-col ${typeMessage ? "animate-fadeIn" : ""} ${
          role === ROLE.USER
            ? "w-11/12 self-end bg-gradient-to-r from-[#006cff] to-[#006cff]/50"
            : "bg-black/40"
        } text-white w-6/7 m-2 mb-6 rounded-xl
            `}
      >
        <div className="self-start text-left font-extralight text-sm p-2 w-full">
          <span className={shouldTypeMessage ? "" : "hidden"} ref={el} />
          <span className={shouldTypeMessage ? "hidden" : ""}>
            {isFile ? (
              <FilePreview fileToUpload={content} />
            ) : (
              ReactHtmlParser(content)
            )}
          </span>
        </div>
        <div className="self-end text-white/40 text-xs">{time}</div>
      </div>
    </>
  );
};
