import { useEffect, useState } from "react";
import { getMetrics } from "../../api/api";
import { MetricGraph } from "../../models/models";
import { GenerateDashboardForPortfolio } from "./GenerateDashboardForPortfolio";

export const DashboardGenerator = ({
  setLoadingAnswer,
}: {
  setLoadingAnswer: (value: boolean) => void;
}) => {
  const [metrics, setMetrics] = useState<{ [key: string]: MetricGraph }>({});

  useEffect(() => {
    const fetchOptions = async () => {
      const metricsData = await getMetrics();
      let metricsOptions: { [key: string]: MetricGraph } = {};
      metricsData?.forEach((m) => (metricsOptions[m.text] = m));
      setMetrics(metricsOptions);
    };

    fetchOptions();
  }, []);

  return (
    <GenerateDashboardForPortfolio
      metrics={metrics}
      setLoadingAnswer={setLoadingAnswer}
    />
  );
};
