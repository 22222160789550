import { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import highRentMarker from "../assets/maps/highRentMarker.svg";
import mediumRentMarker from "../assets/maps/mediumRentMarker.svg";
import lowRentMarker from "../assets/maps/lowRentMarker.svg";
import homeMarker from "../assets/maps/homeMarker.svg";

type MapProps = {
  coordinates: [number, number];
  rentalData: RentalDataDTO[];
  compHover?: number;
  setCompHover: React.Dispatch<React.SetStateAction<number>>;
  avgPropertyRent: number;
};

import L from "leaflet";
import { CloseIcon, MapPin } from "./Icons";
import { SmallText } from "../styles/Typography";
import {
  capitalizeFirst,
  copyToClipboard,
  displayValue,
} from "../utils/helpers";
import { RentalDataDTO } from "../models/models";

// Assign icons based on rent amount
const getIconUrl = (
  avgPropertyRent: number,
  rent?: number,
  isHighlighted?: boolean
) => {
  if (isHighlighted) {
    return homeMarker;
  }
  if (
    rent &&
    rent > avgPropertyRent &&
    (Math.abs(rent - avgPropertyRent) / rent) * 100 > 5
  )
    return highRentMarker;
  if (rent && (Math.abs(rent - avgPropertyRent) / rent) * 100 < 5)
    return mediumRentMarker;
  return lowRentMarker;
};

const getIcon = (
  avgPropertyRent: number,
  rent?: number,
  isHighlighted?: boolean,
  hover?: boolean
) => {
  return L.divIcon({
    className: "custom-marker",
    html: `<div class="bg-contain ${
      hover ? "brightness-75 contrast-150 saturate-150" : ""
    }" style="width:30px;height:30px;${
      isHighlighted ? "transform:scale(1.5); pointer-events: none;" : ""
    };z-index:${
      hover ? "999!important" : "-1!important"
    };background-image:url(${getIconUrl(
      avgPropertyRent,
      rent,
      isHighlighted
    )});"/>`,
  });
};

export const MapLegend = ({
  rentalData,
  compHover,
  setCompHover,
  avgPropertyRent,
}: {
  rentalData: RentalDataDTO[];
  compHover?: number;
  setCompHover: React.Dispatch<React.SetStateAction<number>>;
  avgPropertyRent: number;
}) => {
  return (
    <>
      <div className="flex px-4 py-1 border-b border-b-secondary-gray-100 text-secondary-gray-600">
        <MapPin />
        <SmallText className=" font-normal !p-0 !m-0 w-[180px] !pl-5 !pt-1">
          Address
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1">Rent</SmallText>
      </div>
      <div className="h-full w-full overflow-y-auto">
        {rentalData.map((rental, index) => (
          <div
            className={`flex items-center border-b border-b-secondary-gray-100 px-4 text-secondary-gray-400 ${
              compHover === index ? " bg-blue-gray-200/20" : ""
            }
            ${index === 0 ? " hidden" : ""}
            `}
            key={index}
            onMouseEnter={() => setCompHover(index)}
            onMouseLeave={() => setCompHover(0)}
          >
            <img
              className={`grow-0 !mt-3 !mb-3`}
              src={getIconUrl(avgPropertyRent, rental.price)}
              width={25}
            />
            <SmallText className="font-normal !py-0 !px-4 !m-0 w-[200px] whitespace-nowrap truncate">
              {rental.address}
            </SmallText>
            <SmallText
              className={`font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6`}
            >
              {displayValue(rental.price, "$")}
            </SmallText>
          </div>
        ))}
      </div>
    </>
  );
};

export const MapFullLegend = ({
  rentalData,
  compHover,
  setCompHover,
  avgPropertyRent,
}: {
  rentalData: RentalDataDTO[];
  compHover?: number;
  setCompHover: React.Dispatch<React.SetStateAction<number>>;
  avgPropertyRent: number;
}) => {
  return (
    <div className="overflow-x-auto overflow-y-hidden h-full w-full">
      <div className="w-full flex justify-around p-4 py-1 border-b border-b-secondary-gray-100 text-secondary-gray-600">
        <MapPin className="min-w-[30px]" />
        <SmallText className=" font-normal !p-0 !m-0 w-[180px] min-w-[180px] !pl-5 !pt-1">
          Address
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[70px] grow-0 shrink-0 ">
          Rent
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5  w-[90px] grow-0 shrink-0 ">
          Distance
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[90px] grow-0 shrink-0 ">
          Size
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[90px] grow-0 shrink-0 ">
          Price/ft2
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[90px] grow-0 shrink-0 ">
          Beds
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[80px] grow-0 shrink-0 ">
          Baths
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !pl-5 w-[120px] grow-0 shrink-0 ">
          Buildint type
        </SmallText>
        <SmallText className=" font-normal !p-0 !m-0 !pt-1 !px-5 w-[120px] grow-0 shrink-0 whitespace-nowrap">
          Last seen
        </SmallText>
      </div>
      <div className="h-full min-w-fit w-full overflow-y-auto overflow-x-hidden pb-14">
        {rentalData.map((rental, index) => (
          <div
            className={`w-full min-w-fit flex justify-around items-center border-b border-b-secondary-gray-100 px-4 py-2 text-secondary-gray-400 place-items-center ${
              compHover === index ? " bg-blue-gray-200/20" : ""
            }
            ${index === 0 ? " hidden" : ""}
            `}
            key={index}
            onMouseEnter={() => setCompHover(index)}
            onMouseLeave={() => setCompHover(0)}
          >
            <img
              className="grow-0 !mt-2 !mb-2"
              src={getIconUrl(avgPropertyRent, rental.price)}
              width={25}
            />
            <SmallText
              className="font-normal !py-0 !px-4 !m-0 w-[180px] min-w-[180px] whitespace-nowrap truncate grow-0 shrink-0 cursor-pointer active:bg-blue-gray-100/40"
              onClick={() => copyToClipboard(rental.address)}
            >
              {rental.address}
            </SmallText>
            <SmallText className=" font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[70px]">
              {displayValue(rental.price || null, "$")}
            </SmallText>
            <SmallText className=" font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[90px]">
              {rental.distance ? rental.distance + " mi" : ""}
              {/* {L.latLng(rentalData[0].latitude, rentalData[0].longitude)
                .distanceTo(L.latLng(rental.latitude, rental.longitude))
                .toFixed(2)} */}
            </SmallText>
            <SmallText className=" font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[90px]">
              {rental.sqft ? rental.sqft + " ft2" : ""}
            </SmallText>
            <SmallText className="font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[90px]">
              {rental.dollar_sqft ? "$" + rental.dollar_sqft : ""}
            </SmallText>
            <SmallText className="font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[90px]">
              {rental.bedrooms ? rental.bedrooms + " beds" : ""}
            </SmallText>
            <SmallText className="font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[80px]">
              {rental.baths ? rental.baths : ""}
            </SmallText>
            <SmallText className="font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 !pl-5 w-[120px]">
              {rental.property_type
                ? capitalizeFirst(rental.property_type)
                : ""}
            </SmallText>
            <SmallText className="font-normal !p-0 !m-0 grow-0 shrink-0 !pr-6 w-[120px] !px-5 whitespace-nowrap">
              {rental.last_seen
                ? new Date(rental.last_seen).toLocaleDateString()
                : ""}
            </SmallText>
          </div>
        ))}
      </div>
    </div>
  );
};

const MapComponent = ({
  coordinates,
  rentalData,
  compHover,
  setCompHover,
  avgPropertyRent,
}: MapProps) => {
  const [position, setPosition] = useState(coordinates); //[51.505, -0.09]);

  return (
    <MapContainer
      center={position}
      zoom={11}
      style={{ height: "100%", width: "100%" }}
      className="!z-0"
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      {rentalData?.map((rental, index) =>
        rental.latitude && rental.longitude ? (
          rental.price ? (
            <Marker
              key={index}
              position={[rental.latitude, rental.longitude]}
              icon={getIcon(
                avgPropertyRent,
                rental.price,
                undefined,
                compHover === index
              )}
              riseOnHover
              eventHandlers={{
                mouseover: () => setCompHover(index),
                mouseout: () => setCompHover(0),
              }}
            >
              <Popup>
                {rental.address}
                <br />${rental.price}
              </Popup>
            </Marker>
          ) : (
            <Marker
              key={index}
              position={[rental.latitude, rental.longitude]}
              icon={getIcon(avgPropertyRent, rental?.price, true)}
              zIndexOffset={99}
            >
              <Popup>
                {rental.address}
                <br />${rental.price}
              </Popup>
            </Marker>
          )
        ) : (
          ""
        )
      )}
      {/* <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker> */}
    </MapContainer>
  );
};

export const MarkersLegend = () => {
  const [showLegend, setShowLegend] = useState(true);
  return (
    <div
      className={`bg-white p-4 pr-6 pt-7 rounded-md opacity-90 gap-2 border border-secondary-gray-400 flex flex-col absolute top-2 right-2 text-secondary-gray-500 font-light text-[10px] ${
        showLegend ? "" : "hidden"
      }`}
    >
      <CloseIcon
        className="absolute top-2 right-2"
        onClose={() => {
          setShowLegend(false);
        }}
      />
      <div className="flex items-center gap-1">
        <img className="h-5 w-5 !m-0" src={getIconUrl(1, 0)} />
        <span>Lower than your property avg rent</span>
      </div>
      <div className="flex items-center gap-1">
        <img className="h-5 w-5 !m-0" src={getIconUrl(1, 1)} />
        <span>Similar to your property avg rent</span>
      </div>
      <div className="flex items-center gap-1">
        <img className="h-5 w-5 !m-0" src={getIconUrl(1, 2)} />
        <span>Highr than your property avg rent</span>
      </div>
    </div>
  );
};

export default MapComponent;
