import { Option, Select } from "@material-tailwind/react";
import { ExportIcon, HomePinIcon, InfoIcon } from "@components/Icons";
import { OutlineIconButton } from "@components/Buttons";
import { useEffect, useState } from "react";
import { SmallText, Title } from "../../styles/Typography";
import {
  PropertiesDTO,
  PropertyData,
  RentalDataDTO,
} from "../../models/models";
import { sortById } from "../../utils/helpers";
import { InfoPopup } from "@components/InfoPopup";
import { FeedbackBox } from "@components/FeedbackBox";
import { LeftDashboard } from "./LeftDashboard";
import { RightDashboard } from "./RightDashboard";
import {
  compsExport,
  getCompsProperties,
  getRentalData,
  getRooms,
} from "../../api/api";

function Comps() {
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<PropertyData>();
  const [selectedBedrooms, setSelectedBedrooms] = useState<number>();
  // const [selectedBaths, setSelectedBaths] = useState<string>();
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [bedrooms, setBedrooms] = useState<number[]>([]);
  const [rentalData, setRentalData] = useState<RentalDataDTO[]>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  // const [baths, setBaths] = useState<string[]>([]);
  const [properties, setProperties] = useState<PropertiesDTO>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const sortedProperties = sortById(
          await getCompsProperties()
        ) as PropertiesDTO;
        setProperties(sortedProperties);
        setIsLoading(false);
      } catch (e) {
        console.error(`Faild to get Properties: ${e}`);
        setIsLoading(false);
      }
    };

    !properties?.length && fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedAddress) return;
        setIsLoading(true);
        const rooms = await getRooms(selectedAddress);
        setBedrooms(rooms.bedrooms);
        setIsLoading(false);
      } catch (e) {
        console.error(`Faild to get Properties: ${e}`);
        setIsLoading(false);
      }
    };

    selectedAddress && fetchData();
  }, [selectedAddress]);

  useEffect(() => {
    const getData = async () => {
      if (selectedAddress && selectedBedrooms) {
        try {
          setIsLoading(true);
          setRentalData(
            await getRentalData({
              address: selectedAddress,
              bedrooms: selectedBedrooms,
            })
          );
          setIsLoading(false);
        } catch (e) {
          console.error(`Faild to get Properties: ${e}`);
          setIsLoading(false);
        }
      }
    };

    getData();
  }, [selectedAddress, selectedBedrooms]);

  const handlePropertySelect = (p: PropertyData) => {
    try {
      setIsLoading(true);
      setBedrooms([]);
      setSelectedBedrooms(undefined);
      setSelectedAddress(undefined);
      setSelectedProperty(p);
      p.address?.length && setSelectedAddress(p.address);
      setIsLoading(false);
    } catch (e) {
      console.error(`Faild to get Properties: ${e}`);
      setIsLoading(false);
    }
  };

  return (
    <div className="p-8 w-full h-full">
      <div className="flex gap-2 items-center w-full">
        <Title className="!m-0">Comps</Title>
        <InfoIcon onClick={() => setShowInfoPopup(true)} />
        {showInfoPopup && (
          <InfoPopup
            dismissPopup={() => setShowInfoPopup(false)}
            title={"Comps"}
            text={`A 360° overview for all of your properties' comparables.`}
          />
        )}
        <FeedbackBox sectionName="comps" />
        <OutlineIconButton
          className={`!text-primary !px-5 !py-5 float-rightExportIcon absolute right-16 ${
            selectedAddress && selectedBedrooms
              ? ""
              : "opacity-60 pointer-events-none"
          }`}
          onClick={async () => {
            try {
              await compsExport({
                address: selectedAddress || "",
                bedrooms: selectedBedrooms || 0,
              });
            } catch (e) {
              setError("Export not available");
            }
          }}
        >
          <ExportIcon /> Export
        </OutlineIconButton>
        <div className="absolute top-20 right-[70px] text-red-400">
          {error ? error : ""}
        </div>
      </div>
      <div className="w-full h-full">
        <div className="flex gap-2 w-fit my-6 z-10">
          {properties?.length ? (
            <>
              <div className=" min-w-[320px] overflow-x-clip">
                <Select
                  className="bg-secondary-gray-200 border-none !border-t-transparent align-middle h-[40px] pr-8 whitespace-nowrap overflow-ellipsis"
                  labelProps={{
                    className: `before:!hidden after:!hidden`,
                  }}
                  disabled={isLoading}
                  value={selectedProperty?.name || "Select Property"}
                >
                  {properties.map((p) => (
                    <Option
                      className="list-none"
                      key={p.id}
                      id={p.id}
                      onClick={() => handlePropertySelect(p)}
                    >
                      {p.name}
                    </Option>
                  ))}
                </Select>
              </div>

              {selectedAddress ? (
                bedrooms?.length ? (
                  <Select
                    className=" bg-secondary-gray-200 border-none !border-t-transparent align-middle absolute top-0 right-0 h-[40px]"
                    labelProps={{
                      className: `before:!hidden after:!hidden`,
                    }}
                    disabled={isLoading}
                    value={
                      selectedBedrooms
                        ? selectedBedrooms + " bed(s)"
                        : "Select bedrooms number"
                    }
                  >
                    {bedrooms
                      .filter((r) => r)
                      .map((r) => (
                        <Option
                          className="list-none"
                          key={r}
                          id={r.toString()}
                          onClick={() => setSelectedBedrooms(r)}
                        >
                          {r + " bed(s)"}
                        </Option>
                      ))}
                  </Select>
                ) : (
                  <SmallText className="!h-[40px] !w-[300px] !m-0 !flex justify-center items-center">
                    Loading rooms selection...
                  </SmallText>
                )
              ) : (
                ""
              )}
              {/* 
              <Select
                className=" bg-secondary-gray-200 border-none !border-t-transparent align-middle absolute top-0 right-0 h-[40px]"
                labelProps={{
                  className: `before:!hidden after:!hidden`,
                }}
                value={
                  selectedBaths
                    ? selectedBaths + " bath(s)"
                    : "Select baths number"
                }
              >
                {baths.map((r) => (
                  <Option
                    className="list-none"
                    key={r}
                    id={r.toString()}
                    onClick={() => setSelectedBaths(r)}
                  >
                    {r + " bath(s)"}
                  </Option>
                ))}
              </Select> */}
            </>
          ) : (
            <SmallText>Loading data...</SmallText>
          )}
        </div>
        {selectedAddress && selectedBedrooms ? (
          rentalData?.length ? (
            <>
              <div className=" z-0 absolute top-42 pr-16 pl-8 pb-3 left-0 w-full justify-between flex border-b border-b-secondary-gray-350 text-secondary-gray-400">
                <div className="flex gap-1 items-center !mt-0  !mb-0">
                  <HomePinIcon />
                  <SmallText className=" !mt-0  !mb-0">
                    {selectedAddress}
                  </SmallText>
                </div>
                <SmallText className=" !mt-0  !mb-0">
                  Based on <b>{rentalData?.length}</b> units around the
                  property.
                </SmallText>
              </div>
              <div className="absolute flex w-full h-full left-0 top-44 mt-[10px] z-0">
                <LeftDashboard
                  address={selectedAddress}
                  bedrooms={selectedBedrooms || null}
                  rentalData={rentalData}
                />
                <RightDashboard
                  address={selectedAddress}
                  bedrooms={selectedBedrooms || null}
                  isLoading={isLoading}
                />
              </div>
            </>
          ) : (
            <SmallText>Loading comps...</SmallText>
          )
        ) : rentalData?.length === 0 ||
          (selectedProperty && !selectedProperty.address) ? (
          <SmallText>No comps found for this combination</SmallText>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Comps;
