import { useEffect, useState } from "react";
import { KpisDropdown } from "./FilterDropdown";
import { Select, Option } from "@material-tailwind/react";
import {
  getProperties,
  getPropertiesOverall,
  updateGraphParam,
} from "../api/api";
import {
  setProperties,
  setPropertiesOverall,
} from "../features/properties/properties-slice";
import { useAppDispatch } from "../app/hooks";
import {
  BreakDownOptions,
  GranularityType,
  TrendGraphDTO,
} from "../models/models";
import { updateTrend } from "../features/trends/trends-slice";
import { AppDropdown } from "./Inputs";

export const CustomizeProperties = () => {
  const [kpis, setKpis] = useState({
    "Expired Leases": { isSelected: false },
    Collection: { isSelected: false },
    Budget: { isSelected: false },
    Noi: { isSelected: false },
    Occupancy: { isSelected: false },
    Delinquency: { isSelected: false },
    "Expected Renovations": { isSelected: false },
    Turnovers: { isSelected: false },
  });
  const [customizePopupVisible, setCustomizePopupVisible] = useState(true);
  // const kpis = [
  //     {
  //         title: {value:'Lease Ratio', isSelected: false},
  //         components: [
  //             {value:'Visits', isSelected: false},
  //             {value:'Applicants', isSelected: false},
  //         ]
  //     },
  //     {
  //         title: {value: 'Collection', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Budget', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Noi', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Occupancy', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Delinquency', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Expected renovations', isSelected: false}
  //     },
  //     {
  //         title: {value: 'Expected turnovers', isSelected: false}
  //     },

  // ]

  return (
    <>
      <KpisDropdown
        options={Object.keys(kpis)}
        applyFilter={(selectedOptions) => {
          const options = { ...kpis };
          selectedOptions.forEach((o) => (options[o].isSelected = true));
          setKpis(options);
        }}
      />
      {/* <div className="flex gap-3 items-center">
        <OutlineButton onClick={() => setCustomizePopupVisible(true)}>
          Customize
        </OutlineButton>
      </div>
      {customizePopupVisible && (
        <Popup
          title={"Choose up to 6 KPIs to display:"}
          closePopup={() => setCustomizePopupVisible(false)}
          className="top-32 left-56"
        >
          <>
            {kpis && (
              <FilterDropdown
                options={Object.keys(kpis)}
                applyFilter={(selectedOptions) => {
                  selectedOptions.forEach((o) => (kpis[o].isSelected = true));
                }}
              />
            )}
            <div className="border-b border-secondary-gray-200 pb-2">
              <div className="bg-primary-very-light rounded-lg p-2 mb-4"></div>
            </div>
          </>
        </Popup>
      )} */}
    </>
  );
};

export const CustomizePeriod = ({
  setIsLoading,
}: {
  setIsLoading: (isLoading: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  let date = new Date();
  date.setDate(1); //the first day of current month

  const [period, setPeriod] = useState<Date>(date);

  const handlePeriodChange = async (p: Date) => {
    setIsLoading(true);
    setPeriod(new Date(p));
    const j = p.toJSON();
    try {
      dispatch(setPropertiesOverall(await getPropertiesOverall(j)));
    } catch (e) {
      console.error(`Faild to get Properties Overall: ${e}`);
      setIsLoading(false);
    }
    try {
      dispatch(setProperties(await getProperties(j)));
    } catch (e) {
      console.error(`Faild to get Properties: ${e}`);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getPeriods = () => {
    let d = new Date();
    d.setDate(1);
    let periods = [new Date(d)];
    for (let i = 0; i < 18; i++) {
      //1.5 years history
      d.setMonth(d.getMonth() - 1);
      periods.push(new Date(d));
    }
    return periods;
  };

  const options = getPeriods();

  return (
    <Select
      className="bg-secondary-gray-300 border-none !border-t-transparent align-middle"
      labelProps={{
        className: `before:!hidden after:!hidden`,
      }}
      value={`${period.toLocaleString("default", {
        month: "long",
        year: "numeric",
      })} (MOM)`}
    >
      {options.map((o) => (
        <Option
          className="list-none"
          id={o.toJSON()}
          onClick={() => handlePeriodChange(o)}
        >
          {o.toLocaleString("default", { month: "long", year: "numeric" })}
        </Option>
      ))}
    </Select>
  );
};

export const CustomizeBreakdownPeriod = ({
  setIsLoading,
  graphId,
  granularity,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  graphId: string;
  granularity: GranularityType;
}) => {
  const dispatch = useAppDispatch();
  let date = new Date();
  date.setDate(1); //the first day of current month

  const [breakdownPeriod, setBreakdownPeriod] = useState<string>(
    BreakDownOptions[granularity]
  );

  const handleBreakdownPeriodChange = async (
    graphId: string,
    granularity: GranularityType
  ) => {
    setIsLoading && setIsLoading(true);
    setBreakdownPeriod(BreakDownOptions[granularity]);
    try {
      dispatch(
        updateTrend(await updateGraphParam({ id: graphId, granularity }))
      );
    } catch (e) {
      console.error(`Faild to get Properties Overall: ${e}`);
      setIsLoading && setIsLoading(false);
    }
    setIsLoading && setIsLoading(false);
  };

  return breakdownPeriod ? (
    <Select
      className=" bg-secondary-gray-200 border-none !border-t-transparent align-middle absolute max-w-[140px] top-0 right-0 h-[30px]"
      labelProps={{
        className: `before:!hidden after:!hidden`,
      }}
      value={breakdownPeriod}
    >
      {Object.keys(BreakDownOptions).map((o) => (
        <Option
          className="list-none"
          id={o || "NONE"}
          onClick={() => handleBreakdownPeriodChange(graphId, o)}
        >
          {BreakDownOptions[o] || "No Breakdown"}
        </Option>
      ))}
    </Select>
  ) : (
    ""
  );
};

export const CustomizeSelectedProperties = ({
  setIsLoading,
  graphId,
  properties,
  allProperties,
  updateGraph,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  graphId: string;
  properties: string[];
  allProperties: string[];
  updateGraph?: (newValue: TrendGraphDTO) => void;
}) => {
  const dispatch = useAppDispatch();
  let date = new Date();
  date.setDate(1); //the first day of current month

  const [updatedProperties, setUpdatedProperties] = useState<{
    [p: string]: boolean;
  }>({});

  const getPropertiesMap = (newProperties: string[]) => {
    const p = allProperties.reduce((acc, property) => {
      acc[property] = newProperties.includes(property);
      return acc;
    }, {} as { [p: string]: boolean });
    return p;
  };
  useEffect(() => {
    if (!Object.keys(updatedProperties)?.length) {
      const initialProperties = getPropertiesMap(properties);
      setUpdatedProperties(initialProperties);
    }
  }, []);

  const handlePropertiesChange = async (newProperties: string[]) => {
    setIsLoading && setIsLoading(true);
    setUpdatedProperties(getPropertiesMap(newProperties));
    try {
      const newGraph = await updateGraphParam({
        id: graphId,
        properties: newProperties,
      });
      if (newGraph) {
        dispatch(updateTrend(newGraph));
        updateGraph && updateGraph(newGraph);
      }
    } catch (e) {
      console.error(`Faild to get Properties Overall: ${e}`);
      setIsLoading && setIsLoading(false);
    }
    setIsLoading && setIsLoading(false);
  };

  return (
    <>
      <AppDropdown
        preSelect={updatedProperties}
        label={"Properties"}
        searchLabel="property"
        options={allProperties}
        defaultSelectAll={false}
        onSelectCallback={(selected) => {
          handlePropertiesChange(selected);
        }}
        buttonClassName=" !text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 border-t-transparent !bg-secondary-gray-200 border-none align-middle max-w-[140px] w-[140px] !h-[30px] !py-[5px]"
      />
    </>
  );
};
