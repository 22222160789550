import { LargeText, SmallText } from "../../styles/Typography";

type IconMessageLayoutProps = {
  icon: JSX.Element;
  title: string;
  text: string | JSX.Element;
};

export const IconMessageLayout = ({
  icon,
  title,
  text,
}: IconMessageLayoutProps) => (
  <div className="m-auto text-center h-full max-h-[500px] flex flex-col gap-6 justify-center items-center">
    {icon}
    <div>
      <LargeText>{title}</LargeText>
      <SmallText>{text}</SmallText>
    </div>
  </div>
);
