import { useState } from "react";
import { SmallText } from "../../styles/Typography";
import { AppDropdown } from "../Inputs";
import { capitalizeAll } from "../../utils/helpers";

export const DropdownFilter = ({
  btnTitle,
  options,
  onSelectCallback,
  multiSelect,
  showSearch,
  className,
  small,
  maxOptions,
}: {
  btnTitle: string | JSX.Element;
  options: string[];
  onSelectCallback: (properties: string[]) => void;
  multiSelect: boolean;
  showSearch?: boolean;
  className?: string;
  small?: boolean;
  maxOptions?: number;
}) => {
  const [selectedOptions, setSelectedOptions] = useState("");

  return (
    <>
      {options?.length ? (
        <>
          <div
            className={`border-[1px] border-secondary-gray-350 rounded-sm my-2 p-2 ${
              className || ""
            }`}
          >
            <AppDropdown
              multiSelect={multiSelect}
              maxOptions={maxOptions}
              showSearch={showSearch}
              defaultSelectAll={false}
              className={`${
                small ? "max-h-fit w-fit" : "max-h-64 min-w-[200px] w-full"
              } overflow-scroll`}
              DropdownButton={() => (
                <div className="text-xs px-2 cursor-pointer">{btnTitle}</div>
              )}
              options={options}
              onSelectCallback={(selected: string[]) => {
                setSelectedOptions(
                  selected.map((s) => capitalizeAll(s)).join(",")
                );
                onSelectCallback(selected);
              }}
              showSelectAllBtn={false}
              actions={{
                primary: { title: "Apply", action: () => {} },
              }}
            />
          </div>
          <SmallText className="text-primary font-semibold text-xs !m-0 p-1">
            {selectedOptions}
          </SmallText>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
};
