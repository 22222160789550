import { Typography } from "@material-tailwind/react";
import { LogoSymbolIcon, LogoTextIcon } from "./Icons";

export const LoriLoader = () => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center">
      <div className="flex gap-2 items-center justify-center w-full motion-safe:animate-[pulse_2s_infinite]">
        <LogoSymbolIcon
          className="motion-safe:animate-slow-spin delay-0"
          width={50}
          height={50}
        />
        <LogoTextIcon width={192} height={28} />
      </div>
      <Typography className="ml-12">
        Lori is juggling the numbers! 🔧
      </Typography>
    </div>
  );
};
