import { useAuth0 } from "@auth0/auth0-react";

import { createContext, useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import { User } from "./models/models";
import { getTrends, getUserData, setAuthToken } from "./api/api";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { setTrends } from "./features/trends/trends-slice";
import { setUserData } from "./features/user/user-slice";
import AppLoader from "./components/AppLoader.tsx";
import { LogoSymbolIcon, LogoTextIcon } from "./components/Icons.tsx";
import { PrimaryButton } from "./components/Buttons.tsx";
export const AppContext = createContext<{ user: User | null }>({ user: null });

const LoginButton = () => {
  const { loginWithRedirect, error } = useAuth0();

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (err) {
      console.error("Login error: ", err);
    }
  };

  return (
    <div className="flex flex-col gap-20 items-center justify-center w-full h-screen basis-2">
      <div className="flex items-center justify-center gap-2">
        <LogoSymbolIcon width={50} height={50} />
        <LogoTextIcon width={192} height={28} />
      </div>
      <div>
        <PrimaryButton onClick={handleLogin}>Log In</PrimaryButton>

        {error && <p>Login failed: {error.message}</p>}
      </div>
    </div>
  );
};

function App() {
  const dispatch = useAppDispatch();
  const [tokenExpired, setTokenExpired] = useState(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const userData = useAppSelector((state) => state.userData.user);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({});
        localStorage.setItem("id_token", accessToken);
        setAuthToken(accessToken);
        if (user) {
          const u = await getUserData();

          dispatch(setTrends(await getTrends()));
          dispatch(setUserData(u));
        }
      } catch (e: any) {
        console.log(e.message);
        setTokenExpired(true);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user]);

  if (isLoading) {
    return <AppLoader />;
  }

  if (tokenExpired || !isAuthenticated) {
    return <LoginButton />;
  }

  return userData ? (
    <AppContext.Provider value={{ user: { ...user, ...userData } }}>
      <Home />
    </AppContext.Provider>
  ) : (
    <AppLoader />
  );
}

export default App;
