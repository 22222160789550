import { Typography } from "@material-tailwind/react";
import { GenerateIcon } from "@components/Icons";
import { OutlineButton, PrimaryButton } from "@components/Buttons";
import { useEffect, useRef, useState } from "react";
import { Popup } from "@components/Popup";
import { Graphs } from "@components/Graph";
import {
  generateTrendGraphByPrompt,
  getGraphSuggestions,
  getGraphs,
} from "../api/api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  addTrend,
  setTrends,
  removeTrendOnProccesing,
  addTrendOnProccesing,
} from "../features/trends/trends-slice";
import { DashboardGenerator } from "@components/DashboardGenerator/DashboardGenerator";
import { LargeText, SmallText } from "../styles/Typography";
import { FreeTextPrompts } from "@components/FreeTextPrompts";
import { TrendGraphDTO } from "../models/models";
import { shallowEqual } from "react-redux";
import { LoriLoader } from "@components/LoriLoader";
import { capitalizeAll } from "../utils/helpers";

function Dashboard() {
  const dispatch = useAppDispatch();
  const trends = useAppSelector((state) => state.trends.trends, shallowEqual);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [graphs, setGraphs] = useState<TrendGraphDTO[]>([]);
  const graphsInProcess = useAppSelector(
    (state) => state.trends.trendsOnProccesing
  );
  const [graphPrompts, setGraphPrompts] = useState<
    { text: string; graph_name: string }[]
  >([]);
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const newGraphRef = useRef<HTMLDivElement>();
  const [waitForAction, setWaitForAction] = useState(false);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    const getData = async () => {
      setWaitForAction(true);
      setGraphPrompts(await getGraphSuggestions());
      const t = await getGraphs();
      dispatch(setTrends(t));
      setWaitForAction(false);
    };
    getData();
  }, []);

  useEffect(() => {
    newGraphRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }, [loadingAnswer]);

  useEffect(() => {
    trends?.length ? setGraphs(trends) : setGraphs([]);
  }, [trends]);

  const generateDashboard = async (prompt: string, track?: boolean) => {
    try {
      setError(null);
      // setWaitForAction(true);
      setIsPopupVisible(false);
      // setLoadingAnswer(true);
      dispatch(addTrendOnProccesing());
      const graphJson = await generateTrendGraphByPrompt(prompt, track);
      if (graphJson) {
        dispatch(addTrend(graphJson));
      } else {
        dispatch(removeTrendOnProccesing());
        setError("No data available for this time period.");
        setTimeout(() => {
          setError(null);
        }, 7000);
      }
      // setLoadingAnswer(false);
    } catch (e) {
      // setLoadingAnswer(false);
      dispatch(removeTrendOnProccesing());
    } finally {
      // setWaitForAction(false);
    }
  };
  return (
    <>
      <div
        className={`flex w-full h-full bg-white ${
          waitForAction ? "pointer-events-none animate-pulse" : ""
        }`}
      >
        <div
          style={{ minHeight: "calc(100vh - 54px)" }}
          className="flex flex-col border-r-[1px] border-r-secondary-gray-350 min-w-[270px] max-w-[270px]"
        >
          <DashboardGenerator setLoadingAnswer={setLoadingAnswer} />
        </div>
        <div className="flex flex-col h-full w-full p-10 overflow-auto">
          <div className={`flex justify-between items-center pb-6`}>
            <LargeText>My Dashboards</LargeText>
            <div className="relative flex gap-8">
              {error ? (
                <Typography className="text-center text-red-500 bg-red-50/20 rounded-md p-2 !mt-0 !mb-0 ">
                  {error}
                </Typography>
              ) : (
                ""
              )}
              <OutlineButton
                onClick={() => setIsPopupVisible(true)}
                className={`opacity-50 pointer-events-none ${
                  graphs?.length || graphsInProcess ? "" : "hidden"
                } 
              `}
              >
                + Lori's Recommendations
              </OutlineButton>
              <div
                className={`absolute top-[33px] right-0 left-0 w-fit m-auto whitespace-nowrap text-[10px] bg-black text-white px-3 rounded-full ${
                  graphs?.length || graphsInProcess ? "" : "hidden"
                }`}
              >
                Coming soon!
              </div>
            </div>
          </div>
          <div
            style={{ height: `calc(100% - 154px)` }}
            className={`width-full `}
          >
            <div className="flex flex-wrap gap-4 py-10 width-full overflow-x-hidden overflow-y-auto">
              {Array.from(
                { length: graphsInProcess },
                (_, index) => index + 1
              ).map(() => (
                <div
                  className="w-[478px] h-[360px] max-w-[478px] max-h-[360px] flex flex-col items-center justify-center relative border border-secondary-gray-350 rounded-md p-8 my-4"
                  ref={newGraphRef}
                >
                  <LoriLoader />
                </div>
              ))}

              {trends?.length ? (
                <Graphs trends={trends} />
              ) : (
                <div
                  className={`flex flex-col gap-2 justify-center items-center w-96 pt-10 m-auto h-full ${
                    loadingAnswer ? "hidden" : ""
                  }`}
                >
                  <GenerateIcon />
                  <LargeText>
                    {capitalizeAll("Generate your first dashboard!")}
                  </LargeText>
                  <SmallText className="text-secondary-gray-400">
                    Here you can generate custom dashboards that could help you
                    monitor your assets with any kind of cross-data you want.
                  </SmallText>
                </div>
              )}
            </div>
          </div>
          {isPopupVisible && (
            <Popup
              closePositionRight={true}
              title={"Generate Dashboard"}
              closePopup={() => setIsPopupVisible(false)}
              className="flex flex-col min-w-[600px] max-w-[600px] h-[300px] m-auto top-[10%] left-0 right-0 pt-4"
            >
              {/* <div className="border-b border-secondary-gray-200 pb-2 flex-1">
                <div className="bg-primary-very-light rounded-lg p-2 mb-4">
                  <div className="flex items-center gap-1 justify-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-3 h-3 fill-primary"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="text-primary font-semibold">
                      Try something like
                    </div>
                  </div>

                  <SmallText className="!m-0 text-secondary-gray-500">
                    “please create a dashboard that compares properties 16th St
                    and 2th St budget plan”.
                  </SmallText>
                </div>
              </div> */}
              <FreeTextPrompts
                className="mt-4"
                placeholder="Describe your dashboard..."
                suggestions={
                  graphPrompts
                  //   [
                  //   // "I would like to see [Rental Income] of Property [1,2] based on [Monthly] Time Period.",
                  //   // "I would like to see [Expenses] of Property [5] based on [Maintenance] Category.",
                  //   // "Show me my monthly water consumption for 2023.",
                  //   "Show me my rent average across my portfolio for the past 9 months",
                  //   "Show me my month by month collection across my portfolio",
                  //   "Show me my month by month collection for property [x]",
                  //   "Show me my quarterly collection across my portfolio",
                  //   "Show me the actual collection amount per unit type vs budgeted collection amount per unit type  at the portfolio level (filter-time range)",
                  //   "Show me my monthly average rent per unit type VS occupancy per unit type",
                  //   "Track my yearly NOI performence.",
                  //   "Track my quarterly NOI performence.",
                  //   "Track my monthly NOI performence.",
                  //   "Show me my monthly actual collection amount per unit type vs budgeted collection amount per unit type at the portfolio level",
                  // ]
                }
                generate={generateDashboard}
              />
            </Popup>
          )}
          <div className="w-fit m-auto text-center relative">
            <PrimaryButton
              className={`!w-66 m-auto opacity-50 pointer-events-none ${
                graphs?.length || graphsInProcess || loadingAnswer
                  ? "hidden"
                  : ""
              }`}
              onClick={() => setIsPopupVisible(true)}
            >
              + Lori's Recommendations
            </PrimaryButton>
            <div
              className={`absolute top-[33px] right-0 left-0 w-fit m-auto text-[10px] bg-black text-white px-3 rounded-full ${
                graphs?.length || graphsInProcess || loadingAnswer
                  ? "hidden"
                  : ""
              }`}
            >
              Coming soon!
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
