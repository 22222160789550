import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
} from "@material-tailwind/react";
import {
  AcademicCapIcon,
  CubeTransparentIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  PowerIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { AppContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../models/models";
import { MainMenu } from "./MainMenu";
import { Logo } from "./Logo";
import { setAuthToken } from "../api/api";
import { LargeText, SmallText } from "../styles/Typography";

type AvatarProps = {
  src?: string;
  alt?: string;
};
const Avatar = ({ src, alt }: AvatarProps) => {
  return <img className="rounded-full w-8 h-8 p-0.5" src={src} alt={alt} />;
};

type ProfileMenuProps = {
  user: User;
};

function ProfileMenu({ user }: ProfileMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  const { logout } = useAuth0();
  const profileMenuItems = [
    {
      label: "Sign Out",
      icon: PowerIcon,
      callback: () => {
        localStorage.removeItem("id_token");
        setAuthToken(null);
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
    },
  ];

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <>
            <Avatar src={user?.picture} alt={user?.name} />
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${
                isMenuOpen ? "rotate-180" : ""
              }`}
            />
          </>
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, callback }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={() => {
                callback();
                closeMenu();
              }}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

// nav list menu
const navListMenuItems = [
  {
    title: "Internal report",
    description: "for C-levels and for company’s employees.",
  },
  {
    title: "LP report",
    description: "Generate quick report for LP.",
  },
  {
    title: "Lenders report",
    description: "Generate quick for lenders.",
  },
  {
    title: "Payments",
    description: "A complete report of you last payments.",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const renderItems = navListMenuItems.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <LargeText className="mb-1">{title}</LargeText>
        <SmallText className="font-normal text-secondary-gray-400">
          {description}
        </SmallText>
      </MenuItem>
    </a>
  ));

  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <RocketLaunchIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full">
              <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Docs{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Pages{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// nav list component
const navListItems = [
  {
    label: "Overview",
    icon: AcademicCapIcon,
  },
  {
    label: "Insights",
    icon: CubeTransparentIcon,
  },
];

function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon }) => (
        <Typography
          key={label}
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
            {label}
          </MenuItem>
        </Typography>
      ))}
      <NavListMenu />
    </ul>
  );
}

export function AppNav({
  shouldDisplayComps,
}: {
  shouldDisplayComps: boolean;
}) {
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  return (
    <AppContext.Consumer>
      {({ user }) => (
        <Navbar className="mx-auto p-2 rounded-none lg:pl-6 z-10 max-w-none">
          <div className="mx-auto w-full gap-10 flex justify-between text-blue-gray-900">
            <Logo />
            <div className="w-3/4 flex justify-centerw-full ml-20">
              <MainMenu shouldDisplayComps={shouldDisplayComps} />
            </div>
            <div className="flex items-center">
              <ProfileMenu user={user} />
            </div>
          </div>
          <Collapse open={isNavOpen} className="overflow-scroll">
            <NavList />
          </Collapse>
        </Navbar>
      )}
    </AppContext.Consumer>
  );
}
