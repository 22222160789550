import { MetricGraph } from "../../models/models";
import { DropdownFilter } from "./DropdownFilter";

export const SelectMetric = ({
  metrics,
  setSelectedMetric,
}: {
  metrics: { [key: string]: MetricGraph };
  setSelectedMetric: (m: MetricGraph) => void;
}) => {
  const handleSelect = (selected: string) => {
    metrics && setSelectedMetric(metrics[selected]);
  };

  return (
    <div className="my-6">
      <DropdownFilter
        btnTitle={"Select Metric"}
        options={Object.keys(metrics || [])}
        onSelectCallback={(selected) => {
          handleSelect(selected[0]);
        }}
        multiSelect={false}
        showSearch={true}
      />
    </div>
  );
};
