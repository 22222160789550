import { useState } from "react";
import { MetricGraph } from "../../models/models";
import { ComplementaryData } from "./ComplementaryData";
import { SelectMetric } from "./SelectMetric";
import { SmallText } from "../../styles/Typography";

export const GenerateDashboardForPortfolio = ({
  metrics,
  setLoadingAnswer,
}: {
  metrics: { [key: string]: MetricGraph };
  setLoadingAnswer: (value: boolean) => void;
}) => {
  const [selectedMetric, setSelectedMetric] = useState<MetricGraph>();

  return (
    <div className="p-6 overflow-y-auto pb-10 h-full">
      <SmallText className="m-0 pb-0">Generate Dashboard By Metric:</SmallText>
      <SelectMetric metrics={metrics} setSelectedMetric={setSelectedMetric} />
      {selectedMetric ? (
        <ComplementaryData
          selectedMetric={selectedMetric}
          setLoadingAnswer={setLoadingAnswer}
        />
      ) : (
        ""
      )}
    </div>
  );
};
