import {LogoSymbolIcon, LogoTextIcon} from "./Icons.tsx";

const AppLoader = () => {
    return (
        <div className="flex gap-2 items-center justify-center w-full h-screen motion-safe:animate-[pulse_2s_infinite]">
            <LogoSymbolIcon className="motion-safe:animate-slow-spin delay-0" width={50} height={50}/>
            <LogoTextIcon width={192} height={28}/>
        </div>
    )
};

export default AppLoader
