import { configureStore } from '@reduxjs/toolkit'
import NotificationsSlice from '../features/notifications/notifications-slice';
import UserSlice from '../features/user/user-slice';
import trendsSlice from '../features/trends/trends-slice';
import propertiesSlice from '../features/properties/properties-slice';
import messagesSlice from '../features/messages/messages-slice';

export const store = configureStore({
  reducer: {
    userData: UserSlice,
    notifications: NotificationsSlice,
    properties: propertiesSlice,
    trends: trendsSlice,
    messages: messagesSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;