import { FC } from "react";
import { ReportPreview } from "../../../../../components/ReportPreview";

interface IProps {
  reportUrl: string;
}
const PreviewStep: FC<IProps> = ({ reportUrl }) => {
  return <ReportPreview reportUrl={reportUrl} />;
};

export default PreviewStep;
