import { useRef, useState } from "react";
import { SendIcon } from "./Icons";
import historyIcon from "../assets/history.svg";
import { SmallText } from "../styles/Typography";

type SearchBarProps = {
  className?: string;
  placeholder?: string;
  search: (input: string) => void;
  suggestions?: string[];
};

export const SearchBar = ({
  className,
  search,
  placeholder,
  suggestions,
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isValidInput, setIsValidInput] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  return (
    <div className="flex items-center justify-between gap-2">
      <div
        className={`flex w-full h-fit flex-col justify-between items-center rounded-[9px] border border-secondary-gray-10 bg-secondary-gray-10 ${
          className || ""
        }`}
      >
        <div className="w-full h-full flex flex-col p-2">
          <div className="flex w-full h-full justify-between items-center">
            <input
              placeholder={placeholder || "Ask me anything..."}
              className="group w-full h-full outline-none !border-0 focus:border-transparent bg-transparent text-sm"
              onFocus={() => {
                if (!inputRef?.current?.value) setShowSuggestions(true);
              }}
              onBlur={() => setShowSuggestions(false)}
              onKeyUp={() =>
                setIsValidInput(!!inputRef?.current?.value?.length)
              }
              ref={inputRef}
            />
          </div>
          <div
            className={`w-full flex flex-col group-focus:bg-cyan-500  transition-all ease-in-out duration-500 overflow-hidden ${
              showSuggestions ? "max-h-fit" : "max-h-0"
            } h-fit`}
          >
            {suggestions?.map((row) => (
              <div
                key={row.replace(" ", "_")}
                className="flex gap-1 m-0 mt-2 items-start justify-start text-start cursor-pointer"
                onClick={() => {
                  inputRef.current && (inputRef.current.value = row);
                  setIsValidInput(!!inputRef?.current?.value?.length);
                }}
              >
                <img className="!m-0" src={historyIcon} />
                <SmallText className="!m-0">{row}</SmallText>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        className={`rounded-full w-5 h-5 mt-3`}
        disabled={!isValidInput}
        onClick={() => {
          if (inputRef.current) {
            search(inputRef.current.value);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.value = "";
              }
              setIsValidInput(false);
            }, 0);
          }
        }}
      >
        {isValidInput ? (
          <SendIcon className="text-primary" />
        ) : (
          <SendIcon className=" text-secondary-gray-350" />
        )}
      </button>
    </div>
  );
};
