import { LargeText, SmallText } from "../../styles/Typography";
import MapComponent, {
  MapFullLegend,
  MapLegend,
  MarkersLegend,
} from "@components/Map";
import { getAvgRent } from "../../api/api";
import { useEffect, useState } from "react";
import { RentalDataDTO } from "../../models/models";
import { Popup } from "@components/Popup";
import { StarsCard } from "@components/StarsCard";

export const LeftDashboard = ({
  address,
  bedrooms,
  rentalData,
}: {
  address: string;
  bedrooms: number | null;
  rentalData: RentalDataDTO[];
}) => {
  const [avgRent, setAvgRent] = useState();
  const [portfolioAvgRent, setPortfolioAvgRent] = useState();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [compHover, setCompHover] = useState(0);

  useEffect(() => {
    const updateAvgRent = async () => {
      try {
        setLoading(true);
        const rent = await getAvgRent({
          address,
          bedrooms,
        });
        setAvgRent(rent.general);
        setPortfolioAvgRent(rent.portfolio);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Get Average Rent error: ", err);
      }
    };
    updateAvgRent();
  }, [address, bedrooms]);

  const diff =
    typeof avgRent === "number" && typeof portfolioAvgRent === "number"
      ? (Math.abs(avgRent - portfolioAvgRent) / portfolioAvgRent) * 100
      : 0;

  return (
    <>
      <div className="min-w-[370px] w-[20vw] grow-0 shrink-0 h-full overflow-y-auto pb-64 border-r border-r-secondary-gray-350 p-8 !z-1">
        {loading || !avgRent || portfolioAvgRent === undefined ? (
          <SmallText>Loading data...</SmallText>
        ) : (
          <>
            <StarsCard
              avgRent={avgRent}
              portfolioAvgRent={portfolioAvgRent}
              diff={diff}
            />
            <div
              className="my-4 bg-auto relative border border-secondary-gray-350 !text-white rounded-xl w-full overflow-hidden pb-10"
              onClick={() => setShowPopup(true)}
            >
              <div className="h-48 border-b rounded-t-lg overflow-hidden">
                {rentalData?.length ? (
                  <MapComponent
                    coordinates={[
                      rentalData[0].latitude,
                      rentalData[0].longitude,
                    ]}
                    rentalData={rentalData}
                    compHover={compHover}
                    setCompHover={setCompHover}
                    avgPropertyRent={portfolioAvgRent || 0}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="h-64">
                <MapLegend
                  rentalData={rentalData}
                  compHover={compHover}
                  setCompHover={setCompHover}
                  avgPropertyRent={portfolioAvgRent || 0}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {showPopup ? (
        <Popup
          title={""}
          closePopup={() => setShowPopup(false)}
          closePositionRight={true}
          className="!w-[85vw] h-[85vh] top-[-135px] m-auto left-0 right-0 !z-999 overflow-hidden"
        >
          <LargeText className="mx-10 !font-normal !text-[22px]">
            Nearby Analysis
          </LargeText>
          <SmallText className="mx-10 !text-[14px] text-secondary-gray-400 !mt-0">
            Here is the full breakdown of the properties nearby.
          </SmallText>
          <div className="mx-9 my-2 w-[95%] bg-auto relative border border-secondary-gray-350 !text-white rounded-xl overflow-hidden">
            <div className="min-h-[300px] h-[20vw] max-h-[750px] border-b rounded-t-lg overflow-hidden !z-0">
              {rentalData?.length ? (
                <>
                  <MapComponent
                    coordinates={[
                      rentalData[0].latitude,
                      rentalData[0].longitude,
                    ]}
                    rentalData={rentalData}
                    compHover={compHover}
                    setCompHover={setCompHover}
                    avgPropertyRent={portfolioAvgRent || 0}
                  />
                  <MarkersLegend />
                </>
              ) : (
                ""
              )}
            </div>
            <div
              className=" h-60 z-1"
              style={{
                height: "calc(75vh - 20vw)",
                maxHeight: "calc(75vh - 300px)",
              }}
            >
              <MapFullLegend
                rentalData={rentalData}
                compHover={compHover}
                setCompHover={setCompHover}
                avgPropertyRent={portfolioAvgRent || 0}
              />
            </div>
          </div>
        </Popup>
      ) : (
        ""
      )}
    </>
  );
};
