import { useRef } from "react";
import { FileIcon, PdfFileIcon, UploadIcon, XslFileIcon } from "./Icons";
import { IconSize } from "./Drilldown";
export const FileUploader = ({
  handleFile,
}: {
  handleFile: (file: File) => void;
}) => {
  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target?.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <div
        className="flex justify-center items-center p-2 bg-black/40 rounded-full cursor-pointer h-10 w-10"
        onClick={handleClick}
      >
        <UploadIcon />
      </div>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};

export const FileTypeIcon = ({
  fileName,
  size,
}: {
  fileName: string;
  size: IconSize;
}) => {
  let fileType = fileName.split(".").pop();
  const smallFileSize =
    "!min-w-[10px] !min-h-[10px] !max-w-[18px] !max-h-[18px]";

  return fileType === "pdf" ? (
    <PdfFileIcon
      className={` ${size === IconSize.small ? smallFileSize : ""}`}
    />
  ) : fileType === "xsl" || fileType === "xlsx" ? (
    <XslFileIcon
      className={` ${size === IconSize.small ? smallFileSize : ""}`}
    />
  ) : (
    <FileIcon className={` ${size === IconSize.small ? smallFileSize : ""}`} />
  );
};
export const FilePreview = ({
  fileToUpload,
  deleteFile,
}: {
  fileToUpload: File;
  deleteFile?: () => void;
}) => (
  <div className="flex items-center gap-2 p-1 px-2 m-0 max-w-[190px] h-[25px] rounded-sm bg-black/30 text-white">
    <FileTypeIcon fileName={fileToUpload?.name} size={IconSize.small} />
    <div className="flex shrink grow-0 overflow-hidden w-2/3 h-[20px]">
      <span className=" overflow-hidden whitespace-nowrap text-ellipsis">
        {fileToUpload?.name}
      </span>
    </div>
    {deleteFile && (
      <div
        className="flex cursor-pointer justify-center items-center rounded-full bg-black h-4 w-4 mx-1 text-white"
        onClick={deleteFile}
      >
        x
      </div>
    )}
  </div>
);
