import { ReactElement } from "react";
import { SmallText } from "../styles/Typography";

type HintProps = {
  className?: string;
  title?: string;
  description?: string;
  icon?: ReactElement;
};

export const Hint = ({ className, title, description, icon }: HintProps) => (
  <div
    className={`flex gap-2 bg-primary-very-light rounded-lg p-2 mb-4 ${
      className ?? ""
    }`}
  >
    {icon ?? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4 text-primary mt-1"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
    )}
    <div className="flex flex-col gap-1">
      <div className="text-primary font-semibold">{title ?? "Tip"}</div>
      <SmallText className="!m-0 text-secondary-gray-500">
        {description}
      </SmallText>
    </div>
  </div>
);
