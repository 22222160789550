import { Checkbox } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { SmallText } from "../styles/Typography";
import { DatePicker } from "./DatePicker";
import {
  capitalizeAll,
  getDayAfter,
  getDayBefore,
  getYearAgoDate,
  getYesterdayDate,
} from "../utils/helpers";

type RangePickerProps = {
  setLiveTracking: (value: boolean) => void;
  fromDateSetter: (date: Date) => void;
  toDateSetter: (date: Date) => void;
};

export const RangePicker = ({
  setLiveTracking,
  fromDateSetter,
  toDateSetter,
}: RangePickerProps) => {
  const [track, setTrack] = useState(false);
  const [fromDate, setFromDate] = useState(getYearAgoDate());
  const [toDate, setToDate] = useState(getYesterdayDate());

  useEffect(() => {
    const d = getDayAfter(fromDate);
    const newToDate = toDate > fromDate ? toDate : d;
    setToDate(newToDate);
    toDateSetter(newToDate);
  }, [fromDate]);

  useEffect(() => {
    const d = getDayBefore(toDate);
    const newFromDate = fromDate < toDate ? fromDate : d;
    setFromDate(newFromDate);
    fromDateSetter(newFromDate);
  }, [toDate]);

  return (
    <div className="flex flex-col gap-3">
      <Checkbox
        defaultChecked={track}
        onChange={(e) => {
          setTrack(e.currentTarget.checked);
          setLiveTracking(e.currentTarget.checked);
        }}
        label={
          <SmallText className="mb-1 font-normal">
            {capitalizeAll("Continuous tracking")}
          </SmallText>
        }
        crossOrigin={undefined}
        color="blue"
      />
      <DatePicker
        label="From"
        onChange={() => {}}
        onDateSelect={(date: Date) => {
          setFromDate(date);
          fromDateSetter(date);
        }}
        value={fromDate}
        disabledDays={[{ after: new Date(getDayBefore(getYesterdayDate())) }]}
        className="!max-w-full !w-[335px]"
      />
      <div className={track ? "opacity-30 pointer-events-none" : ""}>
        <DatePicker
          label="To"
          onChange={() => {}}
          onDateSelect={(date: Date) => {
            setToDate(date);
            toDateSetter(date);
          }}
          value={toDate}
          disabledDays={
            fromDate
              ? [{ after: getYesterdayDate(), before: getDayAfter(fromDate) }]
              : []
          }
          className="!max-w-full !w-[335px]"
        />
      </div>
    </div>
  );
};
