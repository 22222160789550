import { FC } from "react";
import { AttachEmailIconPink } from "../../../../../components/Icons";
import { Typography } from "@material-tailwind/react";
import { Hint } from "../../../../../components/Hint";
import { SmallText } from "../../../../../styles/Typography";

const ShareStep: FC = () => {
  return (
    <div className="h-full gap-10 flex flex-col justify-center items-center m-16 mb-32">
      <div className="flex justify-center items-center gap-10">
        <div className="flex bg-pink-light rounded-full p-10 w-fit">
          <AttachEmailIconPink />
        </div>
        <div className="flex flex-col gap-2">
          <Typography variant="h1" className=" font-normal !my-0">
            Great!
          </Typography>
          <SmallText className="text-xl max-w-sm text-secondary-gray-500 p-0 !my-0">
            Now you can share & download the generated report via Email.
          </SmallText>
        </div>
      </div>
      <Hint
        className="w-fit"
        description="You will always have access to your generated reports in the “Reports” page."
      />
    </div>
  );
};

export default ShareStep;
