import "./App.css";
import { Route, Routes } from "react-router-dom";
import Overview from "./pages/Overview";
import Reports from "./pages/Reports";
import Dashboard from "./pages/Dashboard";
import Comps from "./pages/Comps/Comps";
import React from "react";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/comps" element={<Comps />} />
    </Routes>
  );
}

export default Router;
