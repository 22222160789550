import { useState } from "react";
import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format, parse, isSameDay, isBefore, isAfter } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { getMonthAgoDate, getNextMonthDate } from "../utils/helpers";
import { DateInput } from "rsuite";

type DatePickerProps = {
  onSelect: SelectSingleEventHandler;
  disabledDays?: any;
};

const DatePickerWithInput = ({ onSelect, disabledDays }: DatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [month, setMonth] = useState(new Date());

  const isDateDisabled = (d: any) => {
    return disabledDays.some((disabledDay: any) => {
      return (
        (disabledDay.after && isAfter(d, disabledDay.after)) ||
        (disabledDay.before && isBefore(d, disabledDay.before)) ||
        isSameDay(disabledDay, d)
      );
    });
  };

  const handleInputChange = (value: Date | null) => {
    if (!value || isNaN(value)) return;

    if (isDateDisabled(value)) {
      setError("The selected date is disabled.");
      return;
    }

    handleDayClick(value);
    setError("");
    setSelectedDate(value);
    setMonth(value); // Switch the calendar view to the input date
    onSelect(value);
  };

  // Handle date selection from DayPicker
  const handleDayClick = (day) => {
    if (isDateDisabled(day)) {
      setError("The selected date is disabled.");
      return;
    }
    setSelectedDate(day);
    setInputValue(format(day, "MM/dd/yyyy")); // Update input value when a date is picked
    setMonth(day); // Update the calendar view to the selected date
  };

  return (
    <div className="p-3 flex flex-col justify-center items-center">
      <DateInput
        format="MM/dd/yyyy"
        type="text"
        value={parse(inputValue, "MM/dd/yyyy", new Date())}
        onChange={handleInputChange}
        placeholder="mm/dd/yyyy"
        className="border-2 border-gray-300 rounded-md p-2 m-auto w-24 focus:outline-none focus:border-blue-500"
      />
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      <div>
        <DayPicker
          mode="single"
          selected={selectedDate || undefined}
          onDayClick={handleDayClick}
          onSelect={onSelect}
          month={month} // Control the month displayed in the calendar
          showOutsideDays
          disabled={disabledDays || []}
          className="border-0 h-[320px] overflow-y-auto"
          classNames={{
            caption: "flex justify-center py-2 mb-4 relative items-center",
            caption_label: "text-sm font-medium text-gray-900",
            nav: "flex items-center",
            nav_button:
              "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
            nav_button_previous: "absolute left-1.5",
            nav_button_next: "absolute right-1.5",
            table: "w-full border-collapse",
            head_row: "flex font-medium text-gray-900",
            head_cell: "m-0.5 w-9 font-normal text-sm",
            row: "flex w-full mt-2",
            cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
            day: "h-9 w-9 p-0 font-normal",
            day_range_end: "day-range-end",
            day_selected:
              "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
            day_today: "rounded-md bg-gray-200 text-gray-900",
            day_outside:
              "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
            day_disabled: "text-gray-500 opacity-50",
            day_hidden: "invisible",
          }}
          components={{
            IconLeft: ({ ...props }) => (
              <ChevronLeftIcon
                {...props}
                className="h-4 w-4 stroke-2"
                onClick={() => setMonth((d) => getMonthAgoDate(d))}
              />
            ),
            IconRight: ({ ...props }) => (
              <ChevronRightIcon
                {...props}
                className="h-4 w-4 stroke-2"
                onClick={() => setMonth((d) => getNextMonthDate(d))}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default DatePickerWithInput;
