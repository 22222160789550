import { AppNav } from "@components/AppNav";
import { ContainerGrid } from "@components/ContentGrid";
import Router from "../Router";
import { Lori } from "@components/Lori";
import { getCompsProperties } from "../api/api";
import { useEffect, useState } from "react";
import AppLoader from "@components/AppLoader";

function Home() {
  const [shouldDisplayComps, setshouldDisplayComps] = useState<boolean>();

  useEffect(() => {
    const getData = async () => {
      try {
        const cp = (await getCompsProperties())?.length;
        setshouldDisplayComps(cp);
      } catch (e) {
        console.error("Error fetching comps properties: ", e);
        setshouldDisplayComps(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      {shouldDisplayComps === undefined ? (
        <AppLoader />
      ) : (
        <div className="flex h-screen w-full overflow-hidden font-custom">
          <div className="flex flex-col flex-1 w-full h-full overflow-hidden">
            <AppNav shouldDisplayComps={shouldDisplayComps} />
            <ContainerGrid>
              <Router />
              <Lori />
            </ContainerGrid>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
