import { createSlice } from '@reduxjs/toolkit';
import { InsightDTO } from '../../models/models';

interface NotificationsState {
    insights: InsightDTO[],
    isInitialState: boolean,
}

const initialState: NotificationsState = {
    insights: [],
    isInitialState: false
}

const NotificationsSlice = createSlice({name: 'notifications', initialState, reducers: {
    setInitialState(state, {payload: isInitialState}) {
        state.isInitialState = isInitialState;
        return state;
    },
    setInsights(state, {payload: insights}) {
        state.insights = insights.data || [];
        state.isInitialState = insights.isInitialState;
        if(insights?.data?.length){
            state.isInitialState = false;
        }
        return state;
    },
    closeInsight(state, {payload: insightId}) {
        state.insights = state.insights.filter((n) => n.id !== insightId);
        return state;
    },
}})

export const {setInsights, closeInsight} = NotificationsSlice.actions;
export default NotificationsSlice.reducer;