import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { LoriResponseDTO, MessageDTO, ROLE } from "../models/models";
import { askLori, uploadWithLori } from "../api/api";
import { ChatLogoSymbolIcon, SendIcon, ToggleChatIcon } from "./Icons";
import StarsBg from "../assets/Stars.png";
import Loader from "../assets/loader_white.gif";
import { ChatButton } from "./ ChatButton";
import Typed from "typed.js";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ChatMessage } from "./ChatMessage";
import {
  addMessage,
  closeChat,
  openChat,
  setConversationID,
} from "../features/messages/messages-slice";
import { FilePreview, FileUploader } from "./FileUploader";

export const Lori = forwardRef((_props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const messagesRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [messagePending, setMessagePending] = useState(true);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const typingRef = useRef(null);
  const [typed, setTyped] = useState<Typed | null>(null);

  const isOpenChat = useAppSelector((state) => state.messages.isChatOpen);
  const conversationID = useAppSelector(
    (state) => state.messages.conversationID
  );
  const messages = useAppSelector((state) => state.messages.messages);
  const dispatch = useAppDispatch();
  const [oldM, setOldM] = useState(messages);

  useEffect(() => {
    if (isOpenChat && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpenChat]);

  useEffect(() => {
    setOldM(messages);
    const element = messagesRef.current;
    if (element) {
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 0);
    }
  }, [messages]);

  const sendLorisResponse = (response: LoriResponseDTO) => {
    if (!conversationID) {
      dispatch(setConversationID(response.conversationID));
    }
    setLoadingResponse(false);
    const messagesToSend: MessageDTO[] = response?.messages?.map((m) => ({
      role: ROLE.ASSISTANT,
      content: m,
      time: Date.now().toLocaleString(),
    }));
    messagesToSend?.length && sendNewMessages(messagesToSend);
    inputRef?.current?.focus();
  };

  const sendNewMessages = (messagesToSend: MessageDTO[]) => {
    messagesToSend.forEach(async (m) => {
      const role = m.role;
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let hour = date.getUTCHours();
      let minutes = date.getUTCMinutes();

      typingRef.current?.stop();
      setOldM((prev) => [
        ...prev,
        {
          role: role,
          content: m.content,
          time: `${day}/${month} ${hour}:${minutes}`,
          typeMessage: true,
          isFile: m.isFile,
        },
      ]);
      dispatch(
        addMessage({
          role: role,
          content: m.content,
          time: `${day}/${month} ${hour}:${minutes}`,
          typeMessage: true,
          isFile: m.isFile,
        })
      );
      setInputValue("");
      inputRef.current?.blur();
      const element = messagesRef.current;
      if (element) {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 0);
      }
    });
  };

  const sendRequest = async (message: string) => {
    if (!message.replace(/\s/g, "").length && !fileToUpload) {
      //prevent empty message
      return;
    }
    if (fileToUpload) {
      sendNewMessages([
        {
          role: ROLE.USER,
          content: fileToUpload,
          time: Date.now(),
          typeMessage: false,
          isFile: true,
        } as unknown as MessageDTO,
      ]);
      setFileToUpload(null);
      const response = await uploadWithLori(conversationID, fileToUpload);
      sendLorisResponse(response);
      return;
    }
    if (!message?.length) return;
    dispatch(openChat());
    sendNewMessages([
      {
        role: ROLE.USER,
        content: message,
        time: Date.now(),
        typeMessage: true,
      } as unknown as MessageDTO,
    ]);
    setLoadingResponse(true);
    const response = await askLori(conversationID, message);
    sendLorisResponse(response);
  };

  useImperativeHandle(ref, () => ({
    askLori: sendRequest,
  }));

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        inputValue.length && sendRequest(inputValue);
      }
    },
    [inputValue]
  );

  return (
    <div className={`relative bg-transparent h-full flex items-center gap-2`}>
      <div
        className={` w-[40px] h-[40px] z-20 transition-all absolute duration-1000 ${
          isOpenChat ? "right-[362px] rotate-180" : " right-[14px]"
        }`}
      >
        <div
          className={` w-[40px] h-[40px] z-20 transition-all absolute rounded-full bg-[#69a3f3] motion-safe:animate-[ping_5s_infinite] opacity-75 blur-sm`}
        />
        <div
          className={`cursor-pointer z-20 transition-all duration-300 absolute rounded-full bg-[#212121] border border-[#69a3f3]`}
          onClick={() => dispatch(isOpenChat ? closeChat() : openChat())}
        >
          <ChatButton showNotification={messagePending} isOpen={isOpenChat} />
          <ToggleChatIcon />
        </div>
      </div>
      <div
        className={`relative h-full transition-all z-10 duration-1000 ${
          isOpenChat ? "w-96" : "w-8"
        }`}
      >
        <div className="absolute overflow-hidden left-0 w-8 h-full bg-gradient-to-b from-[#69a3f3] via-[#69a3f3] to-[#69a3f3]">
          <div className="w-full h-full bg-gradient-to-b from-transparent via-[#dbccf4] to-transparent -translate-y-full animate-[shimmer_5s_infinite]"></div>
        </div>
        <div className="bg-auto absolute left-[6px] w-full h-full bg-gradient-to-b from-[#212121] to-[#373737]">
          <div className="w-full h-full absolute opacity-20"></div>
          <div
            className="w-full h-full absolute opacity-60"
            style={{
              backgroundImage: `url(${StarsBg})`,
            }}
          ></div>
          <div
            className={`flex overflow-hidden flex-col relative shadow-lg shadow-blue-gray-500/30 !border-blue-gray-500 mr-[-15px] h-full w-full transition-opacity duration-1000 delay-500 ${
              isOpenChat ? "opacity-1" : "opacity-0"
            }`}
          >
            <div
              className={`bg-gradient-to-b from-transparent via-[#69a3f3] to-[#5ad1cd] blur-3xl w-24 h-[200%] absolute right-[420px] -translate-y-full ${
                messagePending
                  ? "animate-[shimmer_3s_infinite]"
                  : "motion-safe:animate-[shimmer_5s_infinite]"
              }`}
            ></div>

            {loadingResponse && (
              <div className="-z-0 bg-gradient-to-t from-white/40 via-[#58a9ff]/20 to-transparent blur-2xl w-[200%] h-[300px] absolute bottom-[-330px] -translate-y-full motion-safe:animate-[pulse_2s_infinite]"></div>
            )}

            <div
              className={`flex flex-col h-full justify-between ${
                isOpenChat ? "" : "opacity-0"
              }`}
            >
              <div className="flex self-center gap-2 items-center p-2 w-11/12 h-[76px] shadow-md  border-b border-white">
                <ChatLogoSymbolIcon />
                <div className="flex flex-col items-start">
                  <div className="text-white font-normal text-[16px] pt-2 my-0">
                    Lori
                  </div>
                  <div className="text-white font-extralight text-[12px] py-0 my-0">
                    I'm here for any request and insights...
                  </div>
                </div>
              </div>
              <div
                ref={messagesRef}
                className="flex flex-col m-top-auto flex-grow overflow-auto p-5 z-20"
              >
                {oldM?.map((message, index) => (
                  <ChatMessage
                    key={index}
                    role={message.role}
                    index={index}
                    content={message.content}
                    isFile={message.isFile}
                    time={message.time}
                    setTyped={setTyped}
                    typingRef={typingRef}
                    isLast={index === oldM.length - 1}
                  />
                ))}
                {loadingResponse ? (
                  <div>
                    <div className="flex gap-3 text-white/80 items-center">
                      <div
                        className={`flex justify-center items-center rounded-full w-10 h-10 bg-black p-2 text-white`}
                      >
                        <div
                          className="w-full flex justify-center p-3g"
                          key={"loading"}
                        >
                          <ChatLogoSymbolIcon />
                        </div>
                      </div>
                      <div>Lori</div>
                    </div>
                    <div
                      className={`px-2 !py-0 flex flex-col bg-black/80
          text-white w-fit m-2 mb-6 rounded-xl animate-fadeIn
          `}
                    >
                      <div className="self-start text-left  font-extralight text-sm px-2">
                        <img src={Loader} className="!my-3 w-5" />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex gap-2 items-center justify-between p-4 border-t w-11/12 self-center h-[66px] rounded-b-md shadow-md text-white">
                <div className="flex items-center gap-3 w-full">
                  <FileUploader
                    handleFile={(file) => {
                      setFileToUpload(file);
                    }}
                  />
                  <div className="flex w-full h-full rounded-full p-3 px-6 text-white bg-black/30">
                    {fileToUpload ? (
                      <div className=" w-full h-full outline-none !border-0 focus:border-transparent bg-transparent text-sm">
                        <FilePreview
                          fileToUpload={fileToUpload}
                          deleteFile={() => setFileToUpload(null)}
                        ></FilePreview>
                      </div>
                    ) : (
                      <input
                        ref={inputRef}
                        type="text"
                        placeholder="Ask or search anything..."
                        className=" w-full h-full outline-none !border-0 focus:border-transparent bg-transparent text-sm"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                        value={inputValue}
                      />
                    )}
                  </div>
                </div>
                <SendIcon
                  className={`text-white ${
                    !inputValue?.length && !fileToUpload
                      ? "pointer-events-none opacity-60"
                      : "cursor-pointer"
                  }`}
                  onClick={() => sendRequest(inputValue)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
