import { createSlice } from '@reduxjs/toolkit';
import { TrendGraphDTO } from '../../models/models';

interface TrendsState {
    trends: TrendGraphDTO[],
    status: string
    trendsOnProccesing: number
}

const initialState: TrendsState = {
    trends: [],
    status: '',
    trendsOnProccesing: 0
}

const TrendsSlice = createSlice({name: 'trends', initialState, reducers: {
    addTrendOnProccesing(state){
        return {...state, trendsOnProccesing: state.trendsOnProccesing+1}
    },
    removeTrendOnProccesing(state){
        return {...state, trendsOnProccesing:  state.trendsOnProccesing ? state.trendsOnProccesing-1:0}
    },
    setTrends(state, {payload: trends}) {
        return {...state, trends};
    },
    addTrend(state, {payload: trend}) {
        return {...state, trends:[trend, ...state.trends], trendsOnProccesing:  state.trendsOnProccesing ? state.trendsOnProccesing-1:0};
    },
    deleteTrend(state, {payload: trendId}) {
        let newTrends: TrendGraphDTO[] = [];
        state.trends.forEach(element => {
            if(element.id !== trendId) newTrends.push(element)
        });
        state.trends = newTrends;
    },
    updateTrend(state, {payload: trend}) {
        state.trends = [...state.trends].map(t => t.id === trend.id ? trend : ({...t}));
    },
}});

export const {setTrends, addTrend, deleteTrend, updateTrend, addTrendOnProccesing, removeTrendOnProccesing} = TrendsSlice.actions;
export default TrendsSlice.reducer;