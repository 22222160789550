import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { OutlineButton } from "@components/Buttons";
import {
  REPORT_TYPE,
  REPORT_TYPE_VALUE,
  ReportGroups,
  ReportLink,
  SavedReportsDTO,
} from "../models/models";
import GenerateReport from "../views/Reports/GenerateReport/GenerateReport";
import { getSavedReports } from "../api/api";
import {
  ExecutiveSummaryIcon,
  FileIcon,
  PdfFileIcon,
  XslFileIcon,
} from "@components/Icons";
import { LargeText, SmallText } from "../styles/Typography";

function Reports() {
  const [selectedReports, setSelectedReports] = useState<
    ReportGroups | undefined
  >(undefined);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [savedReports, setSavedReports] = useState<SavedReportsDTO>();

  useEffect(() => {
    const getReports = async () => {
      setSavedReports(await getSavedReports());
    };
    const reportsMap: ReportGroups = { INTERNAL: true, LP: true };
    setSelectedReports(reportsMap);
    getReports();
  }, []);

  function openFileInNewTab(url: string) {
    window.open(url, "_blank");
  }

  const ReportsSection = ({
    type,
    reports,
  }: {
    type: REPORT_TYPE;
    reports: ReportLink[];
  }) =>
    selectedReports &&
    selectedReports[type.toLocaleUpperCase() as REPORT_TYPE] ? (
      <div>
        <SmallText className="font-semibold">{type}</SmallText>
        <div className="flex flex-col w-80 gap-3">
          {reports.map((r, index) => (
            <div className="relative mb-2">
              <div
                className="relative flex gap-4 cursor-pointer text-secondary-gray-400 border border-secondary-gray-400 font-light text-xs rounded-md p-6 no-underline"
                onClick={() => openFileInNewTab(r?.url)}
                key={`${r.title}_${index}`}
              >
                {r.fileType === "pdf" ? (
                  <PdfFileIcon />
                ) : r.fileType === "xsl" || r.fileType === "xlsx" ? (
                  <XslFileIcon />
                ) : (
                  <FileIcon />
                )}
                <div className="flex flex-col gap-1">
                  <LargeText>{r.title}</LargeText>
                  <span>{r?.date?.toDateString()}</span>
                </div>
                {r?.executiveSummary && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      r?.executiveSummary?.length &&
                        openFileInNewTab(r.executiveSummary);
                    }}
                    className="absolute top-3 right-3 bg-secondary-gray-350 hover:bg-secondary-gray-400 rounded-full h-8 w-8 flex justify-center items-center text-white"
                  >
                    <ExecutiveSummaryIcon />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      ""
    );

  return selectedReports ? (
    <>
      <div className="relative flex flex-col w-full bg-white p-6">
        <Typography variant="h5">Reports:</Typography>
        <OutlineButton
          className="absolute top-6 right-6 pointer-events-none opacity-50"
          onClick={() => setIsPopupOpen(true)}
        >
          + Generate
        </OutlineButton>
        <div className="absolute top-[58px] right-[35px] text-[10px] bg-black text-white px-3 rounded-full">
          Coming soon!
        </div>
        {savedReports ? (
          <div className="grid gap-12 overflow-auto pr-3 py-5">
            {/* <div className="flex gap-3">
              {Object.keys(savedReports).map((type: string) => (
                <Capsule
                  key={type}
                  title={type.toUpperCase()}
                  isSelected={selectedReports[type as REPORT_TYPE_VALUE]}
                  count={savedReports[type as REPORT_TYPE_VALUE].length}
                  onSelect={() => {
                    setSelectedReports({
                      ...selectedReports,
                      [type]:
                        !selectedReports[
                          type.toUpperCase() as REPORT_TYPE_VALUE
                        ],
                    });
                  }}
                />
              ))}
            </div> */}
            {Object.keys(savedReports).map((type: string) =>
              savedReports[type as REPORT_TYPE_VALUE].length ? (
                <ReportsSection
                  key={type}
                  type={type as REPORT_TYPE}
                  reports={savedReports[type as REPORT_TYPE_VALUE]}
                />
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {isPopupOpen ? (
        <GenerateReport
          closePopup={() => {
            setIsPopupOpen(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  ) : (
    <></>
  );
}

export default Reports;
