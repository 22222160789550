export const ChatButton = ({
  className,
  showNotification,
}: {
  className?: string;
  showNotification?: boolean;
  isOpen?: boolean;
}) => {
  return (
    <>
      {showNotification && (
        <div
          className={`absolute -z-10 w-[46px] h-[46px] rounded-full
    bg-gradient-to-r from-[#f07e6e] via-[#69a3f3] to-[#5ad1cd] 
    animate-[load_1.2s_linear_infinite] after:content-['<ToggleChatIcon />'] after:absolute after:top-[1px] after:left-[1px] after:right-[1px] after:bottom-[1px] after:bg-black after:border-1 after:border-blue-200 after:rounded-full ${
      className ? className : ""
    }`}
        >
          <span
            className="absolute
    w-full
    h-full
    rounded-full
    bg-gradient-to-r from-[#f07e6e] via-[#69a3f3] to-[#5ad1cd]
     blur-sm"
          ></span>
          <span
            className="absolute
    w-full
    h-full
    rounded-full
    bg-gradient-to-r from-[#f07e6e] via-[#69a3f3] to-[#5ad1cd] blur-md"
          ></span>
          <span
            className="absolute
    w-full
    h-full
    rounded-full
    bg-gradient-to-r from-[#f07e6e]/20 via-[#69a3f3]/20 to-[#5ad1cd]/20 blur-lg"
          ></span>
          <span
            className="absolute
    w-full
    h-full
    rounded-full
    bg-gradient-to-r from-[#f07e6e]/10 via-[#69a3f3]/10 to-[#5ad1cd]/10 blur-xl"
          ></span>
        </div>
      )}
    </>
  );
};
