import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type RenderPDFProps = {
  reportUrl: string;
  onLoadSuccess?: () => void;
};

export const RenderPDF = ({ reportUrl, onLoadSuccess }: RenderPDFProps) => {
  const PAGE_NUMBER = 1;
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    onLoadSuccess && onLoadSuccess();
  }

  return (
    <>
      <Document file={reportUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={PAGE_NUMBER} width={340} height={700} />
      </Document>
      <p>
        Page {PAGE_NUMBER} of {numPages}
      </p>
    </>
  );
};
