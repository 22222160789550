import { useEffect, useState } from "react";
import {
  getAvgRentByBedroomType,
  getHistoricalTrendLine,
  getPortfolioHistoricalTrendLine,
  getRentDistribution,
} from "../../api/api";
import { Graph } from "@components/Graph";
import { TrendGraphDTO } from "../../models/models";
import { Frame } from "@components/Frame";
import { Title } from "../../styles/Typography";
import { ComingSoonGraph } from "@components/Icons";
import { LoriLoader } from "@components/LoriLoader";
// import { getDemoGraph } from "../../mock-data/mock-graphs";

export const RightDashboard = ({
  address,
  bedrooms,
  isLoading,
}: {
  address: string;
  bedrooms: number | null;
  isLoading?: boolean;
}) => {
  const [historicalTrendLine, setHistoricalTrendLine] =
    useState<TrendGraphDTO | null>();
  const [loadingData, setLoadingData] = useState(isLoading);
  const [portfolioHistoricalTrendLine, setPortfolioHistoricalTrendLine] =
    useState<TrendGraphDTO | null>();
  const [rentDistribution, setRentDistribution] = useState<any>(null);
  const [avgRentByBedroom, setAvgRentByBedroomn] = useState<any>(null);
  const [error, setError] = useState("");
  // const [portfolioHistoricalTrendLine, setPortfolioHistoricalTrendLine] =
  //   useState<TrendGraphDTO | null>();

  useEffect(() => {
    setError("");
    setLoadingData(true);
    const getGraph1 = async () => {
      // setHistoricalTrendLine({
      //   ...(await getHistoricalTrendLine({ address, bedrooms })),
      //   title: "Average rent by bedroom type",
      // });
      // setPortfolioHistoricalTrendLine(
      //   await getPortfolioHistoricalTrendLine({ address, bedrooms })
      // );
      try {
        setRentDistribution(
          // getDemoGraph("Track my yearly NOI performence.")
          await getRentDistribution({ address, bedrooms })
        );
        setLoadingData(false);
      } catch (e) {
        setError(
          "An error occurred while creating the graph. If this error persists, please contact support."
        );
        setLoadingData(false);
      }
    };

    const getGraph2 = async () => {
      setError("");
      try {
        setAvgRentByBedroomn(
          // getDemoGraph("Track my yearly NOI performence.")
          await getAvgRentByBedroomType({ address, bedrooms })
        );
        setLoadingData(false);
      } catch (e) {
        setError(
          "An error occurred while creating the graph. If this error persists, please contact support."
        );
        setLoadingData(false);
      }
    };

    if (address && bedrooms) {
      getGraph1();
      getGraph2();
    }
  }, [address, bedrooms]);

  return (
    <div className="w-full h-full overflow-y-auto pb-64 p-8 overflow-x-hidden gap-y-2 gap-x-6 flex flex-wrap justify-center">
      {loadingData ? (
        <LoriLoader />
      ) : error?.length ? (
        <div className="flex justify-center items-center font-extralight text-red-400 bg-red-50 p-6 w-11/12 h-fit rounded-md animate-fadeIn max-w-xl mx-auto left-0 right-0">
          {error}
        </div>
      ) : (
        <>
          {rentDistribution && (
            <Frame
              className={`overflow-hidden resize w-[30vw] h-[20vw] min-h-[300px] min-w-[478px] max-w-[60vw] max-h-[60vh] pr-0 pb-0 mb-0`}
            >
              <Title>{rentDistribution.options?.title?.text || ""}</Title>
              <Graph dataPoints={rentDistribution} hideTitle={true} />
            </Frame>
          )}
          {avgRentByBedroom && (
            <Frame
              className={`overflow-hidden resize w-[30vw] h-[20vw] min-h-[300px] min-w-[478px] max-w-[60vw] max-h-[60vh] pr-0 pb-0 mb-0`}
            >
              <Title>{avgRentByBedroom.options?.title?.text || ""}</Title>
              <Graph dataPoints={avgRentByBedroom} hideTitle={true} />
            </Frame>
          )}
          <Frame
            className={`overflow-hidden resize w-[30vw] h-[20vw] min-h-[300px] min-w-[478px] max-w-[60vw] max-h-[60vh] pr-0 pb-0 mb-0`}
          >
            <div className="absolute top-[20px] right-[20px] text-[10px] bg-black text-white p-3 rounded-full">
              Coming soon!
            </div>
            <Title>Historical trend line</Title>
            <ComingSoonGraph />
          </Frame>
          <Frame
            className={`overflow-hidden resize w-[30vw] h-[20vw] min-h-[300px] min-w-[478px] max-w-[60vw] max-h-[60vh] pr-0 pb-0 mb-0`}
          >
            <div className="absolute top-[20px] right-[20px] text-[10px] bg-black text-white p-3 rounded-full">
              Coming soon!
            </div>
            <Title>Portfolio historical trend line</Title>
            <ComingSoonGraph />
          </Frame>
        </>
      )}
    </div>
  );
};
