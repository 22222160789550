import { List, ListItem, ListItemSuffix, Chip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const MENU_ITEMS = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
        />
      </svg>
    ),
    title: "Overview",
    notifications: 0,
    path: "",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
        />
      </svg>
    ),
    title: "Reports",
    notifications: 0,
    path: "reports",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
        />
      </svg>
    ),
    title: "My Dashboards",
    notifications: 0,
    path: "dashboard",
  },
];

const COMPS = {
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
      />
    </svg>
  ),
  title: "Comps",
  notifications: 0,
  path: "comps",
};

export function MainMenu({
  shouldDisplayComps,
}: {
  shouldDisplayComps: boolean;
}) {
  const [isSelected, setIsSelected] = useState(0);
  const [menuItems, setMenuItems] = useState<any>(
    shouldDisplayComps ? [...MENU_ITEMS, COMPS] : MENU_ITEMS
  );

  useEffect(() => {
    setMenuItems(shouldDisplayComps ? [...MENU_ITEMS, COMPS] : MENU_ITEMS);
  }, [shouldDisplayComps]);

  return (
    <div className="flex items-center">
      <List className="contents w-full">
        {menuItems.map((item, index) => (
          <NavLink
            to={`/${item.path}`}
            key={item.path}
            className={({ isActive }) =>
              `${
                isActive
                  ? "text-primary bg-primary-very-light"
                  : "text-secondary-gray-500"
              } w-full ml-2 text-sm rounded-full`
            }
          >
            <ListItem
              className={`whitespace-nowrap text-inherit font-custom rounded-full px-4 py-2 hover:bg-primary-very-light/50 focus:bg-primary-very-light focus:text-primary`}
              key={index}
              onClick={() => setIsSelected(index)}
            >
              {item.title}
              {item.notifications ? (
                <ListItemSuffix>
                  <Chip
                    value={item.notifications}
                    size="sm"
                    variant="ghost"
                    color="blue-gray"
                    className="rounded-full text-white bg-primary-soft ml-2 h-5 w-5 flex justify-center items-center"
                  />
                </ListItemSuffix>
              ) : (
                ""
              )}
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );
}
