import { PropsWithChildren } from "react";

type BubbleParams = {
  className?: string;
};

export const Bubble = ({
  children,
  className,
}: PropsWithChildren<BubbleParams>) => (
  <div
    className={`flex justify-center items-center rounded-full w-4 h-4 ${
      className ?? ""
    }`}
  >
    {children}
  </div>
);
