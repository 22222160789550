import { ReactNode, useState } from "react";
import { HalfScreenPopup } from "./HalfScreenPopup";
import {
  CloseIcon,
  GreenArrow,
  RedArrow,
  Star,
  ArrowLeft,
  ArrowRight,
} from "./Icons";
import { Typography } from "@material-tailwind/react";
import { LargeText } from "../styles/Typography";
type BulletProps = {
  title: string;
  message?: string | ReactNode;
};

const Bullet = ({ title, message }: BulletProps) => {
  return (
    <div className="flex gap-2 bg-secondary-gray-50 border-left-primary-dark border-l-2 p-4 rounded-md">
      <div className="p-1">
        <Star />
      </div>
      <div>
        <div className="text-primary-dark">{title}</div>
        <div className="text-secondary-gray-500">{message}</div>
      </div>
    </div>
  );
};

type TreeProps = {};

const Tree = ({}: TreeProps) => {
  return (
    <div className="flex gap-10 justify-center">
      <TreeBranch
        positive={true}
        title="Vacant"
        steps={[
          `If we decide to start an eviction process, our expected fees are $1100, $1600, $2000, it should take 16 days to replace the tenant, and our NOI will be affected by 4%. If we raise the rent roll for the new tenants, it should affect our NOI by 2%.`,
        ]}
      />
      <TreeBranch
        positive={false}
        title="Not Vacant"
        steps={[
          `If we decide to lower the rent roll and avoid eviction, it should affect our NOI by 7% and our cash flow by 5.4%.`,
        ]}
      />
    </div>
  );
};

type TreeBranchProps = {
  positive: boolean;
  title: string;
  steps: string[];
};

const TreeBranch = ({ positive, title, steps }: TreeBranchProps) => {
  return (
    <div className="flex flex-col gap-3 grow-1 w-full items-center">
      <div
        className={`text-secondary-gray-600 rounded-md p-3 ${
          positive ? "bg-notification-green" : "bg-notification-red"
        }`}
      >
        {title}
      </div>
      {steps.map((step) => (
        <div key={step} className="flex flex-col gap-3 items-center">
          {positive ? <GreenArrow /> : <RedArrow />}
          <div className="bg-secondary-gray-50 py-3 px-4 rounded-md">
            {step}
          </div>
        </div>
      ))}
    </div>
  );
};

type DecisionTreeProps = {
  className?: string;
  title: string;
  message?: string | ReactNode;
  closePopup: () => void;
};

type HeaderProps = {
  closePopup: () => void;
};

const Header = ({ closePopup }: HeaderProps) => (
  <div className="w-full flex justify-between pb-8">
    <div className="flex gap-2 items-center">
      <CloseIcon onClose={closePopup} />
      <LargeText>Insight's decision tree</LargeText>
    </div>
    <div className="flex gap-1">
      <ArrowLeft />
      <ArrowRight />
    </div>
  </div>
);

export function DecisionTree({
  closePopup,
  title,
  message,
}: DecisionTreeProps) {
  const [isOpenPopup, setIsOpenPopup] = useState(true);

  return (
    <HalfScreenPopup
      title={""}
      hideDefaultHeader={true}
      className={`transition-all duration-100 !p-6 ${
        isOpenPopup ? "" : "opacity-0 pointer-events-none"
      }
      `}
      closePopup={() => {
        setIsOpenPopup(false);
        setTimeout(closePopup, 200);
      }}
    >
      <Header closePopup={closePopup} />

      <Bullet title={title} message={message} />
      <div className="mt-10 pt-10 border-t border-secondary-gray-350">
        <Tree />
      </div>
    </HalfScreenPopup>
  );
}
