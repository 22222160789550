export function getConfig() {

    const domain = import.meta.env.VITE_AUTH0_DOMAIN;
    const clientId = import.meta.env.VITE_CLIENT_ID;
    const default_audience = import.meta.env.VITE_APP_BASE;

  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.//
  const audience = default_audience || null;

  return {
    domain: domain,
    clientId: clientId,
    ...(audience ? { audience } : null),
  };
}
