import { useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { LightText, Paragraph, ParagraphTitle } from "../styles/Typography";
import { closeInsight } from "../features/notifications/notifications-slice";
import { CheckmarkIcon, DecisionTreeIcon } from "./Icons";
import { OutlineIconButton } from "./Buttons";
import { DecisionTreeData, InsightDTO } from "../models/models";
import { getTimeSince } from "../utils/helpers";
import { Button } from "@material-tailwind/react";
import { Drilldown } from "./Drilldown";
import parse from "html-react-parser";
import { setInsightIsDone } from "../api/api";

type InsightProps = InsightDTO & {
  setDecisionTree: (data: DecisionTreeData) => void;
};

export function Insight({
  title,
  message,
  details,
  id,
  drilldownID,
  drilldownExportID,
  createdAt,
  setDecisionTree,
}: InsightProps) {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [toastTimer, setToastTimer] = useState<NodeJS.Timeout>();
  const [creationTime, setCreattionTime] = useState("now");
  const dispatch = useAppDispatch();
  const [insightRemoved, setInsightRemoved] = useState(false);
  const [undoRemoveInsight, setUndoRemoveInsight] = useState(false);

  useEffect(() => {
    if (!createdAt) return;
    setTimer(
      setInterval(() => setCreattionTime(getTimeSince(createdAt)), 10000)
    );
    setCreattionTime(getTimeSince(createdAt));
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (undoRemoveInsight) clearTimeout(toastTimer);
  }, [undoRemoveInsight]);

  const removeInsight = () => {
    setInsightRemoved(true);
    setToastTimer(
      setTimeout(() => {
        if (!undoRemoveInsight) {
          dispatch(closeInsight(id));
          setInsightIsDone(id);
        }
      }, 5000)
    );
  };

  const undoDone = () => {
    setUndoRemoveInsight(true);
    setInsightRemoved(false);
  };

  return (
    <>
      {insightRemoved ? (
        <div
          className={`flex gap-4 justify-center items-center bg-white p-4 w-fit rounded-lg m-auto text-secondary-gray-400 `}
        >
          Insight marked as completed
          <Button className="opacity-30 capitalize" onClick={undoDone}>
            Undo
          </Button>
        </div>
      ) : (
        <div className=" bg-gradient-to-r from-secondary-gray-400 to-transparent py-[1px] rounded-sm">
          <div
            className={`group relative bg-white  border-l-8 border-secondary-gray-350 border-l-secondary-gray-400 min-w-16 min-h-8 p-4 rounded-sm shadow-lg flex flex-col w-full gap-4`}
          >
            <div className="absolute top-[2px] left-0 ml-[-8px] h-[97%] w-2 bg-gradient-to-l from-secondary-gray-500 via-secondary-gray-300 to-secondary-gray-500 rounded-full shadow-[4.0px_2.0px_11.0px_rgba(0,0,0,0.38)]"></div>

            <div className="flex justify-between">
              <ParagraphTitle>{title || ""}</ParagraphTitle>
              <LightText className=" group-hover:hidden">
                {creationTime || ""}
              </LightText>
              <Drilldown
                id={drilldownID || "0"}
                drilldownExportID={drilldownExportID}
                className="opacity-0 group-hover:opacity-100"
                drilldownExportTitle={title.replace(" ", "_")}
              />
            </div>
            <Paragraph className="font-custom">
              {parse(message || "")}{" "}
              {details ? (
                <span className="font-custom">
                  {details.map((d) => <span>{parse(d)}</span>) || ""}
                </span>
              ) : (
                ""
              )}
            </Paragraph>

            <div className="w-full flex justify-end gap-2">
              <div className="flex gap-2 items-center">
                {/* <div
                className="flex gap-2 rounded-full text-primary w-fit py-2 h-fit justify-center items-center cursor-pointer text-sm"
                onClick={() => {
                  setDecisionTree({ title, message });
                }}
              >
                <DecisionTreeIcon />
                Decision tree
              </div> */}
                <OutlineIconButton
                  className=" hover:!bg-primary border-2 hover:text-white cursor-pointer"
                  onClick={removeInsight}
                >
                  <CheckmarkIcon />
                </OutlineIconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
