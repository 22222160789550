import { PropsWithChildren, ReactNode } from "react";
import { Popup } from "./Popup";

type HalfScreenPopupProps = {
  className?: string;
  title: string;
  description?: string | ReactNode;
  hideDefaultHeader?: boolean;
  closePopup: () => void;
};

export function HalfScreenPopup({
  className,
  title,
  description,
  children,
  closePopup,
  hideDefaultHeader,
}: PropsWithChildren<HalfScreenPopupProps>) {
  return (
    <Popup
      className={`!top-0 !right-0 !h-full !w-1/2 p-10 rounded-none ${
        className || ""
      }`}
      title={title}
      hideDefaultHeader={hideDefaultHeader}
      closePopup={closePopup}
      description={description}
    >
      {/* <Header closePopup={closePopup} /> */}
      {children}
    </Popup>
  );
}
