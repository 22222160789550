import { DrilldownType } from "@components/Drilldown";
import {
  REPORT_TYPE,
  DecisionTreeDTO,
  PropertiesDTO,
  User,
  TrendGraphDTO,
  AxisType,
  SavedReportsDTO,
  CustomTrendDTO,
  OccupancyDrilldownDTO,
  MetricFilter,
} from "../models/models";
import { drilldownText } from "../utils/drilldownParser";

export const mockDailyInsights = {
  isInitialState: false,
  data: [
    {
      id: "8",
      type: "notification",
      title: "Portfolio level",
      message:
        "Comparing to last week, your portfolio's delinquency rate went up by 5%. The highest delinquency rate is at properties 23rd Jump St., 42nd Main St., Property 21st Barber St. with an avg. of X%, and the lowest rate is at properties X,Y,Z with an avg. of X%",
      createdAt: new Date(),
      drilldownID: 1,
      drilldownExportID: 2,
    },
    {
      id: "1",
      type: "notification",
      title: "Property 21st Barber St.",
      message: "- Units 4A, 6D, 58C are vacant for 3 weeks.",
      isDone: false,
      priority: 5,
      createdAt: new Date(),
    },
    {
      id: "2",
      type: "insight",
      title: "Property 68th Stan St.",
      message:
        "- Occupancy rate is now at 96%, which represents a 2% increase month over month.",
      isDone: false,
      priority: 2,
      createdAt: new Date("02/04/2023"),
    },
    {
      id: "3",
      type: "insight",
      title: "Property 23rd Jump St.",
      message: "- Unit 8E renovation budget is at 90% utilization.",
      isDone: false,
      priority: 3,
      createdAt: new Date(),
    },
    {
      id: "4",
      type: "notification",
      title: "Property 89th Brown St.",
      message: "- Units’ 10G, 2F, 6H contracts expire in 3 weeks.",
      priority: 3,
      createdAt: new Date("2023-11-15"),
    },
    {
      id: "5",
      type: "notification",
      title: "Property 42nd Main St.",
      message: "- March collection target achieved.",
      priority: 1,
      createdAt: new Date(),
    },
    {
      id: "6",
      type: "notification",
      title: "Delinquency - Portfolio level",
      message:
        "Comparing to last week, your portfolio's delinquency rate went up by 5%. The highest delinquency rate is at properties 23rd Jump St., 42nd Main St., Property 21st Barber St. with an avg. of X%, and the lowest rate is at properties X,Y,Z with an avg. of X%",
      createdAt: new Date(),
    },
  ],
};

export const mockDecisionTree: DecisionTreeDTO = {
  positive: {
    title: "Vacant",
    steps: [
      `If we decide to start an eviction process, our expected fees are $1100, $1600, $2000, it should take 16 days to replace the tenant, and our NOI will be affected by 4%. If we raise the rent roll for the new tenants, it should affect our NOI by 2%.`,
    ],
  },
  negative: {
    title: "Not Vacant",
    steps: [
      `If we decide to lower the rent roll and avoid eviction, it should affect our NOI by 7% and our cash flow by 5.4%.`,
    ],
  },
};

export const mockSavedReports: SavedReportsDTO = {
  [REPORT_TYPE.internal]: [
    {
      title: "Weekly Report",
      url: "https://docs.google.com/spreadsheets/d/11sPDlgGNJNF4PEO8XoNQJfVlrvQdFLzuoSIL-OSzTZs/edit#gid=77694752",
      date: new Date("Jan 22, 2024"),
      fileType: "xlsx",
    },
    {
      title: "Monthly Report",
      url: "https://docs.google.com/spreadsheets/d/1hRUO08Gm6HBAXUOJA3UUKkq5YzVGUFRNq9zdG5vlIH4/edit?usp=sharing",
      date: new Date("Jan 22, 2024"),
      executiveSummary:
        "https://drive.google.com/file/d/1q9jYp3uhHohcWlcTzq0EwAYkrXGeOoUW/view?usp=drive_link",
      fileType: "xlsx",
    },
  ],
  [REPORT_TYPE.lp]: [
    // {
    //   title: "2023 Unit C22",
    //   url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf",
    //   date: new Date("Feb 22, 2023"),
    // },
    // {
    //   title: "2022 Unit C22",
    //   url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.iso",
    //   date: new Date("Feb 22, 2023"),
    // },
    // {
    //   title: "2022 Unit C20",
    //   url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf",
    //   date: new Date("Feb 22, 2023"),
    // },
    // {
    //   title: "2022 Unit C12",
    //   url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf",
    //   date: new Date("Feb 22, 2023"),
    // },
  ],
};

// export const mockNotifications = {
//   isInitialState: false,
//   data: [
//     {
//       id: "1",
//       title: "Property 21st Barber St.",
//       description: "- Units 4A, 6D, 58C are vacant for 3 weeks.",
//     },
//     {
//       id: "2",
//       title: "Property 68th Stan St.",
//       description:
//         "- Occupancy rate is now at 96%, which represents a 2% increase month over month.",
//     },
//     {
//       id: "3",
//       title: "Property 23rd Jump St.",
//       description: "- Unit 8E renovation budget is at 90% utilization.",
//     },
//     {
//       id: "4",
//       title: "Property 89th Brown St.",
//       description: "- Units’ 10G, 2F, 6H contracts expire in 3 weeks.",
//     },
//     {
//       id: "5",
//       title: "Property 42nd Main St.",
//       description: "- March collection target achieved.",
//     },
//   ],
// };

export const mockPropertiesOverall = {
  noi: {
    drilldownID: 0,
    value: 8353953.87,
    trend: -0.15912731893121151,
  },
  occupancy: {
    drilldownID: 0,
    value: 92.1656976744186,
    trend: -0.3197674418604777,
  },
  delinquency: {
    drilldownID: 0,
    actual: 548390.48,
    grossPotentialRent: 12988986,
    previousActual: 582206.33,
    previousGrossPotentialRent: 12961460,
  },
  budget: {
    drilldownID: 0,
    planned: 5953310.85,
    actual: 5881163.33,
  },
  turnovers: 60,
  expectedRenovations: {
    count: null,
    totalCost: null,
  },
};

// {
//   lastUpdatedAt: new Date("2023-12-12"),
//   noi: { value: 1311988, trend: 11.3 },
//   occupancy: { value: 91, trend: 1.6 },
//   delinquency: { actual: 700, grossPotentialRent: 1200, trend: -6.13 },
//   budget: { planned: 42000, actual: 51487 },
//   turnovers: 37,
//   expectedRenovations: { count: 26, totalCost: 96230 },
// };

export const mockProperties: PropertiesDTO = [
  {
    id: "529",
    name: "8302 Crenshaw Blvd",
    date: "2024-08-08T16:35:39.085Z",
    lastUpdatedAt: new Date("2024-08-08T15:28:53.588074Z"),
    noi: {
      drilldownID: "0",
      drilldownExportID: "0",
      value: 8922.82,
      trend: -1069.0501531310413,
    },
    occupancy: {
      drilldownID: "1088957",
      drilldownExportID: "0",
      value: 93.75,
      trend: 0,
    },
    delinquency: {
      drilldownID: "0",
      drilldownExportID: "1071220",
      actual: 5519.43,
      grossPotentialRent: -1,
      previousActual: 677.02,
      previousGrossPotentialRent: -1,
    },
    utilitiesExpenses: {
      drilldownID: "1086301",
      current: 1690.77,
      previous: 4891.49,
    },
    renewals: {
      drilldownID: "1045309",
      nonConfirmedMoveouts: 0,
      confirmedRenewals: 0,
      turnovers: 0,
    },
    collection: {
      drilldownID: "1082235",
      value: 16905.05,
      target: 22424.48,
    },
    renovations: {
      drilldownID: "0",
      drilldownExportID: "0",
      value: null,
      trend: null,
    },
    maintenanceExpenses: {
      drilldownID: "1093240",
      drilldownExportID: "0",
      value: 380,
      trend: 0,
    },
    expiredLeases: {
      drilldownID: "1078747",
      value: 1,
    },
    budget: {
      drilldownID: "1103487",
      planned: 18621.58,
      actual: 13501.66,
    },
    lossToLease: {
      drilldownID: "1097594",
      charged: 14996.48,
      marketValue: 32000,
    },
    unitsCount: 16,
  },
];

export const mockUnits: PropertiesDTO = [
  {
    id: "1",
    name: "A20",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: 0 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
  {
    id: "2",
    name: "D2",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: 0 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
  {
    id: "3",
    name: "A12",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: -3 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
  {
    id: "4",
    name: "C5",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: -3 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
  {
    id: "5",
    name: "A10",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: -3 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
  {
    id: "6",
    name: "B1",
    noi: { drilldownID: "2", value: 1309230, trend: -0.4 },
    occupancy: { drilldownID: "2", value: null, trend: 89 },
    delinquency: { drilldownID: "2", value: 7, trend: null },
    utilitiesExpenses: {
      drilldownID: "2",
      current: 49487,
      previous: 14987,
    },
    collection: {
      drilldownID: "2",
      value: 435,
      target: 587,
    },
    renewals: {
      drilldownID: "2",
      nonConfirmedMoveouts: 49487,
      confirmedRenewals: 28761,
      turnovers: 12750,
    },
    renovations: { drilldownID: "2", value: 3, trend: 16 },
    maintenanceExpenses: { drilldownID: "2", value: 232, trend: -3 },
    expiredLeases: {
      drilldownID: "2",
      value: 17,
    },
    lossToLease: { drilldownID: "6", charged: 2456, marketValue: 98 },
    budget: { drilldownID: "2", planned: 2234, actual: 2313 },
  },
];

export const mockMetrics = [
  {
    graph_name: "income",
    text: "income",
    optional_params: [MetricFilter.PROPERTIES],
  },
];

export const mockReports = [
  {
    title: "Internal",
    url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf",
  },
  {
    title: "LPs",
    url: "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf",
  },
  // {
  //   title: "Lenders Report",
  // },
  // {
  //   title: "Payments",
  // },
];

export const mockUserData: User = {
  id: "1",
  first_login: false,
  createdNotifications: false,
  preferred_username: "Yakir_Horesh",
  contactGroups: {
    [REPORT_TYPE.lp]: [
      {
        name: "Moran",
        email: "morry4@gmail.com",
      },
    ],
    [REPORT_TYPE.internal]: [
      {
        name: "Moran Horovitz",
        email: "moran@muvan.ai",
      },
      {
        name: "Moran",
        email: "morry4@gmail.com",
      },
    ],
  },
};

export const searchHistory: string[] = [
  "What are my least profitable units in properties D3, D5, C22?",
  "Show me rent lease of unit G5",
  "Properties X, Y, and Z average. monthly water consumption is $1000, while properties A, B, and C are at $1500. During the period of Oct-Dec, there was an increase of 15% for water usage across the portfolio",
];

export const MockTrends: TrendGraphDTO[] = [
  {
    id: "1",
    title: "Gas Consumption at 2023",
    series: [
      {
        name: "42nd Main St",
        data: [
          { x: new Date("2023-04-1").getTime(), y: 2 },
          { x: new Date("2023-05-02").getTime(), y: 1 },
          { x: new Date("2023-06-03").getTime(), y: 3 },
          { x: new Date("2023-08-12").getTime(), y: 7 },
          { x: new Date("2023-09-12").getTime(), y: 2.5 },
        ],
      },
      {
        name: "5th Main St",
        data: [
          { x: new Date("2023-04-1").getTime(), y: 12 },
          { x: new Date("2023-05-2").getTime(), y: 9 },
          { x: new Date("2023-06-4").getTime(), y: 7 },
          { x: new Date("2023-08-7").getTime(), y: 8 },
          { x: new Date("2023-09-12").getTime(), y: 5 },
        ],
      },
    ],
    options: {
      xaxis: {
        type: "datetime" as AxisType,
        tickPlacement: "between",
      },
      yaxis: {
        title: {
          text: "Gas Consumption",
        },
        min: 0,
        max: 12.5,
      },
    },
  },
  // {
  //   id: "2",
  //   title: "Income at 2023",
  //   series: [
  //     {
  //       name: "42nd Main St",
  //       data: [
  //         { x: new Date("2023-02-1").getTime(), y: 12 },
  //         { x: new Date("2023-05-02").getTime(), y: 10 },
  //         { x: new Date("2023-08-03").getTime(), y: 6 },
  //         { x: new Date("2023-08-12").getTime(), y: 7 },
  //         { x: new Date("2023-11-12").getTime(), y: 5.5 },
  //       ],
  //     },
  //     {
  //       name: "5th Main St",
  //       data: [
  //         { x: new Date("2023-02-1").getTime(), y: 1 },
  //         { x: new Date("2023-03-2").getTime(), y: 9 },
  //         { x: new Date("2023-06-4").getTime(), y: 12 },
  //         { x: new Date("2023-08-7").getTime(), y: 8 },
  //         { x: new Date("2023-11-12").getTime(), y: 5 },
  //       ],
  //     },
  //   ],
  //   options: {
  //     xaxis: {
  //       type: "datetime" as AxisType,
  //       tickPlacement: "between",
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Income",
  //       },
  //       min: 0,
  //       max: 12.5,
  //     },
  //   },
  // },
];

export const loriResponseMock = {
  text: `Properties 21st Barber St, 68th Stan St, and 23rd Jump St average.[1][3]
  Monthly water consumption is $1000, while properties 89th Brown St and 42nd Main St are at $1500.[4]
  During the period of Oct-Dec, there was an increase of 15% for water usage across the portfolio.[2][5][6]`,
  attachments: ["/url1", "/url2", "/url3", "/url4", "/url5", "/url6"],
  graphs: MockTrends,
};

export const NewInsightMock = "";

export const ReportLinkMock =
  "/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf";
// "https://drive.google.com/drive/folders/1l7tgvtB4wnGpwUWWsqQi3fZws0JypGfa";

// export const MockTrendJson: TrendGraphDTO = {
//   id: "2",
//   title: "Gas consumption trend",
//   series: [
//     {
//       name: "42nd Main St",
//       data: [
//         { x: new Date("2023-04-1").getTime(), y: 2 },
//         { x: new Date("2023-05-02").getTime(), y: 1 },
//         { x: new Date("2023-06-03").getTime(), y: 3 },
//         { x: new Date("2023-08-12").getTime(), y: 7 },
//         { x: new Date("2023-09-12").getTime(), y: 2.5 },
//       ],
//     },
//     {
//       name: "5th Main St",
//       data: [
//         { x: new Date("2023-04-1").getTime(), y: 12 },
//         { x: new Date("2023-05-2").getTime(), y: 9 },
//         { x: new Date("2023-06-4").getTime(), y: 7 },
//         { x: new Date("2023-08-7").getTime(), y: 8 },
//         { x: new Date("2023-09-12").getTime(), y: 5 },
//       ],
//     },
//   ],
//   options: {
//     xaxis: {
//       type: "datetime",
//     },
//     yaxis: {
//       title: {
//         text: "Gas Consumption",
//       },
//       min: 0,
//       max: 12.5,
//     },
//   },
// };

export const _MockTrendJson = {
  id: "1",
  title: "Gas Consumption at 2023",
  series: [
    {
      name: "42nd Main St",
      data: [
        { x: new Date("2023-04-1").getTime(), y: 2 },
        { x: new Date("2023-05-02").getTime(), y: 1 },
        { x: new Date("2023-06-03").getTime(), y: 3 },
        { x: new Date("2023-08-12").getTime(), y: 7 },
        { x: new Date("2023-09-12").getTime(), y: 2.5 },
      ],
    },
    {
      name: "5th Main St",
      data: [
        { x: new Date("2023-04-1").getTime(), y: 12 },
        { x: new Date("2023-05-2").getTime(), y: 9 },
        { x: new Date("2023-06-4").getTime(), y: 7 },
        { x: new Date("2023-08-7").getTime(), y: 8 },
        { x: new Date("2023-09-12").getTime(), y: 5 },
      ],
    },
  ],
  options: {
    xaxis: {
      type: "datetime" as AxisType,
      tickPlacement: "between",
    },
    yaxis: {
      title: {
        text: "Gas Consumption",
      },
      min: 0,
      max: 12.5,
    },
  },
};

export const MockCustomTrend = ({
  selectedProperties,
  selectedUnits,
  selectedMetrics,
  fromDate,
  toDate,
  track,
}: CustomTrendDTO) => {
  let startDate = fromDate;
  let endDate = toDate || new Date();
  const currentDate = new Date(startDate.getTime());
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  let data1 = [],
    data2 = [];
  for (let i = 0; i < dates.length; i += (dates.length - 1) / 7) {
    const index = Math.ceil(i);
    if (index >= dates.length) break;
    data1.push({
      x: dates[index].getTime(),
      y: (Math.random() * 12.5).toFixed(1),
    });
    data2.push({
      x: dates[index].getTime(),
      y: (Math.random() * 12.5).toFixed(1),
    });
  }

  return {
    id: (Math.random() * 12005).toFixed(1).toString(),
    track,
    title: `${selectedMetrics.join(", ")} for ${
      selectedProperties?.length
        ? "properties " + selectedProperties.join(", ")
        : selectedUnits?.length
        ? "units " + selectedUnits.join(", ")
        : "your portfolio"
    }`,
    series: [
      {
        name: "42nd Main St",
        data: data1,
      },
      {
        name: "5th Main St",
        data: data2,
      },
    ],
    options: {
      xaxis: {
        type: "datetime" as AxisType,
        tickPlacement: "between",
      },
      yaxis: {
        title: {
          text: selectedMetrics.join(", "),
        },
        min: 0,
        max: 12.5,
      },
    },
  };
};

export const DrilldownMockDTO = (type?: DrilldownType) => {
  switch (type) {
    case DrilldownType.MAINTENANCE:
      return drilldownText(DrilldownType.MAINTENANCE, [
        {
          accountID: 1,
          accountName: "a1",
          amount: 233,
        },
        {
          accountID: 2,
          accountName: "f51",
          amount: 13,
        },
      ]);
    case DrilldownType.RENOVATIONS:
      return drilldownText(DrilldownType.RENOVATIONS, [
        {
          unitID: 1,
          unitNumber: "a1",
        },
        {
          unitID: 2,
          unitNumber: "a2",
        },
      ]);
    case DrilldownType.OCCUPANCY:
      return drilldownText(DrilldownType.OCCUPANCY, [
        {
          unitType: "t1",
          status: "Rented",
          number: 234,
          percentage: 12,
        },
        {
          unitType: "t1",
          status: "Vacant",
          number: 567,
          percentage: 26,
        },
        {
          unitType: "t1",
          status: "RentedVacant",
          number: 102,
          percentage: 3,
        },
        {
          unitType: "t2",
          status: "Rented",
          number: 234,
          percentage: 12,
        },
        {
          unitType: "t2",
          status: "Vacant",
          number: 567,
          percentage: 26,
        },
        {
          unitType: "t2",
          status: "RentedVacant",
          number: 102,
          percentage: 3,
        },
      ] as OccupancyDrilldownDTO);
    // "1t": [
    //   {
    //     unitID: 20076,
    //     unitNumber: "a1",
    //     unitType: "1t",
    //     rented: true,
    //   },
    //   {
    //     unitID: 2,
    //     unitNumber: "a2",
    //     unitType: "1t",
    //     rented: false,
    //   },
    // ],
    // "2t": [
    //   {
    //     unitID: 3,
    //     unitNumber: "a3",
    //     unitType: "2t",
    //     rented: true,
    //   },
    //   {
    //     unitID: 4,
    //     unitNumber: "a4",
    //     unitType: "2t",
    //     rented: true,
    //   },
    // ],

    case DrilldownType.UTILITIES:
      return drilldownText(DrilldownType.UTILITIES, [
        {
          unitID: 1,
          unitNumber: "a1",
          current: 200,
          previous: 250,
        },
        {
          unitID: 2,
          unitNumber: "a2",
          current: 200,
          previous: 250,
        },
      ]);
    case DrilldownType.DELINQUENCY:
      return drilldownText(DrilldownType.DELINQUENCY, [
        {
          unitID: 1,
          unitNumber: "a1",
          period: "2 weeks",
          amount: 145,
        },
        {
          unitID: 2,
          unitNumber: "a2",
          period: "1 month",
          amount: 666,
        },
      ]);
    case DrilldownType.BUDGET:
      return drilldownText(DrilldownType.BUDGET, [
        {
          accountID: 1,
          accountNumber: "Property insurance",
          actual: 400,
          planned: 388,
        },
        {
          accountID: 2,
          accountNumber: "Property Management Fees",
          actual: 1000,
          planned: 800,
        },
      ]);
    case DrilldownType.COLLECTION:
      return drilldownText(DrilldownType.COLLECTION, [
        {
          date: new Date(),
          accumulativePrecentage: 10,
        },
        {
          date: new Date(),
          accumulativePrecentage: 17,
        },
        {
          date: new Date(),
          accumulativePrecentage: 62,
        },
        {
          date: new Date(),
          accumulativePrecentage: 100,
        },
      ]);
    case DrilldownType.TRAFFIC_APPLICANT:
      return drilldownText(DrilldownType["TRAFFIC_APPLICANT"], {
        topTracted: [
          {
            unitID: 1,
            unitNumber: "a1",
            visits: 3,
            applications: 1,
          },
          {
            unitID: 2,
            unitNumber: "a2",
            visits: 13,
            applications: 1,
          },
        ],
        leastTracted: [
          {
            unitID: 3,
            unitNumber: "a3",
            visits: 3,
            applications: 1,
          },
          {
            unitID: 4,
            unitNumber: "a4",
            visits: 13,
            applications: 1,
          },
        ],
      });
    case DrilldownType.LOSS_TO_LEASE:
      return drilldownText(DrilldownType.LOSS_TO_LEASE, [
        {
          unitID: 1,
          unitNumber: "a1",
          amount: 343,
          marketRent: 350,
          lossRatio: 2,
          loss: 12,
        },
        {
          unitID: 2,
          unitNumber: "b2",
          amount: 543,
          marketRent: 650,
          lossRatio: 22,
          loss: 120,
        },
      ]);
    case DrilldownType.EXPIRED_LEASES:
      return drilldownText(DrilldownType.EXPIRED_LEASES, [
        {
          unitID: 1,
          unitNumber: "a1",
          amount: 343,
          marketRent: 350,
          lossRatio: 2,
          loss: 12,
        },
        {
          unitID: 2,
          unitNumber: "b2",
          amount: 543,
          marketRent: 650,
          lossRatio: 22,
          loss: 120,
        },
      ]);
    case DrilldownType.RENEWALS:
      return drilldownText(DrilldownType.RENEWALS, [
        {
          propertyID: 1,
          date: new Date(),
          unitID: 11,
          unitType: "Appartment",
          tenantChange: "aaa",
        },
        {
          propertyID: 2,
          date: new Date(),
          unitID: 22,
          unitType: "Appartment",
          tenantChange: "bbb",
        },
      ]);
    default:
      return "Ask me further, what would you like to know?";
  }
};

export const AddressListMock = () => [
  "68th Stan St",
  "21st Barber St",
  "23rd Jump St",
  "23rd Jump St",
];

export const RoomsMock = {
  bedrooms: [2, 3, 4],
};

export const mockNearbyComps = {
  searchedAddress: {
    address: "68th Stan St",
    latitude: 36.88004,
    longitude: -82.30455,
  },
  nearbyComps: [
    {
      address: "67th Stan St",
      latitude: 36.87994,
      longitude: -82.30442,
      distance: 20,
      price: 34567843,
      bedrooms: 4,
      baths: "1",
      property_type: "apt",
      last_seen: "2024-06-08T00:00:00Z",
      sqft: 87,
      dollar_sqft: 98,
    },
  ],
};

export const GraphSuggestionsMock = [
  {
    graph_name: "expired_leases_unit_type_breakdown",
    text: "Show me the YTD count of expired leases with unit types breakdown",
    optional_params: [],
  },
  {
    graph_name: "lease_duration_unit_type_breakdown",
    text: "Show me the average lease duration with unit types breakdown",
    optional_params: [],
  },
  {
    graph_name: "turnovers_unit_type_breakdown",
    text: "Show me my turnovers with unit types breakdown",
    optional_params: [],
  },
  {
    graph_name: "water_consumption_monthly",
    text: "Show me my water expense on a monthly basis",
    optional_params: [],
  },
  {
    graph_name: "rent_growth_monthly",
    text: "Show me my rent growth trend on a monthly basis",
    optional_params: [],
  },
  {
    graph_name: "expenses_item_breakdown_bar_monthly",
    text: "Show me the expenses breakdown on a monthly basis",
    optional_params: [],
  },
  {
    graph_name: "collection_monthly",
    text: "Show me my month by month collection across my portfolio",
    optional_params: [],
  },
  {
    graph_name: "signed_renewals_monthly_unit_type_breakdown",
    text: "Show me the signed lease renewals on a monthly basis, with unit types breakdown",
    optional_params: [],
  },
  {
    graph_name: "opex_monthly",
    text: "Show me my month by month operational expense",
    optional_params: [],
  },
  {
    graph_name: "loss_to_lease_ut_breakdown",
    text: "Show me the loss-to-lease with unit types breakdown for the current month",
    optional_params: [],
  },
  {
    graph_name: "occupancy_rate_per_unit_type",
    text: "Show me the occupancy rate of the current month, with unit types breakdown, across my portfolio",
    optional_params: [],
  },
  {
    graph_name: "declined_renewals_unit_type_breakdown",
    text: "Show me the declined lease renewals' unit types breakdown",
    optional_params: [],
  },
  {
    graph_name: "occupied_units_per_unit_type",
    text: "Show me the count of occupied units of the current month, with unit types breakdown, across my portfolio",
    optional_params: [],
  },
  {
    graph_name: "vacant_units_per_unit_type",
    text: "Show me the count of vacant units of the current month, with unit types breakdown, across my portfolio",
    optional_params: [],
  },
  {
    graph_name: "collection_per_unit_type",
    text: "Show me my income breakdown per unit type across my portfolio",
    optional_params: [],
  },
  {
    graph_name: "electricity_monthly",
    text: "Show me my monthly electricity expense",
    optional_params: [],
  },
  {
    graph_name: "maintenance_monthly",
    text: "Show me my monthely maintenance expenses",
    optional_params: [],
  },
  {
    graph_name: "maintenance_item_pie",
    text: "Show me the maintenance expenses breakdown",
    optional_params: [],
  },
];

export const GraphsMocks = [
  {
    id: "613",
    track: false,
    series: [
      {
        name: "Collected amount",
        type: "line",
        data: [
          {
            x: "2023-01-01",
            y: "21053.91",
          },
          {
            x: "2023-02-01",
            y: "16406.14",
          },
          {
            x: "2023-03-01",
            y: "24000.65",
          },
          {
            x: "2023-04-01",
            y: "20570.72",
          },
          {
            x: "2023-05-01",
            y: "22554.54",
          },
          {
            x: "2023-06-01",
            y: "18973.82",
          },
          {
            x: "2023-07-01",
            y: "30861.22",
          },
          {
            x: "2023-08-01",
            y: "21947.70",
          },
          {
            x: "2023-09-01",
            y: "23682.24",
          },
          {
            x: "2023-10-01",
            y: "27584.03",
          },
          {
            x: "2023-11-01",
            y: "26113.12",
          },
          {
            x: "2023-12-01",
            y: "21337.69",
          },
          {
            x: "2024-01-01",
            y: "23183.23",
          },
          {
            x: "2024-02-01",
            y: "21396.40",
          },
          {
            x: "2024-03-01",
            y: "23369.38",
          },
          {
            x: "2024-04-01",
            y: "23364.82",
          },
          {
            x: "2024-05-01",
            y: "21196.27",
          },
          {
            x: "2024-06-01",
            y: "21410.96",
          },
          {
            x: "2024-07-01",
            y: "23203.60",
          },
          {
            x: "2024-08-01",
            y: "19591.48",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Collection ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#E6B447",
        "#59ADF6",
        "#FFB480",
        "#708090",
        "#D8BFD8",
        "#93E9BE",
        "#40E0D0",
        "#791C0B",
        "#006AF9",
        "#4682B4",
        "#5F9EA0",
        "#FF6961",
        "#9D94FF",
        "#C780E8",
        "#8A9A5B",
        "#CCCCFF",
        "#42D6A4",
        "#FF7F50",
        "#7FFFD4",
        "#08CAD1",
        "#B0E0E6",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "641",
    track: false,
    series: [
      {
        name: "Occupied units count",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "19",
          },
          {
            x: "2023-10-01",
            y: "19",
          },
          {
            x: "2023-11-01",
            y: "18",
          },
          {
            x: "2023-12-01",
            y: "18",
          },
          {
            x: "2024-01-01",
            y: "18",
          },
          {
            x: "2024-02-01",
            y: "18",
          },
          {
            x: "2024-03-01",
            y: "18",
          },
          {
            x: "2024-04-01",
            y: "18",
          },
          {
            x: "2024-05-01",
            y: "18",
          },
          {
            x: "2024-06-01",
            y: "17",
          },
          {
            x: "2024-07-01",
            y: "17",
          },
          {
            x: "2024-08-01",
            y: "18",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupied Units",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Count",
          align: "left",
        },
      },
      colors: [
        "#9D94FF",
        "#8A9A5B",
        "#708090",
        "#7FFFD4",
        "#4682B4",
        "#B0E0E6",
        "#93E9BE",
        "#40E0D0",
        "#CCCCFF",
        "#42D6A4",
        "#C780E8",
        "#D8BFD8",
        "#FF7F50",
        "#E6B447",
        "#59ADF6",
        "#FFB480",
        "#791C0B",
        "#08CAD1",
        "#FF6961",
        "#5F9EA0",
        "#006AF9",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "595",
    track: false,
    series: [
      {
        name: "Charged amount",
        type: "line",
        data: [
          {
            x: "2023-07-01",
            y: "24119.42",
          },
          {
            x: "2023-08-01",
            y: "23584.42",
          },
          {
            x: "2023-09-01",
            y: "24993.65",
          },
          {
            x: "2023-10-01",
            y: "24623.42",
          },
          {
            x: "2023-11-01",
            y: "23408.42",
          },
          {
            x: "2023-12-01",
            y: "23233.42",
          },
          {
            x: "2024-01-01",
            y: "23798.42",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Income ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#FF6961",
        "#B0E0E6",
        "#C780E8",
        "#4682B4",
        "#006AF9",
        "#93E9BE",
        "#FF7F50",
        "#9D94FF",
        "#08CAD1",
        "#FFB480",
        "#5F9EA0",
        "#7FFFD4",
        "#40E0D0",
        "#CCCCFF",
        "#8A9A5B",
        "#D8BFD8",
        "#42D6A4",
        "#791C0B",
        "#59ADF6",
        "#708090",
        "#E6B447",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "602",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "-25.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "-11.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "-14.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "-194.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "213.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "36.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "-9.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "-130.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "1371.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "-76.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "-208.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "251.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#5F9EA0",
        "#D8BFD8",
        "#708090",
        "#FF6961",
        "#FF7F50",
        "#CCCCFF",
        "#7FFFD4",
        "#C780E8",
        "#93E9BE",
        "#42D6A4",
        "#08CAD1",
        "#006AF9",
        "#4682B4",
        "#59ADF6",
        "#40E0D0",
        "#E6B447",
        "#8A9A5B",
        "#791C0B",
        "#FFB480",
        "#9D94FF",
        "#B0E0E6",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "644",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2023-07-01",
            y: "10193.47",
          },
          {
            x: "2023-10-01",
            y: "8045.56",
          },
          {
            x: "2024-01-01",
            y: "10525.47",
          },
          {
            x: "2024-04-01",
            y: "-18307.10",
          },
          {
            x: "2024-07-01",
            y: "3750.02",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#4682B4",
        "#FFB480",
        "#5F9EA0",
        "#FF7F50",
        "#93E9BE",
        "#E6B447",
        "#42D6A4",
        "#006AF9",
        "#791C0B",
        "#59ADF6",
        "#40E0D0",
        "#708090",
        "#D8BFD8",
        "#FF6961",
        "#7FFFD4",
        "#8A9A5B",
        "#C780E8",
        "#08CAD1",
        "#CCCCFF",
        "#B0E0E6",
        "#9D94FF",
      ],
    },
    granularity: "quarter",
    properties: ["4309 S Main Street"],
  },
  {
    id: "593",
    track: false,
    series: [
      {
        name: "Maintenance expense amount",
        type: "line",
        data: [
          {
            x: "2023-08-01",
            y: "898.0",
          },
          {
            x: "2023-09-01",
            y: "972.0",
          },
          {
            x: "2023-10-01",
            y: "4287.48",
          },
          {
            x: "2023-11-01",
            y: "1420.0",
          },
          {
            x: "2023-12-01",
            y: "1120.0",
          },
          {
            x: "2024-01-01",
            y: "200.0",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Repairs and Maintenance ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#708090",
        "#08CAD1",
        "#C780E8",
        "#006AF9",
        "#40E0D0",
        "#59ADF6",
        "#E6B447",
        "#9D94FF",
        "#791C0B",
        "#4682B4",
        "#7FFFD4",
        "#42D6A4",
        "#FF6961",
        "#8A9A5B",
        "#D8BFD8",
        "#B0E0E6",
        "#FFB480",
        "#FF7F50",
        "#CCCCFF",
        "#5F9EA0",
        "#93E9BE",
      ],
    },
    granularity: "week",
    properties: ["4309 S Main Street"],
  },
  {
    id: "645",
    track: false,
    series: [
      {
        name: "Capex amount",
        type: "line",
        data: [
          {
            x: "2023-10-01",
            y: "3988.97",
          },
          {
            x: "2023-11-01",
            y: "1548.0",
          },
          {
            x: "2023-12-01",
            y: "968.0",
          },
          {
            x: "2024-01-01",
            y: "360.0",
          },
          {
            x: "2024-03-01",
            y: "300.0",
          },
          {
            x: "2024-04-01",
            y: "1100.0",
          },
          {
            x: "2024-05-01",
            y: "3068.81",
          },
          {
            x: "2024-06-01",
            y: "18935.0",
          },
          {
            x: "2024-07-01",
            y: "1450.0",
          },
          {
            x: "2024-08-01",
            y: "2281.00",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "CAPEX ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#40E0D0",
        "#B0E0E6",
        "#FF6961",
        "#791C0B",
        "#08CAD1",
        "#8A9A5B",
        "#42D6A4",
        "#7FFFD4",
        "#9D94FF",
        "#CCCCFF",
        "#C780E8",
        "#E6B447",
        "#FFB480",
        "#59ADF6",
        "#D8BFD8",
        "#5F9EA0",
        "#708090",
        "#93E9BE",
        "#FF7F50",
        "#006AF9",
        "#4682B4",
      ],
    },
    granularity: "quarter",
    properties: ["4309 S Main Street"],
  },
  {
    id: "685",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.00",
          },
          {
            x: "2024-03-01",
            y: "-8.90",
          },
          {
            x: "2024-04-01",
            y: "-126.46",
          },
          {
            x: "2024-05-01",
            y: "247.74",
          },
          {
            x: "2024-06-01",
            y: "-17.14",
          },
          {
            x: "2024-07-01",
            y: "-190.82",
          },
          {
            x: "2024-08-01",
            y: "22.69",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth Period",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#7FFFD4",
        "#4682B4",
        "#FFB480",
        "#FF6961",
        "#FF7F50",
        "#D8BFD8",
        "#59ADF6",
        "#8A9A5B",
        "#9D94FF",
        "#40E0D0",
        "#CCCCFF",
        "#708090",
        "#B0E0E6",
        "#791C0B",
        "#42D6A4",
        "#006AF9",
        "#93E9BE",
        "#5F9EA0",
        "#08CAD1",
        "#C780E8",
        "#E6B447",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "646",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2023-10-01",
            y: "8045.56",
          },
          {
            x: "2024-01-01",
            y: "10525.47",
          },
          {
            x: "2024-04-01",
            y: "-18307.10",
          },
          {
            x: "2024-07-01",
            y: "4415.10",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#FFB480",
        "#E6B447",
        "#4682B4",
        "#8A9A5B",
        "#40E0D0",
        "#CCCCFF",
        "#006AF9",
        "#D8BFD8",
        "#93E9BE",
        "#B0E0E6",
        "#FF6961",
        "#42D6A4",
        "#708090",
        "#791C0B",
        "#08CAD1",
        "#59ADF6",
        "#FF7F50",
        "#9D94FF",
        "#C780E8",
        "#7FFFD4",
        "#5F9EA0",
      ],
    },
    granularity: "quarter",
    properties: ["4309 S Main Street"],
  },
  {
    id: "686",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2023-10-01",
            y: "1.00000000000000000000",
          },
          {
            x: "2023-11-01",
            y: "-117.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "691.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "9.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "-26.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "-160.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "244.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "3.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "-5.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "-35.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "103.0000000000000000",
          },
          {
            x: "2024-09-01",
            y: "18.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth Month to Month",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#4682B4",
        "#5F9EA0",
        "#FF7F50",
        "#8A9A5B",
        "#791C0B",
        "#B0E0E6",
        "#40E0D0",
        "#7FFFD4",
        "#E6B447",
        "#FFB480",
        "#006AF9",
        "#59ADF6",
        "#CCCCFF",
        "#708090",
        "#42D6A4",
        "#08CAD1",
        "#D8BFD8",
        "#FF6961",
        "#9D94FF",
        "#C780E8",
        "#93E9BE",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["6221 W 6th Street"],
  },
  {
    id: "647",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "90.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#40E0D0",
        "#FF6961",
        "#5F9EA0",
        "#FF7F50",
        "#42D6A4",
        "#9D94FF",
        "#7FFFD4",
        "#59ADF6",
        "#E6B447",
        "#D8BFD8",
        "#93E9BE",
        "#708090",
        "#006AF9",
        "#08CAD1",
        "#B0E0E6",
        "#791C0B",
        "#CCCCFF",
        "#8A9A5B",
        "#C780E8",
        "#FFB480",
        "#4682B4",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "649",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "90.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#791C0B",
        "#40E0D0",
        "#CCCCFF",
        "#42D6A4",
        "#5F9EA0",
        "#C780E8",
        "#59ADF6",
        "#08CAD1",
        "#FFB480",
        "#D8BFD8",
        "#8A9A5B",
        "#93E9BE",
        "#708090",
        "#9D94FF",
        "#E6B447",
        "#FF6961",
        "#4682B4",
        "#7FFFD4",
        "#B0E0E6",
        "#006AF9",
        "#FF7F50",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "648",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "90.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#5F9EA0",
        "#9D94FF",
        "#93E9BE",
        "#006AF9",
        "#D8BFD8",
        "#708090",
        "#FFB480",
        "#C780E8",
        "#E6B447",
        "#08CAD1",
        "#42D6A4",
        "#7FFFD4",
        "#FF7F50",
        "#FF6961",
        "#59ADF6",
        "#B0E0E6",
        "#40E0D0",
        "#4682B4",
        "#791C0B",
        "#CCCCFF",
        "#8A9A5B",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "650",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2022-10-01",
            y: "80.0000000000000000",
          },
          {
            x: "2022-11-01",
            y: "75.0000000000000000",
          },
          {
            x: "2022-12-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-01-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-02-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-03-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-04-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-07-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-08-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "90.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#006AF9",
        "#CCCCFF",
        "#E6B447",
        "#7FFFD4",
        "#FF6961",
        "#8A9A5B",
        "#FFB480",
        "#791C0B",
        "#08CAD1",
        "#9D94FF",
        "#93E9BE",
        "#C780E8",
        "#59ADF6",
        "#5F9EA0",
        "#D8BFD8",
        "#708090",
        "#42D6A4",
        "#4682B4",
        "#FF7F50",
        "#B0E0E6",
        "#40E0D0",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "687",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.00",
          },
          {
            x: "2024-03-01",
            y: "-8.90",
          },
          {
            x: "2024-04-01",
            y: "-126.46",
          },
          {
            x: "2024-05-01",
            y: "247.74",
          },
          {
            x: "2024-06-01",
            y: "-17.14",
          },
          {
            x: "2024-07-01",
            y: "-190.82",
          },
          {
            x: "2024-08-01",
            y: "22.69",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth Period",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#8A9A5B",
        "#FF7F50",
        "#42D6A4",
        "#C780E8",
        "#CCCCFF",
        "#791C0B",
        "#7FFFD4",
        "#D8BFD8",
        "#708090",
        "#5F9EA0",
        "#4682B4",
        "#59ADF6",
        "#B0E0E6",
        "#9D94FF",
        "#FFB480",
        "#08CAD1",
        "#40E0D0",
        "#E6B447",
        "#FF6961",
        "#006AF9",
        "#93E9BE",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "688",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2023-10-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2023-11-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2023-12-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-01-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-02-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-03-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-04-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-05-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-06-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-07-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2024-08-01",
            y: "0.00000000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#FFB480",
        "#4682B4",
        "#5F9EA0",
        "#9D94FF",
        "#59ADF6",
        "#FF6961",
        "#93E9BE",
        "#7FFFD4",
        "#40E0D0",
        "#08CAD1",
        "#8A9A5B",
        "#42D6A4",
        "#FF7F50",
        "#006AF9",
        "#E6B447",
        "#B0E0E6",
        "#791C0B",
        "#708090",
        "#C780E8",
        "#D8BFD8",
        "#CCCCFF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["6106 10th Ave"],
  },
  {
    id: "653",
    track: false,
    series: [
      {
        name: "Turnovers count",
        type: "line",
        data: [
          {
            x: "2023-10-01",
            y: "0",
          },
          {
            x: "2023-11-01",
            y: "0",
          },
          {
            x: "2023-12-01",
            y: "0",
          },
          {
            x: "2024-01-01",
            y: "0",
          },
          {
            x: "2024-02-01",
            y: "0",
          },
          {
            x: "2024-03-01",
            y: "0",
          },
          {
            x: "2024-04-01",
            y: "0",
          },
          {
            x: "2024-05-01",
            y: "1",
          },
          {
            x: "2024-06-01",
            y: "0",
          },
          {
            x: "2024-07-01",
            y: "0",
          },
          {
            x: "2024-08-01",
            y: "1",
          },
          {
            x: "2024-09-01",
            y: "0",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Turnovers",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Count",
          align: "left",
        },
      },
      colors: [
        "#C780E8",
        "#8A9A5B",
        "#E6B447",
        "#42D6A4",
        "#FF6961",
        "#791C0B",
        "#FFB480",
        "#40E0D0",
        "#B0E0E6",
        "#7FFFD4",
        "#9D94FF",
        "#006AF9",
        "#FF7F50",
        "#4682B4",
        "#D8BFD8",
        "#08CAD1",
        "#5F9EA0",
        "#59ADF6",
        "#708090",
        "#CCCCFF",
        "#93E9BE",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "592",
    track: false,
    series: [
      {
        name: "Maintenance expense amount",
        type: "column",
        data: [
          {
            x: "2023-09-01",
            y: "972.0",
          },
          {
            x: "2023-10-01",
            y: "4287.48",
          },
          {
            x: "2023-11-01",
            y: "1420.0",
          },
          {
            x: "2023-12-01",
            y: "1120.0",
          },
          {
            x: "2024-01-01",
            y: "200.0",
          },
          {
            x: "2024-02-01",
            y: "52.0",
          },
          {
            x: "2024-03-01",
            y: "1088.16",
          },
          {
            x: "2024-04-01",
            y: "180.0",
          },
          {
            x: "2024-05-01",
            y: "454.0",
          },
          {
            x: "2024-06-01",
            y: "690.92",
          },
          {
            x: "2024-07-01",
            y: "14586.0",
          },
          {
            x: "2024-08-01",
            y: "199.04",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Maintenance Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#708090",
        "#7FFFD4",
        "#E6B447",
        "#006AF9",
        "#C780E8",
        "#5F9EA0",
        "#791C0B",
        "#FFB480",
        "#9D94FF",
        "#42D6A4",
        "#FF7F50",
        "#59ADF6",
        "#4682B4",
        "#B0E0E6",
        "#40E0D0",
        "#D8BFD8",
        "#93E9BE",
        "#08CAD1",
        "#8A9A5B",
        "#FF6961",
        "#CCCCFF",
      ],
    },
    granularity: "week",
    properties: ["4309 S Main Street"],
  },
  {
    id: "689",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "96.5517241379310345",
          },
          {
            x: "2023-10-01",
            y: "96.5517241379310345",
          },
          {
            x: "2023-11-01",
            y: "93.1034482758620690",
          },
          {
            x: "2023-12-01",
            y: "93.1034482758620690",
          },
          {
            x: "2024-01-01",
            y: "93.1034482758620690",
          },
          {
            x: "2024-02-01",
            y: "93.1034482758620690",
          },
          {
            x: "2024-03-01",
            y: "89.6551724137931034",
          },
          {
            x: "2024-04-01",
            y: "86.2068965517241379",
          },
          {
            x: "2024-05-01",
            y: "89.6551724137931034",
          },
          {
            x: "2024-06-01",
            y: "86.2068965517241379",
          },
          {
            x: "2024-07-01",
            y: "89.6551724137931034",
          },
          {
            x: "2024-08-01",
            y: "89.6551724137931034",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#42D6A4",
        "#CCCCFF",
        "#93E9BE",
        "#7FFFD4",
        "#FFB480",
        "#E6B447",
        "#40E0D0",
        "#4682B4",
        "#08CAD1",
        "#9D94FF",
        "#D8BFD8",
        "#791C0B",
        "#8A9A5B",
        "#708090",
        "#5F9EA0",
        "#FF7F50",
        "#59ADF6",
        "#006AF9",
        "#B0E0E6",
        "#FF6961",
        "#C780E8",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["6106 10th Ave", "4309 S Main Street"],
  },
  {
    id: "690",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "90.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#8A9A5B",
        "#B0E0E6",
        "#42D6A4",
        "#40E0D0",
        "#791C0B",
        "#08CAD1",
        "#9D94FF",
        "#E6B447",
        "#5F9EA0",
        "#FF7F50",
        "#59ADF6",
        "#006AF9",
        "#FF6961",
        "#7FFFD4",
        "#4682B4",
        "#C780E8",
        "#708090",
        "#FFB480",
        "#93E9BE",
        "#D8BFD8",
        "#CCCCFF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "654",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "573.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "-270.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "14.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "51.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "-187.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "55.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "-158.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-09-01",
            y: "927.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#59ADF6",
        "#B0E0E6",
        "#C780E8",
        "#791C0B",
        "#40E0D0",
        "#08CAD1",
        "#FF6961",
        "#FF7F50",
        "#CCCCFF",
        "#8A9A5B",
        "#5F9EA0",
        "#006AF9",
        "#9D94FF",
        "#708090",
        "#E6B447",
        "#4682B4",
        "#7FFFD4",
        "#93E9BE",
        "#D8BFD8",
        "#42D6A4",
        "#FFB480",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["935 S Mariposa Ave"],
  },
  {
    id: "655",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2023-06-01",
            y: "0.00000000000000000000",
          },
          {
            x: "2023-07-01",
            y: "-286.0000000000000000",
          },
          {
            x: "2023-08-01",
            y: "52.0000000000000000",
          },
          {
            x: "2023-09-01",
            y: "-97.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "-21.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "171.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "-93.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "573.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "-270.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "14.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "51.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "-187.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "55.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "-158.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-09-01",
            y: "927.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#791C0B",
        "#7FFFD4",
        "#C780E8",
        "#08CAD1",
        "#59ADF6",
        "#8A9A5B",
        "#40E0D0",
        "#006AF9",
        "#FFB480",
        "#9D94FF",
        "#E6B447",
        "#FF6961",
        "#D8BFD8",
        "#FF7F50",
        "#93E9BE",
        "#708090",
        "#42D6A4",
        "#CCCCFF",
        "#B0E0E6",
        "#5F9EA0",
        "#4682B4",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["935 S Mariposa Ave"],
  },
  {
    id: "656",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "-270.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "14.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "51.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "-187.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "55.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "-158.0000000000000000",
          },
          {
            x: "2024-08-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-09-01",
            y: "927.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#791C0B",
        "#708090",
        "#08CAD1",
        "#93E9BE",
        "#FF7F50",
        "#FF6961",
        "#B0E0E6",
        "#59ADF6",
        "#FFB480",
        "#4682B4",
        "#7FFFD4",
        "#8A9A5B",
        "#E6B447",
        "#CCCCFF",
        "#9D94FF",
        "#42D6A4",
        "#40E0D0",
        "#006AF9",
        "#5F9EA0",
        "#C780E8",
        "#D8BFD8",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["935 S Mariposa Ave"],
  },
  {
    id: "596",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2023-08-01",
            y: "17.31911283480790207200",
          },
          {
            x: "2023-09-01",
            y: "17.91029350971475816500",
          },
          {
            x: "2023-10-01",
            y: "17.91029350971475816500",
          },
          {
            x: "2023-11-01",
            y: "17.91029350971475816500",
          },
          {
            x: "2023-12-01",
            y: "19.07278404996396829200",
          },
          {
            x: "2024-01-01",
            y: "19.74114635187028872100",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#CCCCFF",
        "#5F9EA0",
        "#FF6961",
        "#E6B447",
        "#7FFFD4",
        "#006AF9",
        "#40E0D0",
        "#8A9A5B",
        "#C780E8",
        "#FFB480",
        "#42D6A4",
        "#9D94FF",
        "#59ADF6",
        "#791C0B",
        "#B0E0E6",
        "#D8BFD8",
        "#FF7F50",
        "#08CAD1",
        "#4682B4",
        "#708090",
        "#93E9BE",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "657",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2023-07-01",
            y: "0.0",
          },
          {
            x: "2023-08-01",
            y: "-1.692268495319471",
          },
          {
            x: "2023-09-01",
            y: "4.9321635370667805",
          },
          {
            x: "2023-10-01",
            y: "9.537813269453332",
          },
          {
            x: "2023-11-01",
            y: "2.839250475480437",
          },
          {
            x: "2023-12-01",
            y: "2.839250475480437",
          },
          {
            x: "2024-01-01",
            y: "2.839250475480437",
          },
          {
            x: "2024-02-01",
            y: "2.839250475480437",
          },
          {
            x: "2024-03-01",
            y: "2.839250475480437",
          },
          {
            x: "2024-04-01",
            y: "11.205308834299643",
          },
          {
            x: "2024-05-01",
            y: "5.11784299696989",
          },
          {
            x: "2024-06-01",
            y: "-1.2131214738530405",
          },
          {
            x: "2024-07-01",
            y: "-8.494576749547079",
          },
          {
            x: "2024-08-01",
            y: "5.957866275063206",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#42D6A4",
        "#006AF9",
        "#C780E8",
        "#FF7F50",
        "#7FFFD4",
        "#791C0B",
        "#CCCCFF",
        "#93E9BE",
        "#59ADF6",
        "#FFB480",
        "#708090",
        "#D8BFD8",
        "#40E0D0",
        "#5F9EA0",
        "#B0E0E6",
        "#FF6961",
        "#9D94FF",
        "#4682B4",
        "#08CAD1",
        "#E6B447",
        "#8A9A5B",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "658",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "0.0",
          },
          {
            x: "2024-04-01",
            y: "8.135082976721897",
          },
          {
            x: "2024-05-01",
            y: "2.215683711184502",
          },
          {
            x: "2024-06-01",
            y: "-3.9404915249743766",
          },
          {
            x: "2024-07-01",
            y: "-11.020915820200184",
          },
          {
            x: "2024-08-01",
            y: "3.032515100181832",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#CCCCFF",
        "#791C0B",
        "#FF6961",
        "#8A9A5B",
        "#40E0D0",
        "#4682B4",
        "#006AF9",
        "#D8BFD8",
        "#708090",
        "#5F9EA0",
        "#7FFFD4",
        "#C780E8",
        "#9D94FF",
        "#FF7F50",
        "#E6B447",
        "#42D6A4",
        "#FFB480",
        "#93E9BE",
        "#B0E0E6",
        "#59ADF6",
        "#08CAD1",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "659",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "-8.90195074475727",
          },
          {
            x: "2024-04-01",
            y: "-126.46134838844083",
          },
          {
            x: "2024-05-01",
            y: "247.73815804338884",
          },
          {
            x: "2024-06-01",
            y: "-17.14460137788646",
          },
          {
            x: "2024-07-01",
            y: "-190.81550687129396",
          },
          {
            x: "2024-08-01",
            y: "22.688588749506366",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#4682B4",
        "#D8BFD8",
        "#FF6961",
        "#FFB480",
        "#08CAD1",
        "#93E9BE",
        "#791C0B",
        "#CCCCFF",
        "#8A9A5B",
        "#708090",
        "#E6B447",
        "#B0E0E6",
        "#40E0D0",
        "#C780E8",
        "#FF7F50",
        "#006AF9",
        "#42D6A4",
        "#59ADF6",
        "#9D94FF",
        "#5F9EA0",
        "#7FFFD4",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "660",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "-8.90195074475727",
          },
          {
            x: "2024-04-01",
            y: "-126.46134838844083",
          },
          {
            x: "2024-05-01",
            y: "247.73815804338884",
          },
          {
            x: "2024-06-01",
            y: "-17.14460137788646",
          },
          {
            x: "2024-07-01",
            y: "-190.81550687129396",
          },
          {
            x: "2024-08-01",
            y: "22.688588749506366",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#FF6961",
        "#FFB480",
        "#D8BFD8",
        "#4682B4",
        "#42D6A4",
        "#FF7F50",
        "#8A9A5B",
        "#7FFFD4",
        "#708090",
        "#006AF9",
        "#C780E8",
        "#E6B447",
        "#40E0D0",
        "#B0E0E6",
        "#08CAD1",
        "#791C0B",
        "#5F9EA0",
        "#CCCCFF",
        "#93E9BE",
        "#59ADF6",
        "#9D94FF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "661",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "-8.90195074475727",
          },
          {
            x: "2024-04-01",
            y: "-126.46134838844083",
          },
          {
            x: "2024-05-01",
            y: "247.73815804338884",
          },
          {
            x: "2024-06-01",
            y: "-17.14460137788646",
          },
          {
            x: "2024-07-01",
            y: "-190.81550687129396",
          },
          {
            x: "2024-08-01",
            y: "22.688588749506366",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#4682B4",
        "#42D6A4",
        "#FFB480",
        "#CCCCFF",
        "#D8BFD8",
        "#006AF9",
        "#8A9A5B",
        "#7FFFD4",
        "#E6B447",
        "#FF6961",
        "#791C0B",
        "#5F9EA0",
        "#9D94FF",
        "#C780E8",
        "#B0E0E6",
        "#93E9BE",
        "#40E0D0",
        "#708090",
        "#59ADF6",
        "#FF7F50",
        "#08CAD1",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "601",
    track: false,
    series: [
      {
        name: "Loss-to-lease amount",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "10870.75",
          },
          {
            x: "2023-10-01",
            y: "9890.98",
          },
          {
            x: "2023-11-01",
            y: "11315.98",
          },
          {
            x: "2023-12-01",
            y: "11315.98",
          },
          {
            x: "2024-01-01",
            y: "11315.98",
          },
          {
            x: "2024-02-01",
            y: "11315.98",
          },
          {
            x: "2024-03-01",
            y: "11315.98",
          },
          {
            x: "2024-04-01",
            y: "9429.98",
          },
          {
            x: "2024-05-01",
            y: "10724.98",
          },
          {
            x: "2024-06-01",
            y: "12071.78",
          },
          {
            x: "2024-07-01",
            y: "13620.78",
          },
          {
            x: "2024-08-01",
            y: "10546.28",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Loss to Lease",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#C780E8",
        "#CCCCFF",
        "#708090",
        "#D8BFD8",
        "#FF7F50",
        "#5F9EA0",
        "#42D6A4",
        "#08CAD1",
        "#9D94FF",
        "#791C0B",
        "#59ADF6",
        "#B0E0E6",
        "#8A9A5B",
        "#7FFFD4",
        "#E6B447",
        "#FF6961",
        "#40E0D0",
        "#FFB480",
        "#006AF9",
        "#4682B4",
        "#93E9BE",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "662",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "-8.90195074475727",
          },
          {
            x: "2024-04-01",
            y: "-126.46134838844083",
          },
          {
            x: "2024-05-01",
            y: "247.73815804338884",
          },
          {
            x: "2024-06-01",
            y: "-17.14460137788646",
          },
          {
            x: "2024-07-01",
            y: "-190.81550687129396",
          },
          {
            x: "2024-08-01",
            y: "22.688588749506366",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#708090",
        "#8A9A5B",
        "#7FFFD4",
        "#59ADF6",
        "#4682B4",
        "#D8BFD8",
        "#40E0D0",
        "#93E9BE",
        "#42D6A4",
        "#CCCCFF",
        "#FF7F50",
        "#791C0B",
        "#FFB480",
        "#C780E8",
        "#006AF9",
        "#9D94FF",
        "#E6B447",
        "#08CAD1",
        "#B0E0E6",
        "#FF6961",
        "#5F9EA0",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "663",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "0.0",
          },
          {
            x: "2024-02-01",
            y: "36.12258229269689",
          },
          {
            x: "2024-03-01",
            y: "24.00501706450935",
          },
          {
            x: "2024-04-01",
            y: "-136.01987073581262",
          },
          {
            x: "2024-05-01",
            y: "373.3501603457204",
          },
          {
            x: "2024-06-01",
            y: "12.784908173328557",
          },
          {
            x: "2024-07-01",
            y: "-223.62041307540693",
          },
          {
            x: "2024-08-01",
            y: "67.00687518429527",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#791C0B",
        "#708090",
        "#7FFFD4",
        "#8A9A5B",
        "#9D94FF",
        "#FF6961",
        "#59ADF6",
        "#08CAD1",
        "#B0E0E6",
        "#93E9BE",
        "#E6B447",
        "#40E0D0",
        "#4682B4",
        "#5F9EA0",
        "#006AF9",
        "#FF7F50",
        "#D8BFD8",
        "#CCCCFF",
        "#FFB480",
        "#42D6A4",
        "#C780E8",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "607",
    track: false,
    series: [
      {
        name: "Expired leases count",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "0",
          },
          {
            x: "2023-10-01",
            y: "0",
          },
          {
            x: "2023-11-01",
            y: "1",
          },
          {
            x: "2023-12-01",
            y: "0",
          },
          {
            x: "2024-01-01",
            y: "0",
          },
          {
            x: "2024-02-01",
            y: "0",
          },
          {
            x: "2024-03-01",
            y: "0",
          },
          {
            x: "2024-04-01",
            y: "0",
          },
          {
            x: "2024-05-01",
            y: "0",
          },
          {
            x: "2024-06-01",
            y: "0",
          },
          {
            x: "2024-07-01",
            y: "0",
          },
          {
            x: "2024-08-01",
            y: "0",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Expired Leases",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "",
          align: "left",
        },
      },
      colors: [
        "#FF7F50",
        "#CCCCFF",
        "#E6B447",
        "#59ADF6",
        "#791C0B",
        "#D8BFD8",
        "#C780E8",
        "#B0E0E6",
        "#40E0D0",
        "#006AF9",
        "#5F9EA0",
        "#9D94FF",
        "#FF6961",
        "#93E9BE",
        "#08CAD1",
        "#8A9A5B",
        "#42D6A4",
        "#708090",
        "#7FFFD4",
        "#FFB480",
        "#4682B4",
      ],
    },
    granularity: "month",
    properties: ["130 N. Westmoreland Ave"],
  },
  {
    id: "608",
    track: false,
    series: [
      {
        name: "Maintenance expense amount",
        type: "line",
        data: [
          {
            x: "2023-01-01",
            y: "938.0",
          },
          {
            x: "2023-02-01",
            y: "2258.0",
          },
          {
            x: "2023-03-01",
            y: "1128.0",
          },
          {
            x: "2023-04-01",
            y: "2220.37",
          },
          {
            x: "2023-05-01",
            y: "112.0",
          },
          {
            x: "2023-06-01",
            y: "855.0",
          },
          {
            x: "2023-07-01",
            y: "4644.0",
          },
          {
            x: "2023-08-01",
            y: "898.0",
          },
          {
            x: "2023-09-01",
            y: "972.0",
          },
          {
            x: "2023-10-01",
            y: "4287.48",
          },
          {
            x: "2023-11-01",
            y: "1420.0",
          },
          {
            x: "2023-12-01",
            y: "1120.0",
          },
          {
            x: "2024-01-01",
            y: "200.0",
          },
          {
            x: "2024-02-01",
            y: "52.0",
          },
          {
            x: "2024-03-01",
            y: "1088.16",
          },
          {
            x: "2024-04-01",
            y: "180.0",
          },
          {
            x: "2024-05-01",
            y: "454.0",
          },
          {
            x: "2024-06-01",
            y: "690.92",
          },
          {
            x: "2024-07-01",
            y: "14586.0",
          },
          {
            x: "2024-08-01",
            y: "199.04",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Repairs and Maintenance ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#7FFFD4",
        "#93E9BE",
        "#40E0D0",
        "#59ADF6",
        "#9D94FF",
        "#8A9A5B",
        "#4682B4",
        "#FF6961",
        "#708090",
        "#B0E0E6",
        "#791C0B",
        "#D8BFD8",
        "#E6B447",
        "#42D6A4",
        "#CCCCFF",
        "#C780E8",
        "#08CAD1",
        "#FFB480",
        "#006AF9",
        "#FF7F50",
        "#5F9EA0",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "609",
    track: false,
    series: [
      {
        name: "Occupancy rate",
        type: "line",
        data: [
          {
            x: "2023-01-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-02-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-03-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-04-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2023-07-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-08-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-09-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-10-01",
            y: "95.0000000000000000",
          },
          {
            x: "2023-11-01",
            y: "90.0000000000000000",
          },
          {
            x: "2023-12-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-01-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-02-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-03-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-04-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-05-01",
            y: "90.0000000000000000",
          },
          {
            x: "2024-06-01",
            y: "85.0000000000000000",
          },
          {
            x: "2024-07-01",
            y: "85.0000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupancy Rate",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#93E9BE",
        "#D8BFD8",
        "#5F9EA0",
        "#FFB480",
        "#08CAD1",
        "#FF7F50",
        "#E6B447",
        "#B0E0E6",
        "#791C0B",
        "#FF6961",
        "#4682B4",
        "#9D94FF",
        "#C780E8",
        "#59ADF6",
        "#708090",
        "#006AF9",
        "#8A9A5B",
        "#40E0D0",
        "#7FFFD4",
        "#42D6A4",
        "#CCCCFF",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "664",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "935.40",
          },
          {
            x: "2024-03-01",
            y: "3420.25",
          },
          {
            x: "2024-04-01",
            y: "858.67",
          },
          {
            x: "2024-05-01",
            y: "-19949.62",
          },
          {
            x: "2024-06-01",
            y: "783.85",
          },
          {
            x: "2024-07-01",
            y: "2627.26",
          },
          {
            x: "2024-08-01",
            y: "1787.84",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#40E0D0",
        "#006AF9",
        "#FFB480",
        "#8A9A5B",
        "#FF7F50",
        "#4682B4",
        "#FF6961",
        "#5F9EA0",
        "#708090",
        "#D8BFD8",
        "#791C0B",
        "#59ADF6",
        "#B0E0E6",
        "#42D6A4",
        "#08CAD1",
        "#93E9BE",
        "#C780E8",
        "#7FFFD4",
        "#9D94FF",
        "#E6B447",
        "#CCCCFF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "666",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "935.40",
          },
          {
            x: "2024-03-01",
            y: "3420.25",
          },
          {
            x: "2024-04-01",
            y: "858.67",
          },
          {
            x: "2024-05-01",
            y: "-19949.62",
          },
          {
            x: "2024-06-01",
            y: "783.85",
          },
          {
            x: "2024-07-01",
            y: "2627.26",
          },
          {
            x: "2024-08-01",
            y: "1787.84",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#006AF9",
        "#FF7F50",
        "#B0E0E6",
        "#08CAD1",
        "#8A9A5B",
        "#E6B447",
        "#D8BFD8",
        "#5F9EA0",
        "#C780E8",
        "#9D94FF",
        "#59ADF6",
        "#93E9BE",
        "#708090",
        "#FFB480",
        "#CCCCFF",
        "#FF6961",
        "#4682B4",
        "#42D6A4",
        "#40E0D0",
        "#791C0B",
        "#7FFFD4",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "665",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "3293.02",
          },
          {
            x: "2024-02-01",
            y: "973.70",
          },
          {
            x: "2024-03-01",
            y: "4225.42",
          },
          {
            x: "2024-04-01",
            y: "1053.82",
          },
          {
            x: "2024-05-01",
            y: "3938.77",
          },
          {
            x: "2024-06-01",
            y: "569.14",
          },
          {
            x: "2024-07-01",
            y: "4190.73",
          },
          {
            x: "2024-08-01",
            y: "582.80",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#FFB480",
        "#93E9BE",
        "#C780E8",
        "#08CAD1",
        "#FF7F50",
        "#40E0D0",
        "#FF6961",
        "#CCCCFF",
        "#D8BFD8",
        "#5F9EA0",
        "#9D94FF",
        "#42D6A4",
        "#59ADF6",
        "#708090",
        "#4682B4",
        "#791C0B",
        "#B0E0E6",
        "#006AF9",
        "#7FFFD4",
        "#E6B447",
        "#8A9A5B",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["935 S Mariposa Ave"],
  },
  {
    id: "667",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "6169.82",
          },
          {
            x: "2024-02-01",
            y: "935.40",
          },
          {
            x: "2024-03-01",
            y: "3420.25",
          },
          {
            x: "2024-04-01",
            y: "858.67",
          },
          {
            x: "2024-05-01",
            y: "-19949.62",
          },
          {
            x: "2024-06-01",
            y: "783.85",
          },
          {
            x: "2024-07-01",
            y: "2627.26",
          },
          {
            x: "2024-08-01",
            y: "1787.84",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#FF7F50",
        "#5F9EA0",
        "#9D94FF",
        "#FFB480",
        "#4682B4",
        "#B0E0E6",
        "#8A9A5B",
        "#42D6A4",
        "#791C0B",
        "#40E0D0",
        "#59ADF6",
        "#08CAD1",
        "#CCCCFF",
        "#006AF9",
        "#708090",
        "#7FFFD4",
        "#C780E8",
        "#FF6961",
        "#E6B447",
        "#D8BFD8",
        "#93E9BE",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "669",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "0.0",
          },
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "0.0",
          },
          {
            x: "2024-04-01",
            y: "8.135082976721897",
          },
          {
            x: "2024-05-01",
            y: "2.215683711184502",
          },
          {
            x: "2024-06-01",
            y: "-3.9404915249743766",
          },
          {
            x: "2024-07-01",
            y: "-11.020915820200184",
          },
          {
            x: "2024-08-01",
            y: "3.032515100181832",
          },
          {
            x: "2024-09-01",
            y: "3.8072936140880698",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth Period",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#8A9A5B",
        "#C780E8",
        "#FF6961",
        "#9D94FF",
        "#40E0D0",
        "#4682B4",
        "#7FFFD4",
        "#E6B447",
        "#08CAD1",
        "#FF7F50",
        "#CCCCFF",
        "#42D6A4",
        "#B0E0E6",
        "#59ADF6",
        "#5F9EA0",
        "#708090",
        "#93E9BE",
        "#791C0B",
        "#FFB480",
        "#006AF9",
        "#D8BFD8",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "671",
    track: false,
    series: [
      {
        name: "NOI amount",
        type: "line",
        data: [
          {
            x: "2023-12-01",
            y: "-6285.38",
          },
          {
            x: "2024-01-01",
            y: "7087.81",
          },
          {
            x: "2024-02-01",
            y: "9648.11",
          },
          {
            x: "2024-03-01",
            y: "8789.24",
          },
          {
            x: "2024-04-01",
            y: "-2553.02",
          },
          {
            x: "2024-05-01",
            y: "33550.16",
          },
          {
            x: "2024-06-01",
            y: "7993.98",
          },
          {
            x: "2024-07-01",
            y: "-8761.98",
          },
          {
            x: "2024-08-01",
            y: "11837.13",
          },
          {
            x: "2024-09-01",
            y: "14166.75",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#FF6961",
        "#4682B4",
        "#006AF9",
        "#E6B447",
        "#5F9EA0",
        "#42D6A4",
        "#59ADF6",
        "#C780E8",
        "#708090",
        "#40E0D0",
        "#791C0B",
        "#FF7F50",
        "#08CAD1",
        "#B0E0E6",
        "#FFB480",
        "#CCCCFF",
        "#93E9BE",
        "#7FFFD4",
        "#8A9A5B",
        "#D8BFD8",
        "#9D94FF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "672",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2023-12-01",
            y: "0.0",
          },
          {
            x: "2024-01-01",
            y: "-212.76661076975455",
          },
          {
            x: "2024-02-01",
            y: "-253.50082254374436",
          },
          {
            x: "2024-03-01",
            y: "-239.83625492810302",
          },
          {
            x: "2024-04-01",
            y: "-59.381612567577456",
          },
          {
            x: "2024-05-01",
            y: "-633.7809328950676",
          },
          {
            x: "2024-06-01",
            y: "-227.1837184068425",
          },
          {
            x: "2024-07-01",
            y: "39.40255004470692",
          },
          {
            x: "2024-08-01",
            y: "-288.3279928978041",
          },
          {
            x: "2024-09-01",
            y: "-325.3921003980666",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#5F9EA0",
        "#006AF9",
        "#C780E8",
        "#59ADF6",
        "#B0E0E6",
        "#40E0D0",
        "#8A9A5B",
        "#FFB480",
        "#791C0B",
        "#42D6A4",
        "#4682B4",
        "#D8BFD8",
        "#708090",
        "#FF7F50",
        "#9D94FF",
        "#7FFFD4",
        "#08CAD1",
        "#FF6961",
        "#93E9BE",
        "#E6B447",
        "#CCCCFF",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "673",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "0.0",
          },
          {
            x: "2024-02-01",
            y: "36.12258229269689",
          },
          {
            x: "2024-03-01",
            y: "24.00501706450935",
          },
          {
            x: "2024-04-01",
            y: "-136.01987073581262",
          },
          {
            x: "2024-05-01",
            y: "373.3501603457204",
          },
          {
            x: "2024-06-01",
            y: "12.784908173328557",
          },
          {
            x: "2024-07-01",
            y: "-223.62041307540693",
          },
          {
            x: "2024-08-01",
            y: "67.00687518429527",
          },
          {
            x: "2024-09-01",
            y: "99.87485556187312",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#9D94FF",
        "#791C0B",
        "#42D6A4",
        "#59ADF6",
        "#FFB480",
        "#B0E0E6",
        "#006AF9",
        "#FF7F50",
        "#C780E8",
        "#708090",
        "#D8BFD8",
        "#E6B447",
        "#4682B4",
        "#7FFFD4",
        "#93E9BE",
        "#08CAD1",
        "#CCCCFF",
        "#8A9A5B",
        "#FF6961",
        "#40E0D0",
        "#5F9EA0",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "674",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "-8.90195074475727",
          },
          {
            x: "2024-04-01",
            y: "-126.46134838844083",
          },
          {
            x: "2024-05-01",
            y: "247.73815804338884",
          },
          {
            x: "2024-06-01",
            y: "-17.14460137788646",
          },
          {
            x: "2024-07-01",
            y: "-190.81550687129396",
          },
          {
            x: "2024-08-01",
            y: "22.688588749506366",
          },
          {
            x: "2024-09-01",
            y: "46.834457733172606",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#9D94FF",
        "#CCCCFF",
        "#59ADF6",
        "#FF7F50",
        "#08CAD1",
        "#8A9A5B",
        "#7FFFD4",
        "#708090",
        "#D8BFD8",
        "#C780E8",
        "#791C0B",
        "#4682B4",
        "#B0E0E6",
        "#42D6A4",
        "#40E0D0",
        "#E6B447",
        "#FFB480",
        "#5F9EA0",
        "#93E9BE",
        "#FF6961",
        "#006AF9",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "676",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "0.0",
          },
          {
            x: "2024-02-01",
            y: "0.0",
          },
          {
            x: "2024-03-01",
            y: "0.0",
          },
          {
            x: "2024-04-01",
            y: "8.135082976721897",
          },
          {
            x: "2024-05-01",
            y: "2.215683711184502",
          },
          {
            x: "2024-06-01",
            y: "-3.9404915249743766",
          },
          {
            x: "2024-07-01",
            y: "-11.020915820200184",
          },
          {
            x: "2024-08-01",
            y: "3.032515100181832",
          },
          {
            x: "2024-09-01",
            y: "3.8072936140880698",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth Period",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#7FFFD4",
        "#59ADF6",
        "#FFB480",
        "#5F9EA0",
        "#4682B4",
        "#006AF9",
        "#40E0D0",
        "#9D94FF",
        "#B0E0E6",
        "#FF7F50",
        "#708090",
        "#8A9A5B",
        "#42D6A4",
        "#08CAD1",
        "#FF6961",
        "#93E9BE",
        "#791C0B",
        "#CCCCFF",
        "#D8BFD8",
        "#C780E8",
        "#E6B447",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "677",
    track: false,
    series: [
      {
        name: "Rent growth rate",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "19.74114635187028872100",
          },
          {
            x: "2024-02-01",
            y: "19.74114635187028872100",
          },
          {
            x: "2024-03-01",
            y: "25.37758717135931381700",
          },
          {
            x: "2024-04-01",
            y: "25.37758717135931381700",
          },
          {
            x: "2024-05-01",
            y: "25.48785937732757336500",
          },
          {
            x: "2024-06-01",
            y: "23.13907933398628795300",
          },
          {
            x: "2024-07-01",
            y: "21.15453567586948727100",
          },
          {
            x: "2024-08-01",
            y: "13.03185564713744709300",
          },
          {
            x: "2024-09-01",
            y: "9.56800000000000000000",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Rent Growth ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#59ADF6",
        "#8A9A5B",
        "#FFB480",
        "#4682B4",
        "#FF6961",
        "#006AF9",
        "#7FFFD4",
        "#40E0D0",
        "#E6B447",
        "#08CAD1",
        "#D8BFD8",
        "#9D94FF",
        "#791C0B",
        "#93E9BE",
        "#708090",
        "#FF7F50",
        "#CCCCFF",
        "#B0E0E6",
        "#42D6A4",
        "#C780E8",
        "#5F9EA0",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "678",
    track: false,
    series: [
      {
        name: "Utilities expense amount",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "935.40",
          },
          {
            x: "2024-03-01",
            y: "3420.25",
          },
          {
            x: "2024-04-01",
            y: "858.67",
          },
          {
            x: "2024-05-01",
            y: "-19949.62",
          },
          {
            x: "2024-06-01",
            y: "783.85",
          },
          {
            x: "2024-07-01",
            y: "2627.26",
          },
          {
            x: "2024-08-01",
            y: "1787.84",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Utilities Expenses ",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#08CAD1",
        "#006AF9",
        "#CCCCFF",
        "#708090",
        "#D8BFD8",
        "#791C0B",
        "#5F9EA0",
        "#42D6A4",
        "#59ADF6",
        "#8A9A5B",
        "#E6B447",
        "#B0E0E6",
        "#FFB480",
        "#7FFFD4",
        "#C780E8",
        "#FF6961",
        "#4682B4",
        "#9D94FF",
        "#93E9BE",
        "#FF7F50",
        "#40E0D0",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "679",
    track: false,
    series: [
      {
        name: "NOI growth rate",
        type: "line",
        data: [
          {
            x: "2024-02-01",
            y: "0.00",
          },
          {
            x: "2024-03-01",
            y: "-8.90",
          },
          {
            x: "2024-04-01",
            y: "-126.46",
          },
          {
            x: "2024-05-01",
            y: "247.74",
          },
          {
            x: "2024-06-01",
            y: "-17.14",
          },
          {
            x: "2024-07-01",
            y: "-190.82",
          },
          {
            x: "2024-08-01",
            y: "22.69",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI Growth Period",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "rate (in %)",
          align: "left",
        },
      },
      colors: [
        "#59ADF6",
        "#B0E0E6",
        "#708090",
        "#791C0B",
        "#FF6961",
        "#9D94FF",
        "#D8BFD8",
        "#08CAD1",
        "#7FFFD4",
        "#FF7F50",
        "#4682B4",
        "#8A9A5B",
        "#FFB480",
        "#5F9EA0",
        "#006AF9",
        "#C780E8",
        "#CCCCFF",
        "#E6B447",
        "#93E9BE",
        "#40E0D0",
        "#42D6A4",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "681",
    track: false,
    series: [
      {
        name: "NOI amount",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "7087.81",
          },
          {
            x: "2024-02-01",
            y: "9648.11",
          },
          {
            x: "2024-03-01",
            y: "8789.24",
          },
          {
            x: "2024-04-01",
            y: "-2553.02",
          },
          {
            x: "2024-05-01",
            y: "33550.16",
          },
          {
            x: "2024-06-01",
            y: "7993.98",
          },
          {
            x: "2024-07-01",
            y: "-8761.98",
          },
          {
            x: "2024-08-01",
            y: "11837.13",
          },
          {
            x: "2024-09-01",
            y: "14166.75",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#59ADF6",
        "#C780E8",
        "#42D6A4",
        "#006AF9",
        "#D8BFD8",
        "#9D94FF",
        "#40E0D0",
        "#7FFFD4",
        "#FF7F50",
        "#5F9EA0",
        "#08CAD1",
        "#CCCCFF",
        "#708090",
        "#4682B4",
        "#93E9BE",
        "#B0E0E6",
        "#791C0B",
        "#FFB480",
        "#FF6961",
        "#E6B447",
        "#8A9A5B",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "638",
    track: false,
    series: [
      {
        name: "Occupied units count",
        type: "line",
        data: [
          {
            x: "2023-09-01",
            y: "19",
          },
          {
            x: "2023-10-01",
            y: "19",
          },
          {
            x: "2023-11-01",
            y: "18",
          },
          {
            x: "2023-12-01",
            y: "18",
          },
          {
            x: "2024-01-01",
            y: "18",
          },
          {
            x: "2024-02-01",
            y: "18",
          },
          {
            x: "2024-03-01",
            y: "18",
          },
          {
            x: "2024-04-01",
            y: "18",
          },
          {
            x: "2024-05-01",
            y: "18",
          },
          {
            x: "2024-06-01",
            y: "17",
          },
          {
            x: "2024-07-01",
            y: "17",
          },
          {
            x: "2024-08-01",
            y: "18",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "Occupied Units",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Count",
          align: "left",
        },
      },
      colors: [
        "#E6B447",
        "#5F9EA0",
        "#708090",
        "#FFB480",
        "#B0E0E6",
        "#C780E8",
        "#006AF9",
        "#4682B4",
        "#42D6A4",
        "#40E0D0",
        "#791C0B",
        "#59ADF6",
        "#FF6961",
        "#9D94FF",
        "#8A9A5B",
        "#FF7F50",
        "#CCCCFF",
        "#93E9BE",
        "#D8BFD8",
        "#7FFFD4",
        "#08CAD1",
      ],
    },
    granularity: "month",
    properties: ["4309 S Main Street"],
  },
  {
    id: "682",
    track: false,
    series: [
      {
        name: "NOI amount",
        type: "line",
        data: [
          {
            x: "2024-01-01",
            y: "18949.53",
          },
          {
            x: "2024-02-01",
            y: "18895.99",
          },
          {
            x: "2024-03-01",
            y: "9137.25",
          },
          {
            x: "2024-04-01",
            y: "8116.53",
          },
          {
            x: "2024-05-01",
            y: "44352.63",
          },
          {
            x: "2024-06-01",
            y: "20183.83",
          },
          {
            x: "2024-07-01",
            y: "-22261.90",
          },
          {
            x: "2024-08-01",
            y: "26299.05",
          },
          {
            x: "2024-09-01",
            y: "31124.39",
          },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "NOI",
        align: "left",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: "",
          align: "left",
        },
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Amounts (in $)",
          align: "left",
        },
      },
      colors: [
        "#42D6A4",
        "#CCCCFF",
        "#5F9EA0",
        "#40E0D0",
        "#93E9BE",
        "#E6B447",
        "#708090",
        "#08CAD1",
        "#C780E8",
        "#791C0B",
        "#D8BFD8",
        "#FFB480",
        "#59ADF6",
        "#006AF9",
        "#FF6961",
        "#7FFFD4",
        "#9D94FF",
        "#B0E0E6",
        "#FF7F50",
        "#4682B4",
        "#8A9A5B",
      ],
      tooltip: {
        shared: true,
        hideEmptySeries: false,
      },
    },
    granularity: "month",
    properties: ["4309 S Main Street", "4320 Santa Rosalia Dr."],
  },
];

export const HistoricalTrendLineMock = {
  id: "1",
  chart_type: "line",
  title: "Historical trend line",
  track: true,
  series: [
    {
      data: [
        { x: new Date("2019").getTime(), y: 70000 },
        { x: new Date("2020").getTime(), y: 90000 },
        { x: new Date("2021").getTime(), y: 65000 },
        { x: new Date("2022").getTime(), y: 80000 },
        { x: new Date("2023").getTime(), y: 95000 },
      ],
    },
    {
      data: [
        { x: new Date("2019").getTime(), y: 50000 },
        { x: new Date("2020").getTime(), y: 40000 },
        { x: new Date("2021").getTime(), y: 55000 },
        { x: new Date("2022").getTime(), y: 50000 },
        { x: new Date("2023").getTime(), y: 65000 },
      ],
    },
  ],
  options: {
    chart: {
      type: "line",
      height: 150,
    },
    xaxis: {
      type: "datetime" as AxisType,
    },
    yaxis: {
      min: 0,
      max: 150000,
    },
  },
};

export const PortfolioHistoricalTrendLineMock = () => {
  return {
    id: "2",
    chart_type: "line",
    title: "Portfolio historical trend line",
    track: true,
    series: [
      {
        data: [
          { x: 3500, y: 70000 },
          { x: new Date("2020").getTime(), y: 90000 },
          { x: new Date("2021").getTime(), y: 65000 },
          { x: new Date("2022").getTime(), y: 80000 },
          { x: new Date("2023").getTime(), y: 95000 },
        ],
      },
      {
        data: [
          { x: new Date("2019").getTime(), y: 50000 },
          { x: new Date("2020").getTime(), y: 40000 },
          { x: new Date("2021").getTime(), y: 55000 },
          { x: new Date("2022").getTime(), y: 50000 },
          { x: new Date("2023").getTime(), y: 65000 },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 150,
      },
      xaxis: {
        type: "datetime" as AxisType,
      },
      yaxis: {
        min: 0,
        max: 150000,
      },
    },
  };
};

export const AvgRentByBedroomTypeMock = () => {
  return {
    id: "3",
    chart_type: "bar",
    title: "Historical trend line",
    track: true,
    series: [
      {
        data: [
          { x: new Date("2020").getTime(), y: 90000 },
          { x: new Date("2021").getTime(), y: 65000 },
          { x: new Date("2022").getTime(), y: 80000 },
          { x: new Date("2023").getTime(), y: 95000 },
        ],
      },
      {
        data: [
          { x: new Date("2019").getTime(), y: 50000 },
          { x: new Date("2020").getTime(), y: 40000 },
          { x: new Date("2021").getTime(), y: 55000 },
          { x: new Date("2022").getTime(), y: 50000 },
          { x: new Date("2023").getTime(), y: 65000 },
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 150,
      },
      xaxis: {
        type: "datetime" as AxisType,
        categories: ["1 bed(s)", "2 bed(s)", "3 bed(s)", "4 bed(s)"],
      },
      yaxis: {
        min: 0,
        max: 150000,
      },
      legend: {
        customLegendItems: ["Today", "1 year ago"],
      },
    },
  };
};

export const PromptsMock = () => {
  return {
    id: "1",
    title: "Historical trend line",
    series: [
      {
        data: [
          { x: new Date("2020").getTime(), y: 90000 },
          { x: new Date("2021").getTime(), y: 65000 },
          { x: new Date("2022").getTime(), y: 80000 },
          { x: new Date("2023").getTime(), y: 95000 },
        ],
      },
      {
        data: [
          { x: new Date("2019").getTime(), y: 50000 },
          { x: new Date("2020").getTime(), y: 40000 },
          { x: new Date("2021").getTime(), y: 55000 },
          { x: new Date("2022").getTime(), y: 50000 },
          { x: new Date("2023").getTime(), y: 65000 },
        ],
      },
    ],
    properties: ["8302 Crenshaw Blvd", "4102 Crenshaw Blvd"],
    options: {
      chart: {
        type: "line",
        height: 150,
      },
      xaxis: {
        type: "datetime" as AxisType,
        categories: ["1 bed(s)", "2 bed(s)", "3 bed(s)", "4 bed(s)"],
      },
      yaxis: {
        min: 0,
        max: 150000,
      },
    },
    chart_type: "line",
    granularity: "week",
    per_property: true,
  };
};

export const RentDistrictionMock = () => {};

export const PublicRecordDataMock = () => {};
