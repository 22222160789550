import { Typography } from "@material-tailwind/react";
import { InfoIcon } from "./Icons";
import { Link } from "./Buttons";
import { SmallText } from "../styles/Typography";

type InfoPopupProps = {
  dismissPopup: () => void;
  title?: string;
  subtitle?: string;
  text?: string;
};
export const InfoPopup = ({
  dismissPopup,
  title,
  subtitle,
  text,
}: InfoPopupProps) => (
  <>
    <div
      onClick={(e) => {
        e.stopPropagation();
        dismissPopup();
      }}
      className={`absolute top-0 left-0 flex justify-center items-center w-full h-screen overflow-hidden z-20 bg-black/5 transition-all duration-500 $`}
    >
      <div className="absolute z-30 top-24 left-8 max-w-full m-12 p-6 rounded-lg bg-white">
        <Typography className="!mt-0 !mb-2 font-normal">{title}</Typography>
        <div className=" bg-primary-very-light rounded-lg px-4 mb-4 w-72">
          {subtitle && (
            <div className="flex items-center gap-1 justify-start text-green font-semibold">
              <InfoIcon color="#0D652D" />
              <div>{subtitle}</div>
            </div>
          )}

          <div className="flex gap-2">
            <InfoIcon className=" !min-w-[16px] mt-[18px]" />
            <SmallText className="text-secondary-gray-500">{text}</SmallText>
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Link onClick={dismissPopup}>Ok! Got it</Link>
        </div>
      </div>
    </div>
  </>
);
