import { Typography } from "@material-tailwind/react";
import { PropsWithChildren, ReactNode } from "react";

type PopupProps = {
  _key?: string | number;
  className?: string;
  title: string;
  description?: string | ReactNode;
  closePopup: () => void;
  hideDefaultHeader?: boolean;
  closePositionRight?: boolean;
};

export function Popup({
  className,
  title,
  description,
  children,
  closePopup,
  closePositionRight,
  hideDefaultHeader,
}: PropsWithChildren<PopupProps>) {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          closePopup();
        }}
        className={`fixed top-0 left-0 flex justify-center items-center w-screen h-screen overflow-hidden z-20 bg-black/5 transition-all duration-500 $`}
      ></div>
      <div
        className={`absolute bg-white min-w-[280px] z-30 min-h-8 w-fit h-fit p-4 rounded-lg shadow-md border border-gray-100 ${
          className || ""
        }`}
      >
        {hideDefaultHeader ? (
          ""
        ) : (
          <div className="flex justify-between">
            <Typography className="!mt-0 !mb-2 font-normal">{title}</Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={`w-4 h-4 cursor-pointer absolute top-4 ${
                closePositionRight ? "right-4" : "left-4"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                closePopup();
              }}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        {description ?? ""}
        {children ?? ""}
      </div>
    </>
  );
}
