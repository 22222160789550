import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/models';

interface UserState {
    user: User|null,
    token: string
}

const initialState: UserState = {
    user: null,
    token: ""
}

const UserSlice = createSlice({name: 'notifications', initialState, reducers: {
    setJWT(state, {payload: token}) {
        state.token = token;
        return state;
    },
    setUserData(state, {payload: user}) {
        state.user = user;
        return state;
    }
}})

export const {setJWT, setUserData} = UserSlice.actions;
export default UserSlice.reducer;