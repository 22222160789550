import { MouseEventHandler, PropsWithChildren } from "react";

type ButtonProps = {
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
};

export const Link = ({
  className,
  children,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <div
      className={`text-primary cursor-pointer ${className || ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const DefaultButton = ({
  className,
  children,
  onClick,
  disabled,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <div
      className={`flex justify-center items-center gap-2 h-10 min-w-[96px] rounded-3xl p-2 px-4 cursor-pointer font-light ${
        disabled
          ? "pointer-events-none !bg-blue-gray-50 border-none !text-blue-gray-200 "
          : ""
      } ${className || ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const OutlineButton = ({
  className,
  children,
  onClick,
  disabled,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <DefaultButton
      className={`text-primary font-normal text-[11px] lg:text-[14px] p-1 px-1 md:px-5 whitespace-nowrap border border-primary bg-white hover:bg-secondary-gray-350 ${
        disabled ? "border-none" : ""
      } ${className || ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </DefaultButton>
  );
};

export const OutlineIconButton = ({
  className,
  children,
  onClick,
  disabled,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <DefaultButton
      className={` cursor-pointer text-secondary-gray-400 hover:text-primary font-normal whitespace-nowrap border border-primary bg-white  hover:bg-secondary-gray-350 !min-w-fit w-8 h-8 !p-0  ${
        disabled ? "border-none" : ""
      } ${className || ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </DefaultButton>
  );
};

export const PrimaryButton = ({
  className,
  children,
  onClick,
  disabled,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <DefaultButton
      className={`bg-primary text-white hover:bg-primary-hover ${
        className || ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </DefaultButton>
  );
};
