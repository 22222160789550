import { useCallback, useRef, useState } from "react";
import { CloseIcon, SendIcon } from "./Icons";
import historyIcon from "../assets/history.svg";
import { SmallText } from "../styles/Typography";

type FreeTextPromptsProps = {
  className?: string;
  placeholder?: string;
  generate: (input: string) => void;
  suggestions?: { text: string; graph_name: string }[];
};

export const FreeTextPrompts = ({
  className,
  placeholder,
  suggestions,
  generate,
}: FreeTextPromptsProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isValidInput, setIsValidInput] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedPromptKey, setSelectedPromptKey] = useState<string | null>(
    null
  );
  const [filter, setFilter] = useState("");

  const LoadSuggestions = useCallback(() => {
    const filteredSuggestions =
      suggestions && filter.length
        ? suggestions?.filter(
            (s) =>
              !filter.length ||
              s.text.toLowerCase().indexOf(filter.toLowerCase()) > -1
          )
        : [];
    return filteredSuggestions?.map((row, index) => {
      const r = row.text;
      return (
        <div
          key={r ? r.replace(" ", "_") : index}
          className="flex gap-1 m-0 mt-2 items-start justify-start text-start cursor-pointer"
          onClick={() => {
            inputRef.current && (inputRef.current.value = r);
            setIsValidInput(!!inputRef?.current?.value?.length);
            setSelectedPromptKey(row.graph_name);
            setShowSuggestions(false);
          }}
        >
          <img className="!m-0" src={historyIcon} />
          <SmallText className="!m-0">{r}</SmallText>
        </div>
      );
    });
  }, [filter]);

  return (
    <div className="flex items-center justify-between gap-2">
      <div
        className={`flex w-full h-fit flex-col justify-between items-center rounded-[9px] border border-secondary-gray-10 bg-secondary-gray-10 ${
          className || ""
        }`}
      >
        <div className="w-full h-full flex flex-col p-2">
          <div className="flex w-full h-full justify-between items-center">
            <input
              placeholder={placeholder || "Ask me anything..."}
              className="group w-full h-full outline-none !border-0 focus:border-transparent bg-transparent text-sm"
              onFocus={() => {
                setShowSuggestions(true);
              }}
              onKeyUp={() =>
                setIsValidInput(!!inputRef?.current?.value?.length)
              }
              ref={inputRef}
              onChange={(e) => setFilter(e.target.value)}
            />
            <CloseIcon
              onClose={() => {
                setSelectedPromptKey(null);
                setFilter("");
                inputRef.current!.value = "";
                inputRef.current?.focus();
              }}
            />
          </div>
          <div
            className={`w-full flex flex-col group-focus:bg-cyan-500  transition-all ease-in-out duration-500 overflow-hidden ${
              showSuggestions ? "max-h-fit" : "max-h-0"
            } h-fit`}
          >
            <LoadSuggestions />
          </div>
        </div>
      </div>
      <button
        className={`rounded-full w-5 h-5 mt-3`}
        disabled={!isValidInput || !selectedPromptKey}
        onClick={() => {
          if (inputRef.current) {
            selectedPromptKey && generate(selectedPromptKey);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.value = "";
              }
              setIsValidInput(false);
            }, 0);
          }
        }}
      >
        {isValidInput && selectedPromptKey ? (
          <SendIcon className="text-primary" />
        ) : (
          <SendIcon className=" text-secondary-gray-350" />
        )}
      </button>
    </div>
  );
};
