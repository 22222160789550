import { useEffect, useState } from "react";
import { GenerateReportStep } from "./types";
import { GenerateReportDTO, REPORT_TYPE } from "../../../models/models";
import { generateReport } from "../../../api/api";

const useGenerateReport = () => {
  const [activeStep, setActiveStep] = useState(GenerateReportStep.FILL);
  const [reportUrl, setReportUrl] = useState("");
  const [reportData, setReportData] = useState<GenerateReportDTO>({
    reportType: REPORT_TYPE.internal,
    fromDate: new Date(),
    toDate: new Date(),
    properties: "",
    recepients: [],
  });

  const updateReportAttribute = (
    attribute: keyof GenerateReportDTO,
    value: any
  ) => setReportData({ ...reportData, [attribute]: value });

  const generate = async () => generateReport(reportData);

  // on mount set default dates
  useEffect(() => {
    const now = new Date();
    let monthAgo = new Date();
    monthAgo.setMonth(now.getMonth() - 1);
    updateReportAttribute("fromDate", monthAgo);
    setReportUrl("/src/assets/reports/Charleston Hall - 2Q23 Executive Summary Report.pdf");
  }, []);

  return {
    activeStep,
    reportData,
    reportUrl,
    setActiveStep,
    generate,
    updateReportAttribute,
  };
};

export default useGenerateReport;
