import { FC, useMemo, useRef, useState } from "react";
import { HalfScreenPopup } from "@components/HalfScreenPopup";
import { GenerateReportStep } from "./types";
import { AppStepper, Step } from "@components/Stepper";
import useGenerateReport from "./useGenerateReport";
import { AttachEmailIcon, DownloadIcon } from "@components/Icons";
import { ReportPreview } from "@components/ReportPreview";
import ShareReport from "./components/ShareReport";
import GenerateReportNavigation from "./components/GenerateReportNavigation";
import FillStep from "./components/Steps/Fill";
import PreviewStep from "./components/Steps/Preview";
import ShareStep from "./components/Steps/Share";
import { sendEmail } from "../../../utils/helpers";

interface IProps {
  closePopup: () => void;
}

const GenerateReport: FC<IProps> = ({ closePopup }) => {
  const {
    activeStep,
    reportData,
    reportUrl,
    setActiveStep,
    generate,
    updateReportAttribute,
  } = useGenerateReport();
  const DownloadButtonRef = useRef<HTMLAnchorElement>(null);
  const [generatedReportUrl, setGeneratedReportUrl] = useState<string>("");
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);
  const onStepNavigation = (forward = false) => {
    let nextStep = activeStep;
    if (forward) {
      nextStep += 1;
    } else {
      nextStep -= 1;
    }

    if (nextStep in GenerateReportStep) {
      setActiveStep(nextStep);
    }
  };
  const goForward = () => onStepNavigation(true);
  const goBack = () => onStepNavigation();

  const steps: Step[] = useMemo(
    () => [
      {
        id: GenerateReportStep.FILL,
        button: (
          <label htmlFor="generateReportForm" tabIndex={0}>
            Generating a report
          </label>
        ),
        label: "Generating a report",
        actions: {
          primary: {
            label: "Generate",
            icon: <></>,
            onClick: async () => {
              // TODO VALIDATION
              setGeneratedReportUrl(await generate());
              goForward();
            },
          },
        },
      },
      {
        id: GenerateReportStep.PREVIEW,
        button: "Preview",
        label: "Preview",
        content: <ReportPreview reportUrl={generatedReportUrl || ""} />,
        actions: {
          primary: {
            label: "Next",
            onClick: goForward,
          },
          secondary: {
            label: "Back",
            onClick: goBack,
          },
        },
      },
      {
        id: GenerateReportStep.SHARE,
        button: "Share",
        label: "Share",
        content: <ShareReport />,
        actions: {
          primary: {
            label: "Share",
            icon: <AttachEmailIcon />,
            onClick: () => {
              sendEmail(
                reportData.reportType,
                "",
                generatedReportUrl,
                undefined,
                reportData.recepients
              );
            },
          },
          secondary: {
            label: "Download",
            icon: (
              <a
                ref={DownloadButtonRef}
                href={generatedReportUrl}
                download={generatedReportUrl}
              >
                <DownloadIcon />
              </a>
            ),
            onClick: () => {
              DownloadButtonRef.current?.click();
            },
          },
        },
      },
    ],
    [activeStep]
  );

  const stepData = useMemo(
    () => steps.find((step) => step.id === activeStep),
    [steps, activeStep]
  );

  const onStepChange = (step: GenerateReportStep) => {
    // todo step validation
    setActiveStep(step);
  };

  const StepContent = useMemo(() => {
    switch (activeStep) {
      case GenerateReportStep.SHARE:
        return ShareStep;
      case GenerateReportStep.PREVIEW:
        return PreviewStep;
      default:
      case GenerateReportStep.FILL:
        return FillStep;
    }
  }, [activeStep]);

  const stepInjectedData = {
    reportData,
    reportUrl,
    onFieldChange: updateReportAttribute,
    setIsDisabledSubmitButton,
  };

  return (
    <HalfScreenPopup
      title={""}
      hideDefaultHeader
      closePopup={closePopup}
      className="!p-0"
    >
      <div className="relative flex flex-col justify-between w-full h-full">
        <AppStepper
          steps={steps}
          onStepChange={onStepChange}
          currentStep={activeStep}
          disabledNextSteps={isDisabledSubmitButton}
        />
        <div
          className="relative flex flex-col h-full flex-1 overflow-auto"
          style={{ maxHeight: "calc(100% - 150px)" }}
        >
          <StepContent {...stepInjectedData} />
        </div>
        <GenerateReportNavigation
          onClose={closePopup}
          primaryAction={stepData?.actions?.primary}
          secondaryAction={stepData?.actions?.secondary}
          disabled={isDisabledSubmitButton}
        />
      </div>
    </HalfScreenPopup>
  );
};

export default GenerateReport;
